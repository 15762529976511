import {  Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { BenefitcalculatorService } from '../../service/benefitcalculator.service';

@Component({
  selector: 'app-saved-search',
  templateUrl: './saved-search.component.html',
  styleUrls: ['./saved-search.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.Emulated
})
export class SavedSearchComponent implements OnInit {

  useritems!: MenuItem[];
  userlogo: any;
  username: any;
  setting: boolean = true;
  period: any[] = [];
  view: any[] = [];

  //Flags
  defaultSettingTab: boolean = true;
  savedSearchTab: boolean = true;

  //savesearch
  SelectFilter: any[] = [];
  filterdataobj_group: any[] = []
  filterdataobj_group_name: any[] = []
  filterdataobj_surrender_year: any[] = []
  filterdataobj_surrender_year_name: any[] = []
  filterdataobj_products: any[] = []
  filterdataobj_products_name: any[] = [];
  filterdataobj_riderfee: any[] = []
  filterdataobj_riderfee_name: any[] = []
  filterdataobj_strategyfee: any[] = []
  filterdataobj_strategyfee_name: any[] = []
  SelectFilter_AMbest: any[] = [];
  SelectFilter_AMbest_name: any[] = [];

  gender: any;
  age: any;
  pouseage: any;
  state: any;
  Contribution: any;
  amount: any;
  periode: any;
  diffperiode: any;

  data: any[] = [];
  dataview: any[] = [];
  savedserchstr: any = '';
  saveSearchLength: number = 0;
  searchtitle: any;
  deleteSearch: boolean = false;
  deteleSearchId: number = 0;
  showSideMenu: boolean = false;
  savedSearchList: any;
  showLoader: boolean = false;
  savedType: string = '';
  constructor(public layoutService: LayoutService,
    private router: Router,
    private benefitcalculatorService: BenefitcalculatorService,
    private messageService: MessageService) {
    this.userlogo = '';
    this.username = this.benefitcalculatorService.get('UserName')[0];
  }

  ngOnInit(): void {
    this.data = [];
   
    // this.isSaved = true;
    this.useritems = [{
      label: 'Account Settings',
      icon: 'pi pi-user-edit',
      command: () => {
          this.router.navigate(['/profilesetting']);
      }
    },
    {
      label: 'Default Settings',
      icon: 'pi pi-cog',
      command: () => {
        this.router.navigate(['/adminsystem']);
      }
    },
    {
      label: 'My Saved Searches',
      icon: 'pi pi-save',
      command: () => {
        this.router.navigate(['/savedsearch']);
      }
    },
    {
      label: 'My Custom Shelfs',
      icon: 'pi pi-book',
      command: () => {
        this.router.navigate(['/adminsystem']);
      }
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        this.onLogout();
      }
    }
    ];
    this.savedSearch();
  }

  onLogout() {
    // sessionStorage.removeItem('Token');
    // sessionStorage.removeItem('UserName');
    // sessionStorage.removeItem('userId');
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  defaultContent() {
    this.setting = true
    this.savedSearchTab = false;
    this.defaultSettingTab = true;
    this.router.navigate(['/adminsystem']);
  }

  profileContent() {
    this.setting = false
    this.router.navigate(['/profilesetting']);
  }
  customShelf(){
    this.router.navigate(['/customshelf'])
  }
  myFavourites(){
    this.router.navigate(['/myfavourites'])
  }

  savedSearch() {
    this.defaultSettingTab = false;
    this.savedSearchTab = true;
    this.setting = false;
    let uID = this.benefitcalculatorService.decryptUsingAES256(JSON.parse(sessionStorage.getItem('userId')|| ''));
    this.showLoader = true;
    this.benefitcalculatorService.getSavedSearch(JSON.parse(uID)).subscribe((res: any) => {
      this.saveSearchLength = res.length;
      this.savedSearchList = res ? res : [];
      this.formatSearchData(this.savedSearchList);
    })
  }

  formatSearchData(searchList = []){
    this.data = [];
    searchList.map((data: any) => {
      let savestr = JSON.parse(data.searchString);
      if (savestr) {
        this.filterdataobj_group_name = savestr.filterdataobj_group_name
        this.filterdataobj_group = savestr.filterdataobj_groupid

        this.SelectFilter_AMbest_name = savestr.SelectFilter_AMbest_name
        this.SelectFilter_AMbest = savestr.SelectFilter_AMbestid

        this.filterdataobj_products_name = savestr.filterdataobj_products_name
        this.filterdataobj_products = savestr.filterdataobj_productsid

        this.filterdataobj_surrender_year_name = savestr.filterdataobj_surrender_year_name
        this.filterdataobj_surrender_year = savestr.filterdataobj_surrender_yearid

        this.filterdataobj_strategyfee_name = savestr.filterdataobj_strategyfee_name
        this.filterdataobj_strategyfee = savestr.filterdataobj_strategyfeeid

        this.filterdataobj_riderfee_name = savestr.filterdataobj_riderfee_name
        this.filterdataobj_riderfee = savestr.filterdataobj_riderfeeid

        this.gender = 'Gender' + ' ' + savestr.gender == 'M' || savestr.gender != 'select' ? 'Male, ' : 'Female, ';
        this.age = 'Age ' + '(' + savestr.age + ')' + ', '
        this.pouseage = 'Spouse age ' + '(' + savestr.pouseage + '), '
        this.state = savestr.state + ', '
        this.Contribution = savestr.Contribution + ', '
        this.amount = '$' + savestr.amount + '/-' + ', '
        this.diffperiode = 'Deferral Period (' + savestr.diffperiode + ') '
      }
      if (savestr.searchtitle) {
        this.searchtitle = savestr.searchtitle
      } else { this.searchtitle = 'Save Search Name' }
      if (this.gender != '') { this.savedserchstr += this.gender }
      if (this.age != '') { this.savedserchstr += this.age }
      if (this.pouseage != '') { this.savedserchstr += this.pouseage }
      if (this.state != '') { this.savedserchstr += this.state }
      if (this.Contribution != '') { this.savedserchstr += this.Contribution }
      if (this.amount != '') { this.savedserchstr += this.amount }
      if (this.diffperiode != '') { this.savedserchstr += this.diffperiode }
      let object =
      {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_group: this.filterdataobj_group,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        SelectFilter_AMbest: this.SelectFilter_AMbest,
        filterdataobj_products_name: this.filterdataobj_products_name,
        filterdataobj_products: this.filterdataobj_products,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_surrender_year: this.filterdataobj_surrender_year,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name,
        filterdataobj_strategyfee: this.filterdataobj_strategyfee,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_riderfee: this.filterdataobj_riderfee
      }

      this.filterdataobj_group = []
      this.filterdataobj_group_name = []
      // this.filterdataobj_best = []
      this.filterdataobj_surrender_year = []
      this.filterdataobj_surrender_year_name = []
      this.filterdataobj_products = []
      this.filterdataobj_products_name = [];
      this.filterdataobj_riderfee = []
      this.filterdataobj_riderfee_name = []
      this.filterdataobj_strategyfee = []
      this.filterdataobj_strategyfee_name = []
      // this.filterdataobj_short = []
      this.SelectFilter_AMbest = [];
      this.SelectFilter_AMbest_name = [];
      let advanceFilter = '';
      if(object.filterdataobj_group_name.length > 0){
        advanceFilter += `<b> Group Names : </b> ${object.filterdataobj_group_name.map(({ groupName }) => ( groupName )).toString()}`;
      }
      if(object.SelectFilter_AMbest.length > 0){
        advanceFilter += advanceFilter != '' ? ', ' : '';
        advanceFilter += `<b> AMB Best Ratings : </b> ${object.SelectFilter_AMbest.toString()}`;
      }
      if(object.filterdataobj_surrender_year_name.length > 0){
        advanceFilter += advanceFilter != '' ? ', ' : '';
        advanceFilter += `<b>  Min. Surrender Year : </b> ${object.filterdataobj_surrender_year_name.map(({ surryr }) => ( surryr )).toString()}`;
      }
      if(object.filterdataobj_products_name.length > 0){
        advanceFilter += advanceFilter != '' ? ', ' : '';
        advanceFilter += `<b> Company & Product Names : </b> ${object.filterdataobj_products_name.map(({ data }) => ( data )).toString()}`;
      }
      if(object.filterdataobj_riderfee_name.length > 0){
        advanceFilter += advanceFilter != '' ? ', ' : '';
        advanceFilter += `<b> Min. Rider Fee : </b>${object.filterdataobj_riderfee_name.map(({ riderFee }) => ( riderFee )).toString()}`;
      }
      if(object.filterdataobj_strategyfee_name.length > 0){
        advanceFilter += advanceFilter != '' ? ', ' : '';
        advanceFilter += `<b> Strategy Fee : </b>${object.filterdataobj_strategyfee_name.map(({ strategyFee }) => ( strategyFee )).toString()}`;
      }
      // console.log('Group Names :', object.filterdataobj_group_name.map(({ groupName }) => ( groupName )).toString());
      // console.log('AMB Best Ratings : ',object.SelectFilter_AMbest.toString());
      // console.log('Min. Surrender Year : ',object.filterdataobj_surrender_year_name.map(({ surryr }) => ( surryr )).toString());
      // console.log('Company & Product Names : ',object.filterdataobj_products_name.map(({ data }) => ( data )).toString());
      // console.log('Strategy Fee :',object.filterdataobj_riderfee_name.map(({ riderFee }) => ( riderFee )).toString());
      // console.log('Min. Rider Fee :',object.filterdataobj_strategyfee_name.map(({ strategyFee }) => ( strategyFee )).toString());
      this.data.push({ id: data.id, userId: data.userId, saveddata: this.savedserchstr, savedstr: object, searchtitle: this.searchtitle, advance_filter:  advanceFilter});
      this.savedserchstr = '';
    });
    this.showLoader = false;
    // console.log('all saved search', this.data);
    this.dataview = JSON.parse(JSON.stringify(this.data));
  }

  filterviewclick(event: any) {
    let viewdata = [];
    sessionStorage.removeItem('sidebarObject');
    sessionStorage.removeItem('chipDataObject');
    viewdata.push(event,'view')
    this.benefitcalculatorService.setsavedData(viewdata);
    this.router.navigate(['/home'])
  }

  filtereditclick(event: any) {
    let editdata = [];
    editdata.push(event,'edit')
    this.benefitcalculatorService.setsavedData(editdata);
    this.router.navigate(['/home']);
  }
  filterdeleteclick(event: any) {
    this.deleteSearch = true;
    this.deteleSearchId = event.id;
  }
  saveCancel(){
    this.deleteSearch = false;
  }
  deleteSearchclc(){
    this.benefitcalculatorService.deleteSavedSearch(this.deteleSearchId).subscribe((res) => {
      this.data = [];
      this.savedSearch();
    });
    this.deleteSearch = false;
    this.messageService.add({key: 'tc', severity:'error', summary:'Delete', detail:'Saved Search Data Deleted Successfully !!'});
  }

  editSavedSearch(data: any){
    let viewdata = [];
    sessionStorage.removeItem('sidebarObject');
    sessionStorage.removeItem('chipDataObject');
    let myData = Object.assign({}, data);
    viewdata.push(myData,'view')
    this.benefitcalculatorService.setsavedData(viewdata);
    this.savedType = 'edit';
    this.showSideMenu = true;  
  }

  hideAdvanceFilterMenu(data: any){
    if(data && data.id){
      const index = this.savedSearchList.findIndex((x: any) => x.id == data.id);
      if(index > -1){
        this.savedSearchList[index] = data;
        this.formatSearchData(this.savedSearchList);
      }
    }else if(data === true) {
      this.data = [];
      this.savedSearch();
    }
    this.showSideMenu = false;
  }

  onNewSavedSearch(){
    this.savedType = '';
    // sessionStorage.removeItem('basicFilterObj');
    sessionStorage.removeItem('sidebarObject');
    sessionStorage.removeItem('chipDataObject');
    let viewdata = [''];
    this.benefitcalculatorService.setsavedData(viewdata);
    this.showSideMenu = true;
  }

  goBack() {
    this.router.navigate(['home']);
  }
}
