import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-view-custom-shelf',
  templateUrl: './view-custom-shelf.component.html',
  styleUrls: ['./view-custom-shelf.component.scss']
})
export class ViewCustomShelfComponent implements OnInit {

  customShelfData: any[] = [];
  shelfName!: string;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.customShelfData = this.config.data.customShelfData ?? [];
    this.shelfName = this.config.data.shelfName ?? '';
  }

}
