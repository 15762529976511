import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class BenefitcalculatorInterceptor implements HttpInterceptor {
  token: any;
  accessToken: any;
  constructor(private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.token = sessionStorage.getItem('Token');
    this.accessToken = JSON.parse(this.token);
    if (this.token) {
      // If we have a token, we set it to the header
      if (!request.url.includes('CompanyLogo') && !request.url.includes('us_states') ) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.accessToken}`,
            'Content-Type': 'application/json'
          }
        })
      }
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          // console.log('err', err);
          if (err.status === 401) {
            sessionStorage.clear();
            this.router.navigate(['/auth/login']);
          }
        }
        return throwError(err);
      })
    )
  }
}
