<!-- <ul class="layout-menu">
    <p-tree [value]="files1" [filter]="true" selectionMode="checkbox"  filterMode="strict"></p-tree>
  
</ul> -->
<div class="shadow-sm mb-2 bg-white rounded pt-2">
    <div class="row align-items-center">
        <div class="col-1"><i class="bi bi-filter fa-lg"></i></div>
        <div class="col-6">
            <label class="font-bold">FILTER & SHORT</label>
        </div>
        <div class="col">
            <label><u>Clear filter</u></label>
        </div>
    </div>
    <hr />
    <div class="text-center">
        <div class="p-2">
            <label class="text-muted">Pickup where you left off.</label>
        </div>
        <div class="pt-2 pb-3">
            <button pButton pRipple type="button" label="SAVE SEARCH"
                class="p-button-outlined p-button-secondary search-btn"></button>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-1" (click)="ontabSelect('Short by')">
            <i class="bi bi-filter-left fa-lg"></i>
        </div>
        <div class="col-4" (click)="ontabSelect('Short by')">
            <label>Short by</label>
        </div>
        <div class="col-6 text-center">
            <label class="text-prime">{{ selectedShort }}</label>
        </div>
    </div>
</div>
<div *ngIf="selectedtab == 'Sidebar'">
    <div class="card-overflow card ">
        <ul class="layout-menu">
            <li class="menu-separator sidebar-menu row" (click)="ontabSelect('Group Name')">
                <div class="col-10 font-bold"><label>Group Name</label></div>
                <div class="col-2"><i class="pi pi-chevron-right"></i></div>
            </li>
            <li class="menu-separator sidebar-menu-data row" (click)="ontabSelect('Company & Product Name')">
                <div class="col-10 font-bold">
                    <label>Company & Product Name</label>
                </div>
                <div class="col-2"><i class="pi pi-chevron-right"></i></div>
            </li>
            <li class="menu-separator sidebar-menu-data row" (click)="ontabSelect('A.M. Best Rating')">
                <div class="col-10 font-bold">
                    <label>A.M. Best Rating</label>
                </div>
                <div class="col-2"><i class="pi pi-chevron-right"></i></div>
            </li>
            <li class="menu-separator sidebar-menu-data row" (click)="ontabSelect('Surrender Year')">
                <div class="col-10 font-bold">
                    <label>Surrender Year</label>
                </div>
                <div class="col-2"><i class="pi pi-chevron-right"></i></div>
            </li>
            <li class="menu-separator sidebar-menu-data row" (click)="ontabSelect('Rider Fee')">
                <div class="col-10 font-bold"><label>Rider Fee</label></div>
                <div class="col-2"><i class="pi pi-chevron-right"></i></div>
            </li>
            <li class="menu-separator sidebar-menu-data row" (click)="ontabSelect('Strategy Fee')">
                <div class="col-10 font-bold"><label>Strategy Fee</label></div>
                <div class="col-2"><i class="pi pi-chevron-right"></i></div>
            </li>
            <!-- <li class="menu-separator sidebar-menu-data row" (click)="ontabSelect('Confinement Multiplier')"> -->
            <li class="menu-separator sidebar-menu-data row">
                <div class="col-10 font-bold">
                    <label>Confinement Multiplier</label>
                </div>
                <div class="col-2"><i class="pi pi-chevron-right"></i></div>
            </li>
        </ul>
    </div>
</div>

<div *ngIf="selectedtab == 'Group Name'">
    <div>
        <div class="sidebar-menu-data-back row" (click)="onbackClick()">
            <div class="col-2"><i class="pi pi-chevron-left"></i></div>

            <div class="col-10">
                <label class="font-bold">Group Name</label>
            </div>
        </div>
        <!-- <div *ngFor="let item of groupnamedata"> -->
        <div>
            <div class="card">
                <div class="card-body">
                    <p-listbox [options]="groups" [(ngModel)]="selectedCountries" [metaKeySelection]="false"
                        [checkbox]="true" [filter]="true" [multiple]="true" optionLabel="groupName"
                        [listStyle]="{ 'max-height': '150rem' }">
                        <ng-template let-group pTemplate="item ">
                            <div class="country-item">
                                <div>{{ group.groupName }}</div>
                            </div>
                        </ng-template>
                    </p-listbox>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedtab == 'Company & Product Name'">
    <div>
        <div class="sidebar-menu-data-back row" (click)="onbackClick()">
            <div class="col-2"><i class="pi pi-chevron-left"></i></div>

            <div class="col-10">
                <label class="font-bold">Company & Product Name</label>
            </div>
        </div>
        <!-- <div *ngFor="let item of groupnamedata"> -->
        <div>
            <div class="card">
                <div class="card-body">
                    <p-tree [value]="productlist" selectionMode="checkbox" [filter]="true">
                    </p-tree>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedtab == 'A.M. Best Rating'">
    <div>
        <div class="sidebar-menu-data-back row" (click)="onbackClick()">
            <div class="col-2"><i class="pi pi-chevron-left"></i></div>

            <div class="col-10">
                <label class="font-bold">A.M. Best Rating</label>
            </div>
        </div>
        <!-- <div *ngFor="let item of groupnamedata"> -->
        <div>
            <div class="card">
                <div class="card-body">
                    <p-listbox [options]="amBestRating" [(ngModel)]="selectedCountries" [metaKeySelection]="false"
                        [checkbox]="true" [multiple]="true" [filter]="true" optionLabel="name"
                        [listStyle]="{ 'max-height': '150rem' }">
                        <ng-template let-amRating pTemplate="item">
                            <div class="country-item">
                                <div>{{ amRating.bestid }}</div>
                            </div>
                        </ng-template>
                    </p-listbox>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedtab == 'Surrender Year'">
    <div>
        <div class="sidebar-menu-data-back row" (click)="onbackClick()">
            <div class="col-2"><i class="pi pi-chevron-left"></i></div>

            <div class="col-10">
                <label class="font-bold">Surrender Year</label>
            </div>
        </div>
        <!-- <div *ngFor="let item of groupnamedata"> -->
        <div>
            <div class="card">
                <div class="card-body">
                    <h5>PrimeNG ListBox component</h5>
                    <p-listbox [options]="surrenderYear" [(ngModel)]="selectedCountries" [metaKeySelection]="false"
                        [checkbox]="true" [multiple]="true" [filter]="true" optionLabel="name"
                      >
                        <ng-template let-surrenderYr pTemplate="item">
                            <div class="country-item">
                                <div>{{ surrenderYr.surryr }}</div>
                            </div>
                        </ng-template>
                    </p-listbox>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedtab == 'Rider Fee'">
    <div>
        <div class="sidebar-menu-data-back row" (click)="onbackClick()">
            <div class="col-2"><i class="pi pi-chevron-left"></i></div>

            <div class="col-10"><label class="font-bold">Rider Fee</label></div>
        </div>
        <!-- <div *ngFor="let item of groupnamedata"> -->
        <div class="p-3">
            <div class="card">
                <div class="card-body">
                    <div>
                        <label class="text-muted font-bold">Maximum Fee</label>
                    </div>
                    <div>
                        <p-dropdown [options]="riderFee" [(ngModel)]="selectedCity1" placeholder="Select"
                            optionLabel="name" [showClear]="true" [style]="{ width: '15rem' }"></p-dropdown>
                        <label class="font-italic drp-down">or less</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedtab == 'Strategy Fee'">
    <div>
        <div class="sidebar-menu-data-back row" (click)="onbackClick()">
            <div class="col-2"><i class="pi pi-chevron-left"></i></div>

            <div class="col-10">
                <label class="font-bold">Strategy Fee</label>
            </div>
        </div>
        <!-- <div *ngFor="let item of groupnamedata"> -->
        <div class="p-3">
            <div class="card">
                <div class="card-body">
                    <div>
                        <label class="text-muted font-bold">Maximum Fee</label>
                    </div>
                    <div>
                        <p-dropdown [options]="strategyFee" [(ngModel)]="selectedCity1" optionLabel="strategyFee" 
	                        [showClear]="true" placeholder="Select" [style]="{ width: '15rem'}">

	                            <ng-template let-strFee pTemplate="item">
		                            <div class="country-item">
		                            	<div>{{strFee.strategyFee}}</div>
		                            </div>
	                            </ng-template>
                        </p-dropdown>
                        <label class="font-italic drp-down">or less</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedtab == 'Short by'">
    <div>
        <div class="sidebar-menu-data-back row" (click)="onbackClick()">
            <div class="col-2"><i class="pi pi-chevron-left"></i></div>

            <div class="col-10">
                <label class="font-bold">Short by</label>
            </div>
        </div>
        <div class="p-3">
            <div class="shortby-overflow card">
                <div class="card-body">
                    <div *ngFor="let category of categories" class="p-field-checkbox">
                        <div class="row">
                            <div class="col-8 p-2 pl-4">
                                <label class="short-font-size">{{
                                    category.name
                                    }}</label>
                            </div>
                            <div class="col-4 p-2 pl-6">
                                <p-radioButton [inputId]="category.key" name="category" [value]="category"
                                    [(ngModel)]="selectedCategory" (click)="shortby(selectedCategory)"></p-radioButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>