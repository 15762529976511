import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoUploadRoutingModule } from './logo-upload-routing.module';
import { LogoUploadComponent } from './logo-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SafeimgpipePipe } from './safeimgpipe.pipe';


@NgModule({
  declarations: [
    LogoUploadComponent,  
     SafeimgpipePipe,
  ],
  imports: [
    CommonModule,
    LogoUploadRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    ProgressSpinnerModule,
    ImageModule,
    FileUploadModule,
  ],
  exports:[LogoUploadComponent]
})
export class LogoUploadModule { }
