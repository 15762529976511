<div [ngClass]="containerClass">
    <div class="main-container-div layout-main-container"
        [ngClass]="sideMenuShow ? 'side-menu-open': ''">
        <!-- Basic Filter Form Start-->
        <div class="topbar p-3 p-xl-4 mb-3">
            <div class="d-flex align-items-center justify-content-between">
                <label class="topHeading">Case Information</label>
                <div class="filters d-flex align-items-end">
                    <span class="filter-label mt-1 mt-md-0">Advanced Filters</span>
                    <button pButton pRipple type="button" (click)="sidebarOpen()" icon="pi pi-filter-fill fa-lg"
                        class="p-button-rounded p-button-text sidebaricon"></button>
                    <app-advance-filter-menu *ngIf="sideMenuShow" [savedType]="savedtype"
                        [showApprovedProducts]="showApprovedProductsOnly" [userCustomShelfs]="userCustomShelfs"
                        [customShelfMasterData]="customShelfMasterData" (onUpdateFilter)="resetProductList($event)"
                        (onShowSpinner)="showAdvanceFilterSpinner()" (onApplyCustomShelf)="applyCustomShelf($event)"
                        (hideAdvanceFilter)="sideMenuShow = false"></app-advance-filter-menu>
                </div>
            </div>
            <form [formGroup]="topbarForm" (ngSubmit)="onSubmit(topbarForm.value)">
                <div class="align-items-end justify-content-between">
                    <div class="row gy-3">
                        <div class="col-xl-2 col-lg-3 col-md-6 px-md-2">
                            <label class="fontsize"><span class="text-danger">*</span>Gender</label>
                            <div class="pt-1 dropdowngn">
                                <p-dropdown [options]="genderDataList" formControlName="gender" class="dwopdown"
                                    (onChange)="genderValue($event)" placeholder="Select" optionLabel="name"
                                    optionValue="value"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 px-md-2">
                            <label class="fontsize"><span class="text-danger">*</span>Owner Type</label>
                            <div class="" style="height:56px;font-size:16px;padding-top:24px;">
                                <div class="flex flex-wrap gap-5">
                                    <div class="flex align-items-center">
                                        <p-radioButton name="maritalstatus" formControlName="maritalstatus"
                                            (click)="setOwnerType('single')" value="single"
                                            label="Single"></p-radioButton>
                                    </div>

                                    <div class="flex align-items-center">
                                        <p-radioButton name="maritalstatus" formControlName="maritalstatus"
                                            (click)="setOwnerType('joint')" value="joint" label="Joint"></p-radioButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 px-md-2 d-flex">
                            <label class="fontsize position-absolute" for="age"><span class="text-danger">*</span>Date
                                of
                                Birth /
                                Age</label><br />
                            <div class="calendar d-flex">
                                <p-calendar formControlName="acalendar" class="treetableDate"
                                    placeholder="SELECT A DATE" [(ngModel)]="ageSelectedDate" [showIcon]="true"
                                    inputId="icon" (onClose)="onCloseAge($event)" (keyup.enter)="onCloseAge($event)">
                                </p-calendar>
                                <p-inputNumber (onBlur)="onEnterAge($event)" (keyup.enter)="onEnterAge($event)"
                                    (onClear)="onEnterAge($event)" formControlName="age" [size]="2"
                                    styleClass="age-input" placeholder="-" [(ngModel)]="age"></p-inputNumber>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 px-md-2 d-flex" *ngIf="ownerType == 'joint'">
                            <label class="fontsize position-absolute" for="age"><span class="text-danger">*</span>Spouse
                                Date of
                                Birth / Age </label><br />
                            <div class="calendar d-flex">
                                <p-calendar formControlName="scalendar" placeholder="SELECT A DATE" [showIcon]="true"
                                    inputId="icon" [(ngModel)]="spageSelectedDate" (onClose)="onSpouseAgeClose($event)"
                                    (keyup.enter)="onSpouseAgeClose($event)">
                                </p-calendar>
                                <p-inputNumber (onBlur)="onSpouseAgeEnter($event)" (onClear)="onSpouseAgeEnter($event)"
                                    (keyup.enter)="onSpouseAgeEnter($event)" formControlName="pouseage" [size]="2"
                                    placeholder="-" styleClass="age-input" [(ngModel)]="pouseage"></p-inputNumber>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-6 px-md-2 dwopdown">
                            <label class="fontsize" for="vars">State</label><br />
                            <p-dropdown [options]="stateData" formControlName="illinois" placeholder="Select State"
                                optionLabel="stateName" optionValue="stateCode" (onChange)="onStateChange($event)"
                                (onClear)="onStateClear($event)">
                                <ng-template let-state pTemplate="item">
                                    <div class="country-item">
                                        <div>
                                            {{
                                            ( state.stateCode == 'ORG' ? "OR" : state.stateCode ) + ' -
                                            '+state.stateName
                                            }}
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="row gy-3 pt-3">
                        <div class="col-xl-3 col-lg-4 col-md-6 px-md-2">
                            <div class="">
                                <label class="fontsize" for="vars">Income Type</label><br />
                                <p-dropdown [options]="varData" placeholder="Select rider type"
                                    styleClass="income-type-dropdown" formControlName="ridertype" class="dwopdown"
                                    optionLabel="varName" optionValue="value" (onChange)="onVarChange($event)">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 px-md-2 amountType">
                            <label class="fontsize">Amount Type</label><br />
                            <div class="mt-2 contmontperiode">
                                <p-selectButton [options]="stateOptions" formControlName="Contribution"
                                    (onOptionClick)="contIncome($event)" [(ngModel)]="incCont" optionLabel="label"
                                    optionValue="value"></p-selectButton>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 px-md-2">
                            <div class="">
                                <label class="fontsize" for="amount"><span class="text-danger">*</span>Amount</label>

                                <div class="mt-2 p-inputgroup amount">
                                    <span class="p-inputgroup-addon">$</span>
                                    <p-inputNumber (onBlur)="onAmountSelect($event)"
                                        (keyup.enter)="onAmountSelect($event)" formControlName="amount"
                                        inputId="integeronly" pInputText placeholder="Price" class="m-0">
                                    </p-inputNumber>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 px-md-2">
                            <label class="fontsize">Deferral Period </label><br />
                            <div class="d-flex mt-2">
                                <div class="align-self-center slider">
                                    <p-slider formControlName="diffperiode" [(ngModel)]="diffperiode" (onSlideEnd)="
                                        updateDeffSliderChange($event)
                                    " [max]="20"></p-slider>
                                </div>

                                <div class="definput">
                                    <p-inputNumber formControlName="diffperiode" [(ngModel)]="diffperiode" [size]="1"
                                        (onBlur)="updateDeffChange($event)"
                                        (keyup.enter)="updateDeffChange($event)"></p-inputNumber>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- Basic Filter Form end-->

        <!-- Sidebar Filter & card -->
        <div class="d-flex">
            <div class="card-layout">
                <div class="searchResult d-flex"></div>
                <div class="cards-container">
                    <div class="card card-view p-0 mb-0">
                        <ng-container *ngIf="filteredArray.length > 0; else showMainSpinner">
                            <!-- Dara Count, custom shelf, comparison & sidebar filter -->
                            <div class="top-bar">
                                <div #topbarmenu class=" w-100">
                                    <div class="searchResult row">
                                        <div class="search-wrp d-inline-flex"
                                        [ngClass]="!sideMenuShow ? 'col-xl-8 col-lg-12' : 'col-12'">
                                            <div class="d-grid">
                                                <label class="">Search Result</label>
                                                <span>{{
                                                    totalMatches
                                                    }}
                                                    items found</span>
                                            </div>
                                            <div class="d-flex earlychange" style="margin-left: 20px; margin-right:20px;">
                                                <form [formGroup]="topbarForm" (ngSubmit)="
                                                                                                        onSubmit(
                                                                                                            topbarForm.value
                                                                                                        )
                                                                                                    ">
                                                    <div class="periodeam">
                                                        <p-selectButton [options]="
                                                                                                                monthlyAnuallyopt
                                                                                                            " formControlName="periode" [(ngModel)]="
                                                                                                                periodeam
                                                                                                            " (onOptionClick)="
                                                                                                                periodChange(
                                                                                                                    $event
                                                                                                                )
                                                                                                            " optionLabel="label" optionValue="value">
                                                        </p-selectButton>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="d-flex earlychange ml-0">
                                                <div class="card customShelfhead align-items-center p-0">
                                                    <span id="AllProducts">All Products</span>
                                                    <p-inputSwitch aria-label="Approved Products" inputId="showApprovedProductsOnly"
                                                        (onChange)="onApprovedProducts($event)" [(ngModel)]="showApprovedProductsOnly"></p-inputSwitch>
                                                    &nbsp;&nbsp;
                                                    <span id="ApprovedProducts">Approved Products </span>
                                                    <span class="shelf-product-indicator"> (<i class="pi pi-star-fill"></i> indicates shelf product)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-inline-flex mt-lg-0"
                                        [ngClass]="!sideMenuShow ? 'compare-wrp col-xl-4 col-lg-12' : 'col-6'">
                                            <div class="sidebarmenu">
                                                <div class="compared d-flex">
                                                    <button pButton type="button" class="p-button-outlined p-button-secondary mx-2"
                                                        *ngIf="totalCompare > 0 || compareData.length > 0" (click)="clearCompareChange()" icon="pi pi-trash"
                                                        label="Remove Compare" iconPos="left">
                                                    </button>
                                                    <p-toggleButton [(ngModel)]="
                                                                                                            checked
                                                                                                        " (onChange)="
                                                                                                            onCompare(
                                                                                                                $event
                                                                                                            )
                                                                                                        " [onLabel]="'Compare '+totalCompare"
                                                        offLabel="Compare" [onIcon]="'pi pi-copy'" offIcon="pi pi-copy" iconPos="left">
                                                    </p-toggleButton>
                                                </div>
                                            </div>
                                            <div class="ml-4 grismenubutton">
                                                <div ng-reflect-ng-class="p-dataview-layout-options p-se"
                                                    class="p-dataview-layout-options p-selectbutton p-buttonset">
                                                    <button type="button" class="p-button p-button-icon-only" (click)="dataView='list'"
                                                        [ngClass]="{'p-highlight' : dataView == 'list'}"><i class="pi pi-bars"></i></button>
                                                    <button type="button" class="p-button p-button-icon-only" [ngClass]="{'p-highlight' : dataView == 'grid'}"
                                                        (click)="dataView='grid'"><i class="pi pi-th-large"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Start Card/Data View and Sidebar Filter -->
                            <div class="data-view-container"
                                [ngClass]="sideMenuShow ? 'side-menu-data-view':'normal-data-view'"
                                (scroll)="onScroll($event)">
                                <p-dataView [value]="filteredArray" [paginator]="false" emptyMessage=" "
                                    onChangeLayout="" #p [layout]="dataView">
                                    <!-- List view template -->
                                    <ng-template class="" let-product pTemplate="listItem">
                                        <div class="card product-list-item row align-items-center pgrid p-0"
                                            *ngIf="compareVariables == true">
                                            <div class="listviewdata d-flex col-12 align-items-center"
                                                id="product_{{product.id}}">
                                                <span class="income-type" [ngClass]="{
                                                                                                    'inflation-based':
                                                                                                        product.benifitBaseType ==
                                                                                                        'Inflation Based',
                                                                                                    'increasing':
                                                                                                        product.benifitBaseType ==
                                                                                                        'Increasing',
                                                                                                    'level':
                                                                                                        product.benifitBaseType ==
                                                                                                        'Level',
                                                                                                    'no-income':
                                                                                                        product.benifitBaseType ==
                                                                                                        null
                                                                                                }">{{
                                                    product.benifitBaseType == 'Max Income Option' ? 'Max Income*' :
                                                    product.benifitBaseType
                                                    }}</span>
                                                <div class="product-list-detail col-md-1 px-4 py-0">
                                                    <div class="d-flex">
                                                        <p-image src="{{ product.logo1 }}" alt="Image" width="90"
                                                            class="">
                                                        </p-image>
                                                    </div>
                                                </div>
                                                <div class="product-list-detail col-md-5 col-lg-4 p-4">
                                                    <span class="listview">{{
                                                        product.companyName
                                                        }}</span>
                                                </div>
                                                <div class="product-list-detail col-md-3 col-lg-4 p-4">
                                                    <div class="productIcon mb-2 d-flex">
                                                        <i class="pi pi-box"></i>
                                                        <h4 class="m-0">
                                                            {{ product.productName }} <i *ngIf="product.isShelfProduct"
                                                                class="pi pi-star-fill"></i>
                                                        </h4>
                                                    </div>
                                                    <div class="productIcon d-flex">
                                                        <i class="pi pi-user"></i>
                                                        <h4 class="m-0">
                                                            {{ product.riderName }}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="product-list-detail col-md-2 align-self-center">
                                                    <label class="head-lable text-center">${{
                                                        product.anualIncom
                                                        | number : "1.0-0" || 0
                                                        }} <br /> <span class="font-16"> {{
                                                            product.IncomePeriod
                                                            }} </span></label>
                                                </div>
                                                <div class="product-list-detail col-md-1 align-self-center">
                                                    <button pButton pRipple type="button" (click)="expandCard(product)" icon="pi pi-external-link" class="p-button-rounded p-button-secondary p-button-outlined "></button>
                                                    <button pButton pRipple icon="pi pi-ellipsis-v"
                                                        class="p-element p-ripple p-button-rounded p-button-info p-button-outlined p-button p-component p-button-icon-only ml-2"
                                                        *ngIf="isCompare == false" (click)="
                                                                    selectedItem = product;
                                                                    listmenu.toggle($event);
                                                                    compEvent = $event
                                                                "></button>
                                                    <p-checkbox name="groupname" [ngModel]="product.isChecked"
                                                        [binary]="true"
                                                        class="p-element p-ripple p-button-info p-button-rounded p-button-outlined p-button p-component p-button-icon-only comp-chackbox"
                                                        *ngIf="isCompare == true" (onChange)="
                                                                    onComparechange(
                                                                        (comparedataItem = product),
                                                                        (compEvent = $event)
                                                                    )
                                                                ">
                                                    </p-checkbox>
                                                    <p-menu appendTo="body" [showTransitionOptions]="'250ms'" #listmenu
                                                        [popup]="true" [model]="itemslist"></p-menu>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>

                                    <ng-template let-item pTemplate="gridItem">
                                        <div class="colmn-card pad-card" *ngIf="compareVariables == true">
                                            <div class="card-data pad-card fadeIn position-relative zoomIn"
                                                id="product_{{item.id}}">
                                                <div class="card-main-content">
                                                    <div class="basedIncome" [ngClass]="{
                                                                    basedIncome1:
                                                                        item.benifitBaseType ===
                                                                        'Inflation Based',
                                                                    basedIncome2:
                                                                        item.benifitBaseType ===
                                                                        'Increasing',
                                                                    basedIncome3:
                                                                        item.benifitBaseType ===
                                                                        'Level',
                                                                    basedIncome4:
                                                                        item.benifitBaseType == null
                                                                    }">
                                                        <label>{{
                                                            item.benifitBaseType
                                                            }}</label>
                                                    </div>
                                                    <div class="data-height d-flex justify-content-between p-2">
                                                        <p-image src="{{ item.logo1 }}" alt="Image" width="90"
                                                            class="p-img me-2">
                                                        </p-image>
                                                        <div class="card-bottom text-center px-2">
                                                            <label class="head-lable">${{
                                                                item.anualIncom
                                                                | number : "1.0-0" || 0
                                                                }} </label> <br />
                                                            <span> {{ item.IncomePeriod }} </span>
                                                        </div>
                                                        <div class="p-0 cardIconButton text-right">
                                                            <button pButton pRipple icon="pi pi-ellipsis-v"
                                                                class="p-element p-ripple p-button-rounded p-button-info p-button-outlined p-button p-component p-button-icon-only"
                                                                *ngIf="isCompare == false" (click)="
                                                                        selectedItem = item;
                                                                        menu.toggle($event);
                                                                        compEvent = $event
                                                                    "></button>
                                                            <p-checkbox name="groupname" [ngModel]="item.isChecked"
                                                                [binary]="true"
                                                                class="p-element p-ripple p-button-rounded p-button-info p-button-outlined p-button p-component p-button-icon-only comp-chackbox"
                                                                *ngIf="isCompare == true" (onChange)="
                                                                        onComparechange(
                                                                            (comparedataItem =
                                                                                item),
                                                                            (compEvent = $event)
                                                                        )
                                                                    ">
                                                            </p-checkbox>
                                                            <p-menu class="pop-up" appendTo="body"
                                                                [showTransitionOptions]="
                                                                        '250ms'
                                                                    " #menu [popup]="true" [model]="items"></p-menu>
                                                        </div>
                                                    </div>
                                                    <div class="card-body pad-card-main p-2">
                                                        <h4 class="cardHeadingName mb-2">
                                                            {{ item.companyName }}
                                                        </h4>
                                                        <div class="cardFonttitle">
                                                            <div class="productIcon d-flex">
                                                                <i class="pi pi-box"></i>
                                                                <h4 class="m-0 pl-1">
                                                                    {{ item.productName }} <i
                                                                        *ngIf="item.isShelfProduct"
                                                                        class="pi pi-star-fill"></i>
                                                                </h4>
                                                            </div>
                                                            <div class="productIcon d-flex">
                                                                <i class="pi pi-user"></i>
                                                                <h4 class="m-0 pl-1">
                                                                    {{ item.riderName }}
                                                                </h4>
                                                            </div>
                                                            <div class="productIcon d-flex" *ngIf="item.marketingName">
                                                                <i class="pi pi-sun"></i>
                                                                <h4 class="m-0 pl-1">
                                                                    {{ item.marketingName }}
                                                                </h4>
                                                            </div>
                                                            <!-- <div class="productIcon d-flex">
                                                                        <i class="pi pi-dollar"></i>
                                                                        <h4 class="m-0">
                                                                            {{
                                                                            item.otherVariable ||
                                                                            "Standard Option"
                                                                            }}
                                                                        </h4>
                                                                    </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-100 text-center" style="position:relative; top:10px;"
                                                    *ngIf="!item.isCollapsed">
                                                    <button pButton type="button" (click)="expandCard(item)"
                                                        class="p-button-secondary p-button-outlined card-toogle-button"
                                                        styleClass="card-toogle-button" icon="pi pi-external-link"
                                                        iconPos="left"></button>
                                                </div>
                                                <ng-container>
                                                    <div id="collapse_{{item.id}}"
                                                        class="collapse card-optional-content"
                                                        aria-labelledby="headingOne">
                                                        <hr class="w-100 ml-0" />
                                                        <div class="cardInnerData p-2">
                                                            <div class="row cardFont">
                                                                <label
                                                                    class="col font-italic text-muted pad-card-body">Eligibility
                                                                    Age</label>
                                                                <span class="col p-0">{{ item.minAge }} -
                                                                    {{ item.maxAge }}</span>
                                                            </div>
                                                            <div class="row cardFont">
                                                                <label
                                                                    class="col font-italic text-muted pad-card-body">Roll-Up
                                                                </label>
                                                                <span class="col p-0">{{ item.rollUp || 0 }}%
                                                                    compounded for 10Yrs.</span>
                                                            </div>
                                                            <div class="row cardFont">
                                                                <label
                                                                    class="col font-italic text-muted pad-card-body">Step-Ups</label>
                                                                <span class="col p-0">{{
                                                                    item.highAnnivValStepup && item.highAnnivValStepup
                                                                    != '' ?
                                                                    item.highAnnivValStepup : 'None'
                                                                    }}</span>
                                                            </div>
                                                            <div class="row cardFont">
                                                                <label
                                                                    class="col font-italic text-muted pad-card-body">Fee</label>
                                                                <span class="col p-0">{{
                                                                    item.riderFeedata || 0
                                                                    | number : "1.2-2"
                                                                    }}% of {{ item.chargeBasisName }}</span>
                                                            </div>
                                                            <div class="row cardFont">
                                                                <label
                                                                    class="col font-italic text-muted pad-card-body">Rider
                                                                    Bonus</label>
                                                                <span class="col p-0" *ngIf="item.bbBonus">{{
                                                                    item.bbBonus + '%'
                                                                    }}
                                                                    {{ item.bonusName }}
                                                                </span>
                                                                <span #noBonus>
                                                                    None
                                                                </span>
                                                            </div>
                                                            <div class="row cardFont">
                                                                <label
                                                                    class="col font-italic text-muted pad-card-body">Premium
                                                                    Bonus</label>
                                                                <span class="col p-0">{{
                                                                    item.premiumBonus ? item.premiumBonus + '%' : 'None'
                                                                    }}</span>
                                                            </div>
                                                            <div class="row cardFont">
                                                                <label
                                                                    class="col font-italic text-muted pad-card-body">Withdrawal
                                                                    %
                                                                </label>
                                                                <span class="col p-0">{{
                                                                    item.withdrawal
                                                                    | number : "1.2-2"
                                                                    }}%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="w-100 text-center" style="position:relative; top:50px;"
                                                        *ngIf="item.isCollapsed">
                                                        <button pButton type="button" (click)="expandCard(item)"
                                                            class="p-button-secondary p-button-outlined card-toogle-button"
                                                            styleClass="card-toogle-button" icon="pi pi-angle-up"
                                                            iconPos="left"></button>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <!-- Card view template end -->
                                    <ng-template pTemplate="empty">
                                        <h2 *ngIf="notFount == true">No records found</h2>
                                    </ng-template>
                                </p-dataView>
                            </div>
                            <!-- End Card/Data View and Sidebar Filter -->
                        </ng-container>
                        <ng-template #showMainSpinner>
                            <ng-container *ngIf="isLoader; else noData">
                                <p-progressSpinner class="text-center data-view-spinner"></p-progressSpinner>
                            </ng-container>
                            <ng-template #noData>
                                <div class="text-center pb-2 pt-4">
                                    <h2>No records found</h2>
                                </div>
                            </ng-template>

                        </ng-template>
                    </div>
                    <ng-container *ngIf="filteredArray.length > 0">
                        <!-- Comparison chip Data-View -->
                        <div>
                            <div *ngIf="isCompare == true" class="row compchip mt-2 mb-2">
                                <div class="p-d-flex p-ai-center col-10 text-center">
                                    <p-chip class="comp-chip ml-1" removeIcon="pi pi-trash" [removable]="true"
                                        *ngFor="let item of compareData" (onRemove)="oncompChipRemove(item)">
                                        {{ item.productName }}</p-chip>
                                </div>
                                <div class="text-end col-2 align-self-center comp-btn d-flex">
                                    <div class="cancelBtn">
                                        <button pButton label="Cancel" class="p-button p-component"
                                            (click)="clearCompareChange()"></button>
                                    </div>
                                    <div class="compBtn">
                                        <button pButton label="Compare ({{ totalCompare }})" icon="pi pi-copy"
                                            class="p-button p-component" (click)="
                                                                oncompareDatachange(compareData)
                                                            " [disabled]="
                                                                compareData.length >= 2
                                                                    ? false
                                                                    : true
                                                            "></button>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column md:flex-row md:justify-content-end"
                                [ngClass]="isLoader == true ? 'display' : ''">
                            </div>
                        </div>
                        <div class="load-more text-center" *ngIf="filteredArray.length > 0">
                            <div *ngIf="isLoader">
                                <p-progressSpinner [style]="{width: '50px', height: '50px'}"
                                    styleClass="custom-spinner"></p-progressSpinner>
                            </div>
                        </div>
                    </ng-container>



                </div>
            </div>
        </div>
    </div>
</div>
    <!-- Illustration page Dialog(POP-UP) -->
    <p-dialog header="Illustration" styleClass="illustration-dialog" [(visible)]="illustration"
        (onHide)="hideIllustrationDialog()" [modal]="true" [closable]="false"
        [style]="{ width: '90%', height: '1454px', background: '#F0F2F5' }" [baseZIndex]="10000">
        <p-header class="p-dialog-header-icons ng-tns-c107-2 download-btn">
            <div class="d-flex  w-100 justify-content-between">
                <div class="align-self-center download-btn">
                    <p-button icon="pi pi-arrow-left" (onClick)="closeIllustration()"></p-button>
                    <label>Illustration</label>
                </div>
                <div class="d-flex align-items-center illusdownload">
                    <p-progressSpinner [style]="{ width: '50px', height: '50px' }" styleClass="custom-spinner"
                        class="p-0 m-0 mt-1" strokeWidth="6" fill="var(--surface-ground)" *ngIf="isLoad == true">
                    </p-progressSpinner>
                    <button type="button" pButton icon="pi pi-download"
                        class="p-ripple p-element ng-tns-c107-2 p-dialog-header-icon p-dialog-header-maximize p-link ng-star-inserted"
                        (click)="onExport()">
                        Download
                    </button>
                </div>
            </div>
        </p-header>
        <p-progressSpinner class="illuLoader text-center" *ngIf="isLoader == true"></p-progressSpinner>
        <div id="export" #exportPdf [ngClass]="isLoader == true ? 'display' : ''">
            <div class="d-flex illutitle">
                <div class="m-4">
                    <img [src]="illu_Img" alt="Image" class="p-imgillu" />
                </div>
                <div class="productdata m-4 mr-0 w-100">
                    <div class="row w-100">
                        <div class="productCarrier d-flex">
                            <h4 class="m-0">{{ carrier }}</h4>
                        </div>
                    </div>
                    <div class="row pb-2 w-100">
                        <div class="col-4 p-0">
                            <div class="productIcon mb-0 d-flex">
                                <i class="pi pi-box"></i>
                                <span class="ml-3">Product Name </span>
                            </div>
                            <div class="inputgroup col-3 p-0">
                                <h4 class="">{{ productIllu }} <i *ngIf="illustrationResData.isShelfProduct"
                                        class="pi pi-star-fill"></i></h4>
                            </div>
                        </div>
                        <div class="col-3 p-0">
                            <div class="productIcon mb-0 d-flex">
                                <i class="pi pi-user"></i>
                                <span class="ml-3">Rider Name </span>
                            </div>
                            <div class="inputgroup col-2">
                                <h4 class="">{{ riderIllu }}</h4>
                            </div>
                        </div>
                        <div class="col-2 p-0">
                            <div class="productIcon">
                                <i class="pi pi-dollar"></i>
                                <span class="ml-3">Income Type</span>
                            </div>
                            <div class="inputgroup col-2">
                                <h4 class="">{{ otherVariable }}</h4>
                            </div>
                        </div>
                        <div class="col-2 p-0">
                            <div class="productIcon">
                                <i class="pi pi-calendar"></i>
                                <span class="ml-3">Effective Date</span>
                            </div>
                            <div class="inputgroup col-2">
                                <h4 class="">
                                    {{ illEffectDate | date : "MM/dd/yyyy" }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid row p-fluid assumption my-4 mx-1 justify-content-between">
                <div class="w-50">
                    <div class="rassum mr-2">
                        <div class="dataRider">
                            <span class="">Rider Assumption </span>
                        </div>
                        <hr />
                        <div class="dataRider1">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Client(s) Age
                                </span>
                                <!-- <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ riderAge }}</span> -->
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">
                                    <ng-container
                                        *ngIf="(agecheck > 0 && spouseagecheck == 0) || (agecheck == 0 && spouseagecheck > 0)">
                                        {{ agecheck > 0 ? agecheck : spouseagecheck}}
                                    </ng-container>
                                    <ng-container *ngIf="agecheck > 0 && spouseagecheck > 0">
                                        Age {{ agecheck }} / Spouse Age {{ spouseagecheck }}
                                    </ng-container>
                                </span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Roll-Up</span>
                                <div class="RidertitleData md:col-7 pl-0"
                                    *ngIf="rollupdata && rollupdata.length > 0; else noRollUp">
                                    <ng-container
                                        *ngIf="illustrationResData.marketingName && illustrationResData.marketingName != ''">
                                        <div class="row">
                                            <span class="md:col-12 py-0 pl-0 d-table-cell">{{
                                                illustrationResData.marketingName
                                                }}</span>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngFor="let rollup of rollupdata">
                                        <div class="row">
                                            <span class="md:col-12 py-0 px-0 d-table-cell">{{
                                                rollup
                                                }}</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-template #noRollUp>
                                    <span class="p-inputgroup RidertitleData md:col-7 pl-0">None</span>
                                </ng-template>

                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Step-Ups</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ illuStepUps }}</span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Fee</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ riderFeeIllu }}</span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Rider Bonus</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ benefitBonus }}</span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Premium Bonus</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ premiumBonus }}</span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Initial Withdrawal % </span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0"
                                    *ngIf="otherVariable != incomeTypes.MaxIncome && otherVariable != 'Max Income Option : MAWA'">{{
                                    withdrawalPer }}%</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0"
                                    *ngIf="otherVariable == incomeTypes.MaxIncome || otherVariable == 'Max Income Option : MAWA'">{{
                                    withdrawalPer }}% Withdrawals When AV = $0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-50">
                    <div class="illuassum ml-3">
                        <div class="dataRider">
                            <span class="">Illustration Assumption</span>
                        </div>
                        <hr />
                        <div class="dataRider1">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">
                                    <ng-container
                                        *ngIf="(agecheck > 0 && spouseagecheck == 0) || (agecheck == 0 && spouseagecheck > 0)">
                                        Single
                                    </ng-container>
                                    <ng-container *ngIf="agecheck > 0 && spouseagecheck > 0">
                                        Joint
                                    </ng-container>
                                    Income
                                </span>
                                <!-- <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ riderAge }}</span> -->
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">

                                    <ng-container
                                        *ngIf="(agecheck > 0 && spouseagecheck == 0) || (agecheck == 0 && spouseagecheck > 0)">
                                        {{ agecheck > 0 ? agecheck : spouseagecheck }}
                                    </ng-container>
                                    <ng-container *ngIf="agecheck > 0 && spouseagecheck > 0">
                                        {{ agecheck }} / {{ spouseagecheck }}
                                    </ng-container>
                                </span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Purchase Payment</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">${{ purchasePay | number :
                                    "1.0-0" }}</span>
                            </div>

                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Initial Benefit Base</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">
                                    ${{
                                    initialBenifit | number : "1.0-0"
                                    }}</span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Deferral Period</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ deferralPer }}</span>
                            </div>
                            <!-- <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Rider Bonus</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ benefitBonus }}</span>
                            </div> -->
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">State of Issue</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ selectedStateName }}</span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Waiting Period</span>
                                <span *ngIf="
                                        waitingPer == null || waitingPer == 0
                                    " class="p-inputgroup RidertitleData md:col-7 pl-0">
                                    None</span>

                                <span *ngIf="waitingPer > 1" class="p-inputgroup RidertitleData md:col-7 pl-0">{{
                                    waitingPer }} Years</span>
                                <span *ngIf="waitingPer == 1" class="p-inputgroup RidertitleData md:col-7 pl-0">{{
                                    waitingPer }} Year</span>
                            </div>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup Ridertitle md:col-5">Withrawal Frequency</span>
                                <span class="p-inputgroup RidertitleData md:col-7 pl-0">{{ withdrawalFrequency }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-2 illustration-note"
                *ngIf="otherVariable == incomeTypes.MaxIncome || otherVariable == 'Max Income Option : MAWA'">
                Important Note: The income generated by this rider is reduced once the account value equals zero
                dollars.
            </div>
            <div class="table my-4 illustration-table">
                <p-table [value]="illustrationtblResponse" [paginator]="paginateIllustration"
                    [rows]="paginateIllustration ? 20 : illustrationtblResponse.length" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    styleClass="p-datatable-gridlines illustration-table">
                    <ng-template pTemplate="header">
                        <tr class="text-center">
                            <th class="endyears" pSortableColumn="defYear">
                                Beginning Of Year<p-sortIcon field="defYear">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="age">
                                Age<p-sortIcon field="age"></p-sortIcon>
                            </th>
                            <th pSortableColumn="purchaseAmount">
                                <span>
                                    Purchase Payment
                                </span>
                                <p-sortIcon field="purchaseAmount">
                                </p-sortIcon>
                                <!-- <br> -->
                                <span style="margin-left:-30px">(Estimated)</span>
                            </th>
                            <th pSortableColumn="benefitBaseSimple">
                                Benefit Base<p-sortIcon field="benefitBaseSimple">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="rollup">
                                Roll-Up<p-sortIcon field="rollup"></p-sortIcon>
                            </th>
                            <th pSortableColumn="monthlyWithdrawal" *ngIf="withdrawalFrequency == 'Monthly'">
                                <span>
                                    Monthly Withdrawal
                                </span>
                                <p-sortIcon field="monthlyWithdrawal"></p-sortIcon>
                                <!-- <br> -->
                                <span style="margin-left:-30px">(Estimated)</span>
                            </th>
                            <th pSortableColumn="annualWithdrawal" *ngIf="withdrawalFrequency == 'Annually'">
                                <span>Annual Withdrawal</span>
                                <p-sortIcon field="annualWithdrawal"></p-sortIcon>
                                <!-- <br> -->
                                <span style="margin-left:-30px">(Estimated)</span>
                            </th>
                            <th pSortableColumn="annualWithdrawalPer" class="text-center">
                                <span>Withdrawal %</span> <p-sortIcon field="annualWithdrawalPer"></p-sortIcon>
                                <!-- <br> -->
                                <span style="margin-left:-30px">(Estimated)</span>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-illustrationtblResponse>
                        <tr class="text-center" [style.backgroundColor]="
                                +illustrationtblResponse.defYear == +diffperiode
                                    ? '#e9ecef'
                                    : ''
                            ">
                            <td>{{ illustrationtblResponse.defYear }}</td>
                            <td>{{ illustrationtblResponse.age }}</td>
                            <td>
                                $
                                {{
                                illustrationtblResponse.purchaseAmount
                                | number : "1.0-0"
                                }}
                            </td>
                            <td>
                                $
                                {{
                                illustrationtblResponse.benefitBaseSimple
                                | number : "1.0-0"
                                }}
                            </td>
                            <td>
                                {{ illustrationtblResponse.rollup }}%
                                {{ illustrationtblResponse.rollupType }}
                            </td>
                            <td class="text-center" *ngIf="withdrawalFrequency == 'Monthly'">
                                {{ illustrationtblResponse.defYear == 0 ? '-' : "$ " +
                                (illustrationtblResponse.monthlyWithdrawal | number : "1.0-0" )}}
                            </td>
                            <td class="text-center" *ngIf="withdrawalFrequency == 'Annually'">
                                {{ illustrationtblResponse.defYear == 0 ? '-' : "$ " +
                                (illustrationtblResponse.annualWithdrawal | number : "1.0-0" )}}
                            </td>
                            <td>
                                {{ illustrationtblResponse.defYear == 0 ? "-" :
                                (illustrationtblResponse.annualWithdrawalPer + '%')}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        Please note that this illustration is provided by Beacon
                        Annuity Solutions and should not be considered a
                        replacement for an actual illustration provided by the
                        Insurance Company.
                        {{ illEffectDate | date : "MM/dd/yyyy dd:MM:a" }}
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-dialog>
    <!-- Product Profile page Dialog(POP-UP) -->
    <p-dialog header="Product Profile" styleClass="illustration-dialog" [(visible)]="productProfile"
        (onHide)="showAccountDescription = false" [modal]="true" [style]="{ width: '90%', background: '#F0F2F5' }"
        [closable]="false" [baseZIndex]="10000">
        <p-progressSpinner class="illuLoader" *ngIf="isLoader == true"></p-progressSpinner>
        <p-header class="p-dialog-header-icons ng-tns-c107-2 pt-4">
            <div class="d-flex justify-content-between">
                <div class="align-self-center download-btn">
                    <p-button icon="pi pi-arrow-left" (onClick)="closeProductProfile()"></p-button>
                    <label>Product Profile</label>
                </div>
            </div>
        </p-header>
        <div class="" [ngClass]="isLoader == true ? 'display' : ''">
            <div class="">
                <div class="d-flex illutitle">
                    <div class="m-4">
                        <img src="{{ companyLogo1 }}" alt="Image" class="p-imgillu" />
                    </div>
                    <div class="w-100 px-4 pt-6">
                        <div class="row">
                            <div class="productCarrier">
                                <h2 class="head2 m-0">
                                    {{ productProfileDetails.productName }} <i
                                        *ngIf="productProfileDetails.isShelfProduct" class="pi pi-star-fill"></i>
                                </h2>
                                <h4 class="procompany m-0 mt-2">
                                    {{ productProfileDetails.companyName }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product-data-card my-4">
                    <div class="row pr-0 justify-content-between">
                        <div class="p-3 mb-4 white description w-68">
                            <div class="discData">
                                <h4 class="p-2 mb-0">Description</h4>
                            </div>
                            <div *ngIf="showEllipsis; else noEllipsis" class="samples fixed p-2">
                                <div ellipsis="View more" [ellipsis-content]="
                                                                    productProfileDetails.descr
                                                                " (ellipsis-click-more)="
                                                                    showEllipsis = false
                                                                " class="sample p-2"></div>
                            </div>
                            <ng-template #noEllipsis>
                                <p class="longText p-2">
                                    {{ productProfileDetails.descr }}
                                    <button class="text-blue-500 lessbtn" (click)="showEllipsis = true">
                                        Show less
                                    </button>
                                </p>
                            </ng-template>
                        </div>
                        <div class="pt-3 white contlimits w-31">
                            <div class="discData">
                                <h4 class="p-2 pl-3 mb-0">Contribution Limits</h4>
                            </div>
                            <div class="p-2">
                                <div class="row contlimit pr-1">
                                    <div class="col-md-6 p-0">
                                        <label class="plabel">State of Profile</label>
                                    </div>
                                    <div class="col-md-6 p-0 text-right">
                                        <label class="plabelData">{{
                                            selectedStateName
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row contlimit pr-1">
                                    <div class="col-md-6 p-0">
                                        <label class="plabel">Annuity Type</label>
                                    </div>
                                    <div class="col-md-6 p-0 text-right">
                                        <label class="plabelData">{{
                                            "Index"
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row contlimit pr-1">
                                    <div class="col-md-6 p-0">
                                        <label class="plabel">Product Inception</label>
                                    </div>
                                    <div class="col-md-6 p-0 text-right">
                                        <label class="plabelData">{{
                                            productProfileDetails.incdate
                                            | date
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row contlimit pr-1">
                                    <div class="col-md-6 p-0">
                                        <label class="plabel">Premium Type</label>
                                    </div>
                                    <div class="col-md-6 p-0 text-right">
                                        <label class="plabelData">{{
                                            premiumType
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row contlimit pr-1">
                                    <div class="col-md-6 p-0">
                                        <label class="plabel">Proprietary</label>
                                    </div>
                                    <div class="col-md-6 p-0 text-right">
                                        <label class="plabelData">{{
                                            properity
                                            }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-xl-4 pt-3 white keyfeature">
                            <div class="discData px-3">
                                <h4 class="head4 p-2 mb-0">Key Features</h4>
                            </div>
                            <div class="keyscroll px-3">
                                <div class="key-items1 mb-4">
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel header-text">Guarantees</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Minimum Surrender Value</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">{{ productProfileDetails.minguarate * 100 }}
                                                % &#64;
                                                {{
                                                productProfileDetails.mininitguarperc *
                                                100
                                                }}
                                                %</label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="key-items2 mb-4">
                                    <div class="row py-2">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel header-text">Fees and Expenses</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Strategy Fees(avg)</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">{{ strategyFee ? strategyFee + '%' : '-'
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Rider Fee(avg)</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">{{ riderFee ? riderFee + '%' : '-' }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Contract Fee</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">-</label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="key-items3 mb-4">
                                    <div class="row py-2">
                                        <div class="col-md-12 p-0">
                                            <label class="plabel header-text">Liquidity</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Free Look</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">{{
                                                productProfileDetails.freeperiod
                                                }}
                                                Years</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Surrender Charge Period</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">{{
                                                productProfileDetails.surryr
                                                }}
                                                Years</label>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Annual Free Withdrawal</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-center">
                                            <label class="plabelData">{{
                                                productProfileDetails.annfreepct
                                                }}%</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData text-primary"
                                                (click)="showAccountDescription = !showAccountDescription"
                                                role="button">Description <i *ngIf="!showAccountDescription"
                                                    class="pi pi-arrow-down"></i> <i class="pi pi-arrow-up"
                                                    *ngIf="showAccountDescription"></i></label>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="showAccountDescription">
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 pl-2 mb-4 col-xl-12 white">
                                                <div class="description-header">
                                                    <h4>Annual Free Withdrawal Description</h4>
                                                </div>
                                                <p class="py-2">
                                                    {{ productProfileDetails.desAnnfre }}
                                                </p>
                                            </div>
                                        </div>
                                        <hr>
                                    </ng-container>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Waivers</label>
                                        </div>
                                        <div class="col-md-6 col-xl-6 pr-2 text-right">
                                            <label class="plabelData">{{
                                                productProfileDetails.waivType?.replaceAll(',', ', ')
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">MVA</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-end">
                                            <label *ngIf="
                                                    productProfileDetails.mva ==
                                                        null ||
                                                    productProfileDetails.mva ==
                                                        false
                                                " class="plabelData">
                                                No
                                            </label>
                                            <label *ngIf="
                                                    productProfileDetails.mva ==
                                                    true
                                                " class="plabelData">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="key-items4 mb-4">
                                    <div class="row py-2">
                                        <div class="col-md-12 p-0">
                                            <label class="plabel header-text">Miscellaneous</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Premium Bonus</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">{{
                                                preBonuse
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-8 p-0">
                                            <label class="plabel">Vesting/Bonus Recapture</label>
                                        </div>
                                        <div class="col-md-6 col-xl-4 pr-2 text-right">
                                            <label class="plabelData">{{
                                                vestingBonuse
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="key-items5">
                                    <div class="row py-2">
                                        <div class="col-md-12">
                                            <label class="plabel header-text">Riders</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-7 p-0">
                                            <label class="plabel">GMWB</label>
                                        </div>
                                        <div class="col-md-6 col-xl-5 pr-2 text-right">
                                            <label class="plabelData">{{ productProfileDetails.gmwbRiderName || '-'
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-7 p-0">
                                            <label class="plabel">GMAB</label>
                                        </div>
                                        <div class="col-md-6 col-xl-5 pr-2 text-right">
                                            <label class="plabelData">{{ productProfileDetails.gmabName || '-'
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-7 p-0">
                                            <label class="plabel">GMIB</label>
                                        </div>
                                        <div class="col-md-6 col-xl-5 pr-2 text-right">
                                            <label class="plabelData">{{ productProfileDetails.gmibName || '-'
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-7 p-0">
                                            <label class="plabel">Death Benefit</label>
                                        </div>
                                        <div class="col-md-6 col-xl-5 pr-2 text-right">
                                            <label class="plabelData">{{ productProfileDetails.deathBenefitsName ||
                                                '-'}}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-7 p-0">
                                            <label class="plabel"> Combine Benefit</label>
                                        </div>
                                        <div class="col-md-6 col-xl-5 pr-2 text-right">
                                            <label class="plabelData">{{ productProfileDetails.combineBenefitsName ||
                                                '-' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 col-xl-4 px-4">
                            <div class="key-items p-3 white mb-4 keyfeature1">
                                <div class="discData">
                                    <h4 class="head4 p-2 mb-0">Age Qualifications</h4>
                                </div>
                                <div class="scrolly">
                                    <div class="row py-2">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel header-text">All States</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData header-text">Qualified</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData header-text">Non-Qualified</label>
                                        </div>
                                    </div>
                                    <div class="row agequa">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Owner</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label
                                                *ngIf="productProfileDetails.minoq && productProfileDetails.minoq >= 0"
                                                class="plabelData">{{
                                                productProfileDetails.minoq }}-{{
                                                productProfileDetails.mxoq }}</label>
                                            <label *ngIf="productProfileDetails.minoq ==  null"
                                                class="plabelData">N/A</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label
                                                *ngIf="productProfileDetails.minonq && productProfileDetails.minonq >= 0"
                                                class="plabelData">{{
                                                productProfileDetails.minonq }}-{{ productProfileDetails.mxonq
                                                }}</label>
                                            <label *ngIf="productProfileDetails.minonq == null"
                                                class="plabelData">N/A</label>
                                        </div>
                                    </div>
                                    <div class="row agequa">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Annuitant</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label *ngIf="
                                                    productProfileDetails.minaq && 
                                                    productProfileDetails.minaq >=
                                                    0
                                                " class="plabelData">{{
                                                productProfileDetails.minaq
                                                }}-{{
                                                productProfileDetails.mxaq
                                                }}</label>
                                            <label *ngIf="
                                                    productProfileDetails.minaq ==
                                                    null
                                                " class="plabelData">N/A</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label *ngIf="
                                                    productProfileDetails.minaq && 
                                                    productProfileDetails.minanq >=
                                                    0
                                                " class="plabelData">{{
                                                productProfileDetails.minanq
                                                }}-{{
                                                productProfileDetails.mxanq
                                                }}</label>
                                            <label *ngIf="
                                                    productProfileDetails.minanq ==
                                                    null
                                                " class="plabelData">N/A</label>
                                        </div>
                                    </div>
                                    <div class="row agequa">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Maturity</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label *ngIf="productProfileDetails.matageq >= 0" class="plabelData">{{
                                                productProfileDetails.matageq
                                                }}</label>
                                            <label *ngIf="
                                                                                            productProfileDetails.matageq ==
                                                                                            null
                                                                                        " class="plabelData">
                                                N/A
                                            </label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label *ngIf="
                                                    productProfileDetails.matage >=
                                                    0
                                                " class="plabelData">{{
                                                productProfileDetails.matage
                                                }}</label>
                                            <label *ngIf="
                                                    productProfileDetails.matage ==
                                                    null
                                                " class="plabelData">
                                                N/A
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row agequa">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Max Annuitization</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label *ngIf="
                                                    productProfileDetails.mxazq >=
                                                    0
                                                " class="plabelData">{{
                                                productProfileDetails.mxazq
                                                }}</label>
                                            <label *ngIf="
                                                    productProfileDetails.mxazq ==
                                                    null
                                                " class="plabelData">N/A</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label *ngIf="
                                                    productProfileDetails.mxaznq >=
                                                    0
                                                " class="plabelData">{{
                                                productProfileDetails.mxaznq
                                                }}</label>
                                            <label *ngIf="
                                                    productProfileDetails.mxaznq ==
                                                    null
                                                " class="plabelData">N/A</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="key-items p-3 white mb-4 keyfeature2">
                                <div class="discData">
                                    <h4 class="head4 p-2 mb-0">Indexes Available</h4>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-xl-8 py-2">
                                        <h5 class="plabel header-text">Index</h5>
                                        <div class="p-0" *ngFor="
                                                                                                let item of index;
                                                                                                let i = index
                                                                                            ">
                                            <label class="plabel">{{
                                                item
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xl-4 py-2 text-right">
                                        <h5 class="plabel header-text">Ticker</h5>
                                        <div class="p-0 text-right" *ngFor="let item of tiker; let i = index">
                                            <label class="plabelticker">{{
                                                item
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="key-items p-3 white mb-4 keyfeature3">
                                <div class="discData">
                                    <h4 class="head4 p-2 mb-0">Crediting Strategies</h4>
                                </div>
                                <div class="p-2">
                                    <div class="row" *ngFor="
                                            let item of credStrategies;
                                            let i = index
                                        ">
                                        <label class="plabelticker">{{
                                            item
                                            }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="key-items p-3 white mb-4 keyfeature4">
                                <div class="discData">
                                    <h4 class="head4 p-2 mb-0">Available Rate Bands</h4>
                                </div>
                                <div class="p-2">
                                    <ng-container *ngIf="productProfileDetails.rateBand; else noBands">
                                        <div class="row" *ngFor="let rate of productProfileDetails.rateBand.split(',')">
                                            <label class="plabelticker">${{ rate | number }}</label>
                                        </div>
                                    </ng-container>
                                    <ng-template #noBands>
                                        <p>No rate bands available</p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 col-xl-4">
                            <div class="key-items p-3 white mb-4 keyfeature1">
                                <div class="discData">
                                    <h4 class="head4 p-2 mb-0">Contribution Limits</h4>
                                </div>
                                <div class="scrolly">
                                    <div class="row py-2">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel header-text">All States</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData header-text">Qualified</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData header-text">Non-Qualified</label>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Minimum</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData">${{
                                                (productProfileDetails.minq
                                                | number : "1.0-0") || 0
                                                }}</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData">${{
                                                (productProfileDetails.minnq
                                                | number : "1.0-0") || 0
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Minimum additional</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData">${{
                                                (productProfileDetails.minaddlq
                                                | number : "1.0-0") || 0
                                                }}</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData">${{
                                                (productProfileDetails.minaddlnq
                                                | number : "1.0-0") || 0
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Maximum</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData">${{
                                                (productProfileDetails.maxi
                                                | number : "1.0-0") || 0
                                                }}</label>
                                        </div>
                                        <div class="col-md-6 col-xl-3 p-0 text-right">
                                            <label class="plabelData">${{
                                                (productProfileDetails.maxi
                                                | number : "1.0-0") || 0
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="productProfileDetails.desMax">
                                        <label class="text-danger m-3">*<label class="contbottom">{{
                                                productProfileDetails.desMax
                                                }}</label></label>
                                    </div>
                                </div>
                            </div>
                            <div class="key-items money-items p-3 mb-4 white keyfeature2">
                                <div class="discData">
                                    <h4 class="head4 p-2 mb-0">Money Accepted</h4>
                                </div>
                                <div class="scrolly">
                                    <div class="row">
                                        <div class="col-md-4 col-xl-4 p-0">
                                            <h5 class="plabel">
                                                <span [ngClass]="
                                                productProfileDetails.nonqual
                                                    ? 'bold'
                                                    : 'light'
                                            ">
                                                    Non-Qualified
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="col-md-4 col-xl-4 p-0 text-right">
                                            <h5 class="plabelData">
                                                <span [ngClass]="
                                                        productProfileDetails.four01k
                                                            ? 'bold'
                                                            : 'light'
                                                    ">
                                                    401K
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="col-md-4 col-xl-4 p-0 text-right">
                                            <h5 class="plabelData">
                                                <span [ngClass]="
                                                        productProfileDetails.pension
                                                            ? 'bold'
                                                            : 'light'
                                                    ">
                                                    Pension
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-xl-4 p-0">
                                            <h5 class="plabel">
                                                <span [ngClass]="
                                                productProfileDetails.ira
                                                    ? 'bold'
                                                    : 'light'
                                            ">
                                                    IRA
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="col-md-4 col-xl-4 p-0 text-right">
                                            <h5 class="plabelData">
                                                <span [ngClass]="
                                                        productProfileDetails.tsa403b
                                                            ? 'bold'
                                                            : 'light'
                                                    ">
                                                    TSA 403b
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="col-md-4 col-xl-4 p-0 text-right">
                                            <h5 class="plabelData">
                                                <span [ngClass]="
                                                        productProfileDetails.other
                                                            ? 'bold'
                                                            : 'light'
                                                    ">
                                                    Other
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-xl-4 p-0">
                                            <h5 class="plabel">
                                                <span [ngClass]="
                                                productProfileDetails.sepira
                                                    ? 'bold'
                                                    : 'light'
                                            ">
                                                    SEP IRA
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="col-md-4 col-xl-4 p-0 text-right">
                                            <h5 class="plabelData">
                                                <span [ngClass]="
                                                        productProfileDetails.keogh
                                                            ? 'bold'
                                                            : 'light'
                                                    ">
                                                    KEOGH
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-xl-4 p-0">
                                            <h5 class="plabel">
                                                <span [ngClass]="
                                                productProfileDetails.four01a
                                                    ? 'bold'
                                                    : 'light'
                                            ">
                                                    Roth IRA
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="col-md-4 col-xl-4 p-0 text-right">
                                            <h5 class="plabelData">
                                                <span [ngClass]="
                                                        productProfileDetails.profsh
                                                            ? 'bold'
                                                            : 'light'
                                                    ">
                                                    Profit Sharing
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="key-items border-round p-3 white mb-4 keyfeature3">
                                <div class="discData">
                                    <h4 class="head4 p-2 mb-0">
                                        Operations and Management
                                    </h4>
                                </div>
                                <div class="scrolly">
                                    <div class="row pb-5">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Insurance Company</label>
                                        </div>
                                        <div class="col-md-6 col-xl-6 p-0 text-right">
                                            <label class="plabelData">{{
                                                productProfileDetails.companyName
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-6 p-0">
                                            <label class="plabel">Telephone</label>
                                        </div>
                                        <div class="col-md-6 col-xl-6 p-0 text-right">
                                            <label class="plabelData">{{
                                                productProfileDetails.telephone
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xl-6 p-0 site">
                                            <label class="plabel">Web Site</label>
                                        </div>
                                        <div class="col-md-6 col-xl-6 p-0 text-blue-500 text-right">
                                            <a href="#" class="plabelData">{{
                                                productProfileDetails.webaddress
                                                }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="m-4 white">
                    <div class="row">
                        <div class="col-6 col-sm-9 col-md-8 col-xxl-6 p-0">
                            <span class="p-1 plabelData">Copyright© 2023 Beacon Annuity Solutions, LLC
                                All Rights Reserved.
                            </span>
                        </div>
                        <div class="col-6 col-sm-9 col-md-8 col-xxl-6 p-0 text-end">
                            <span class="p-1 mr-6 plabelData">Terms of Use & Privacy Policy</span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </p-dialog>
    <!-- Product Comparison page Dialog(POP-UP) -->
    <p-dialog header="Rider Comparison" [(visible)]="showCompareDialog" (onHide)="clearCompareChange()" [modal]="true"
        [style]="{ width: '1920px', height: '1454px' }" [baseZIndex]="10000">
        <p-header class="p-dialog-header-icons ng-tns-c107-2 profileHeader">
            <div class="d-flex download-btn justify-content-between">
                <div class="align-self-center">
                    <label>Rider Comparison</label>
                    <p class="shelf-product-indicator ml-4"> (<i class="pi pi-star-fill"></i> indicates shelf product )
                    </p>
                </div>
            </div>
        </p-header>
        <div class="container p-0 container-profile">
            <p-table>
                <ng-template pTemplate="header">
                    <div class="tableDatacompare">
                        <tr class="d-flex trtop">
                            <th class="col comtitle p-0 theadtitle"></th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                <img src="{{ item.logo1 }}" alt="Image" class="p-imgillu" />
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Company Name</th>
                            <td class="col text-center border-round compare-margin p-0 fw-bold font-22"
                                *ngFor="let item of compareData">
                                {{ item.companyName }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Product Name</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                <a href="javascript:void(0)" class="font-22"
                                    (click)="reditectToProduct(item.productid)">{{ item.productName }} <i
                                        *ngIf="item.isShelfProduct" class="pi pi-star-fill"></i></a>
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Rider Name</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.riderName }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Fee</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                <!-- {{ item.riderFeeBasis }} -->
                                {{
                                item.riderFeedata || 0
                                | number : "1.2-2"
                                }}% of {{ item.chargeBasisName }} {{ item.chargeFreqDeductedWhen }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Client(s) Age</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.riderAge }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0 theadtitlebottom">
                                Waiting Period
                            </th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                <ng-container *ngIf="item.waitingTerm == 'Contract Years'; else ageTemplate">
                                    {{ item.waitingPeriod > 0 ? item.waitingPeriod + ' Year(s)' : '-'}}
                                </ng-container>
                                <ng-template #ageTemplate>
                                    {{ item.waitingPeriod > 0 ? 'Age ' + item.waitingPeriod : '-'}}
                                </ng-template>
                            </td>
                        </tr>
                        <tr class="d-flex" *ngIf="incCont == 'contribution'">
                            <th class="col comtitle p-0">Initial Income Amount(Estimated)</th>
                            <td class="col text-center border-round compare-margin p-0 compPreIncom"
                                *ngFor="let item of compareData">
                                {{ item.annualIncome }}
                            </td>
                        </tr>
                        <tr class="d-flex" *ngIf="incCont == 'income'">
                            <th class="col comtitle p-0">Inital Purchase Payment</th>
                            <td class="col text-center border-round compare-margin p-0 compPreIncom"
                                *ngFor="let item of compareData">
                                {{ item.annualIncome ? (item.annualIncome) : 0 }}
                            </td>
                        </tr>
                        <tr class="d-flex" *ngIf="incCont == 'income'">
                            <th class="col comtitle p-0">Targeted Income Amount</th>
                            <td class="col text-center border-round compare-margin p-0 compPreIncom"
                                *ngFor="let item of compareData">
                                ${{ amountcheck | number }}
                            </td>
                        </tr>

                        <tr class="d-flex">
                            <th class="col comtitle p-0 theadtitlebottom">
                                Withdrawal Frequency
                            </th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ withdrawalFrequency }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Income Type</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                <!-- {{ item.otherVariable }} --> {{ item.benifitBaseType }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">
                                Withdrawal %
                            </th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.withdrawalPer }}%
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">
                                Single or Joint Income
                            </th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.singleJoinIncomeData }}
                            </td>
                        </tr>
                        <!-- <tr class="d-flex">
                            <th class="col comtitle p-0">
                                Max. Income Options
                            </th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.riderName }}
                            </td>
                        </tr> -->
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Roll-Up</th>
                            <td class="col text-center border-round comparedata compare-margin p-0"
                                *ngFor="let item of compareData; let i = index">
                                <ng-container *ngIf="item.marketingName && item.marketingName != ''">
                                    <div class="">{{
                                        item.marketingName
                                        }}</div>
                                </ng-container>
                                <ng-container *ngIf="item.rolldata.length > 0; else norolldata">
                                    <div *ngFor="let rollitem of item.rolldata">
                                        {{ rollitem }}
                                    </div>
                                </ng-container>
                                <ng-template #norolldata>
                                    <span> None </span>
                                </ng-template>
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Step-Ups</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.IncomePeriod.replace('Estimated ', '') }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Rider Bonus</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.bbBonus ? item.bbBonus + '%' : 'None' }}
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Premium Bonus</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{
                                item.premiumBonus ? item.premiumBonus + '%' : 'None'
                                }}
                                <ng-container *ngIf="item.bonusPercentageBasis">
                                    of {{ getBonusBasis(item.bonusPercentageBasis) }}
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="d-flex">
                            <th class="col comtitle p-0">
                                Initial Benefit Base
                            </th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.initialBenifitdata ? '$' + (item.initialBenifitdata | number) : 0 }}
                            </td>
                        </tr>
                        <!-- <tr class="d-flex">
                            <th class="col comtitle p-0">Purchase Payment</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.purchasePayment ? '$' + (item.purchasePayment | number) : 0 }}
                            </td>
                        </tr> -->
                        <tr class="d-flex">
                            <th class="col comtitle p-0">Deferral Period</th>
                            <td class="col text-center border-round compare-margin p-0"
                                *ngFor="let item of compareData">
                                {{ item.deffralPer }} years
                            </td>
                        </tr>
                    </div>
                </ng-template>
            </p-table>
        </div>
    </p-dialog>
    <!-- Save Search Dualog(POP-UP)-->
    <p-dialog header="Save this Search" [(visible)]="saveSeavch" (onHide)="searchDataForm.reset()" [modal]="true"
        [style]="{ width: '508px', height: '320px' }" [baseZIndex]="10000">
        <p-header>
            <div class="searchHeading">
                <label>Save this Search</label>
            </div>
        </p-header>
        <hr class="m-0" />
        <form [formGroup]="searchDataForm" class="mx-2" (ngSubmit)="saveSearch()">
            <div class="searchData">
                <div class="d-grid">
                    <label class="mb-2">Custom name your search</label>
                    <input type="text" formControlName="searchtitle" placeholder="Enter search name...." pInputText
                        [(ngModel)]="searchtitle" formControlName="searchtitle" required />
                    <div *ngIf="searchDataFormControls.searchtitle.invalid && ((searchDataFormControls.searchtitle.dirty || searchDataFormControls.searchtitle.touched) || saveFormSubmitted)"
                        class="alert alert-danger form-danger" role="alert">
                        <div
                            *ngIf="searchDataFormControls.searchtitle.errors.required || searchDataFormControls.searchtitle.errors.whitespace">
                            Please enter search name.
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="save text-center">
                    <!-- <div class="canceSearch">
                        <button
                            type="button"
                            pButton
                            class="m-2"
                            (click)="saveCancel()"
                        >
                            CANCEL
                        </button>
                    </div> -->
                    <div class="searchSave">
                        <button type="button" pButton class="m-2" (click)="saveSearch()">
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </p-dialog>
    <p-dialog header="New Save Searches" [(visible)]="createShelfPopup" [modal]="true"
        [style]="{ width: '508px', height: '268px' }" [baseZIndex]="10000" (onHide)="cancelCustomsearch()">
        <p-header>
            <div class="searchHeading">
                <label>Create New Shelf</label>
            </div>
        </p-header>
        <hr class="m-0" />
        <div class="deletDialog">
            <label>You don't have any custom shelf, Want to create one?</label>
        </div>
        <div class="">
            <div class="save d-flex">
                <div class="canceSearch">
                    <button type="button" pButton class="m-2" (click)="cancelCustomsearch()">
                        No
                    </button>
                </div>
                <div class="searchSave">
                    <button type="button" pButton class="m-2" (click)="createCustomNew()">
                        Yes, Create
                    </button>
                </div>
            </div>
        </div>
    </p-dialog>
    <!--Temporary dialog for showing full product card-->
    <p-dialog [(visible)]="showProductDialog" [modal]="true" appendTo="body" [draggable]="false" [resizable]="false"
        styleClass="product-detail-dialog">
        <ng-container class="" *ngIf="currentProduct">
            <div class="product-detail-card position-relative" id="product_{{currentProduct.id}}">
                <div class="">
                    <div class="d-flex w-100" [ngClass]="{ 'bg-green' : currentProduct.benifitBaseType === 'Inflation Based', 
                    'bg-blue':
                    currentProduct.benifitBaseType ===
                        'Increasing',
                    'bg-orange':
                    currentProduct.benifitBaseType ===
                        'Level',
                    'bg-white':
                    currentProduct.benifitBaseType == null
                }">
                        <div class="basedIncome text-center" [ngClass]="{
                        basedIncome1:
                        currentProduct.benifitBaseType ===
                            'Inflation Based',
                        basedIncome2:
                        currentProduct.benifitBaseType ===
                            'Increasing',
                        basedIncome3:
                        currentProduct.benifitBaseType ===
                            'Level',
                        basedIncome4:
                        currentProduct.benifitBaseType == null
                    }">
                            <label>{{ currentProduct.benifitBaseType == 'Max Income Option' ? 'Max Income*' : currentProduct.benifitBaseType }} </label>
                        </div>
                        <div class="text-right close-btn">
                            <button pButton type="button" (click)="showProductDialog = false"
                                class="p-button-secondary p-button-rounded" styleClass="btn-close-dialog"
                                icon="pi pi-times" iconPos="left"></button>
                        </div>
                    </div>

                    <div class="data-height  d-flex">
                        <div class="p-2">
                            <p-image src="{{ currentProduct.logo1 }}" alt="Image" width="90" class="p-img">
                            </p-image>
                        </div>
                        <div class=" card-bottom text-center">
                            <label class="head-lable">${{
                                currentProduct.anualIncom
                                | number : "1.0-0" || 0
                                }}</label>
                            <br /> <span> {{ currentProduct.IncomePeriod }} </span>
                        </div>
                    </div>
                    <h4 class="cardHeadingName p-2">
                        {{ currentProduct.companyName }}
                    </h4>
                    <div class="card-body pad-card-main">
                        <div class="p-2 cardFonttitle">
                            <div class="productIcon d-flex">
                                <i class="pi pi-box"></i>
                                <h4 class="m-0 pl-1">
                                    {{ currentProduct.productName }} <i *ngIf="currentProduct.isShelfProduct"
                                        class="pi pi-star-fill"></i>
                                </h4>
                            </div>
                            <div class="productIcon d-flex">
                                <i class="pi pi-user"></i>
                                <h4 class="m-0 pl-1">
                                    {{ currentProduct.riderName }}
                                </h4>
                            </div>
                            <div class="productIcon d-flex">
                                <i class="pi pi-dollar"></i>
                                <h4 class="m-0 pl-1">
                                    {{
                                    currentProduct.otherVariable ||
                                    "Standard Option"
                                    }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="w-100 ml-0" />
                <div class="cardInnerData p-2">
                    <div class="row cardFont">
                        <!-- <label class="col font-italic text-muted pad-card-body">Eligibility
                        Age</label>
                    <span class="col p-0">{{ currentProduct.minAge }} -
                        {{ currentProduct.maxAge }}</span> -->
                        <label class="col-md-4 font-italic text-muted pad-card-body">Client(s) Age
                        </label>
                        <span class="col-md-8 p-0">
                            <ng-container
                                *ngIf="(agecheck > 0 && spouseagecheck == 0) || (agecheck == 0 && spouseagecheck > 0)">
                                {{ agecheck > 0 ? agecheck : spouseagecheck}}
                            </ng-container>
                            <ng-container *ngIf="agecheck > 0 && spouseagecheck > 0">
                                Age {{ agecheck }} / Spouse Age {{ spouseagecheck }}
                            </ng-container>
                        </span>
                    </div>
                    <div class="row cardFont">
                        <label class="col-md-4 font-italic text-muted pad-card-body">Roll-Up
                        </label>
                        <div class="col-md-8 p-0">
                            <ng-container *ngIf="currentProduct.marketingName">
                                <div class="col-md-12">{{
                                    currentProduct.marketingName
                                    }}</div>
                            </ng-container>
                            <ng-container
                                *ngIf="currentProduct.rollUpdata && currentProduct.rollUpdata.length > 0; else noRollUp">
                                <ng-container *ngFor="let rollup of currentProduct.rollUpdata">
                                    <div class="col-md-12">{{
                                        rollup
                                        }}</div>
                                </ng-container>
                            </ng-container>
                            <ng-template #noRollUp>
                                <span class="p-inputgroup RidertitleData pl-0">None</span>
                            </ng-template>
                        </div>
                        <!-- <span class="col p-0">{{ currentProduct.rollUp || 0 }}%
                        compounded for 10Yrs.</span> -->
                    </div>
                    <div class="row cardFont">
                        <label class="col-md-4 font-italic text-muted pad-card-body">Step-Ups</label>
                        <span class="col-md-8 p-0">{{
                            currentProduct.highAnnivValStepup && currentProduct.highAnnivValStepup != '' ?
                            currentProduct.highAnnivValStepup : 'None'
                            }} </span>
                    </div>
                    <div class="row cardFont">
                        <label class="col-md-4 font-italic text-muted pad-card-body">Fee</label>
                        <span class="col-md-8 p-0">{{
                            currentProduct.riderFeedata || 0
                            | number : "1.2-2"
                            }}% of {{ currentProduct.chargeBasisName }}</span>
                    </div>
                    <div class="row cardFont">
                        <label class="col-md-4 font-italic text-muted pad-card-body">Rider Bonus</label>
                        <span class="col-md-8 p-0" *ngIf="currentProduct.bbBonus; else noBBBonus; ">{{
                            currentProduct.bbBonus + '%' }} {{
                            currentProduct.bonusName }}
                        </span>
                        <ng-template #noBBBonus>
                            <span class="col-md-8 p-0">
                                None
                            </span>
                        </ng-template>

                    </div>
                    <div class="row cardFont">
                        <label class="col-md-4 font-italic text-muted pad-card-body">Premium
                            Bonus</label>
                        <span class="col-md-8 p-0"> {{ currentProduct.premiumBonus ? currentProduct.premiumBonus + '%' :
                            'None' }} </span>
                    </div>
                    <div class="row cardFont">
                        <label class="col-md-4 font-italic text-muted pad-card-body">Withdrawal %
                        </label>
                        <span class="col-md-8 p-0">{{
                            currentProduct.withdrawal
                            | number : "1.2-2"
                            }}%</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </p-dialog>
