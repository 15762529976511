import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomShelfRoutingModule } from './custom-shelf-routing.module';
import { CustomShelfComponent } from './custom-shelf.component';
import { MenuModule } from 'primeng/menu';
import { TreeModule } from 'primeng/tree';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { BeaconSharedModule } from 'src/app/shared/shared.module';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AddUpdateCustomShelfComponent } from './add-update-custom-shelf/add-update-custom-shelf.component';
import { InputTextModule } from 'primeng/inputtext';
import { ViewCustomShelfComponent } from './view-custom-shelf/view-custom-shelf.component';


@NgModule({
  declarations: [
    CustomShelfComponent,
    AddUpdateCustomShelfComponent,
    ViewCustomShelfComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CustomShelfRoutingModule,
    MenuModule,
    TreeModule,
    RadioButtonModule,
    DropdownModule,
    ButtonModule,
    AccordionModule,
    ChipModule,
    DataViewModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    DialogModule,
    MultiSelectModule,
    ToastModule,
    BeaconSharedModule,
    DynamicDialogModule,
    InputTextModule
  ],
  providers: [
    DialogService
  ],
  exports: [
    CustomShelfComponent,
    AddUpdateCustomShelfComponent,
    ViewCustomShelfComponent
  ]
})
export class CustomShelfModule { }
