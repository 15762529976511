import { Component, OnDestroy, OnInit, Renderer2, ViewChild, HostListener, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, take } from 'rxjs';
import { LayoutService } from "./service/app.layout.service";
import { BenefitcalculatorService } from '../service/benefitcalculator.service';
import { Product, ProductChildDetail, ProductDetail, ProductDetails } from '../models/product';
import { AMBestRating, ConfiMultiplier, Groups, illustrationtblResponse, RiderFee, StateData, StrategyFee, SurrenderYear } from '../models/filter';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { GridData } from '../models/customer';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Paginator } from 'primeng/paginator';
import { ScrollService } from '../service/scroll.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BonusType, BonusTypeId } from '../models/product.constant';

declare var $: any;

enum StateOption {
    Contribution = "contribution",
    Income = "income"
}
enum MonthAnuallyOption {
    Monthly = 0,
    Annually = 1
}
enum sortByData {
    WithdrawalAmount = "Withdrawal Amount",
    CompanyName = "Company Name",
    ProductName = "Product Name"
}
enum allOptions {
    select1 = 'Slect1',
    select2 = 'Slect1',
    select3 = 'Slect1'
}
enum varData {
    All = "All",
    Level = "Level",
    Increasing = "Increasing",
    InflationBased = "Inflation Based",
    MaxIncome = "Max Income Option"
}

@Component({
    selector: 'app-layout',
    templateUrl: './app.layout.component.html',
    styleUrls: ['./app.layout.component.scss']
    // providers: [MessageService]
})
export class AppLayoutComponent implements OnDestroy, OnInit, AfterViewInit {

    @ViewChild('exportPdf') exportPdf!: ElementRef;
    filterEdit: any
    savedtype: string = '';
    IsCustomShelf: boolean = false;
    // CustomShelflist: any[] = [];
    createShelfPopup: boolean = false;
    incomeTypes = varData;
    //topbar form
    topbarForm = this.fb.group({
        gender: ['', Validators.required],
        age: [0, Validators.required],
        acalendar: ['', Validators.required],
        maritalstatus: ['joint', Validators.required],
        pouseage: [0],
        scalendar: [''],
        illinois: [''],
        ridertype: [''],
        Contribution: [''],
        income: [''],
        amount: [100000, Validators.required],
        periode: [true],
        diffperiode: [10],
    })

    searchDataForm = this.fb.group({
        searchtitle: ['', [Validators.required, this.noWhitespaceValidator]]
    })
    saveFormSubmitted: boolean = false;

    //Product Profile
    userlogo: any;
    username: any;
    productProfileDetails: any = {};
    pproductProfileData: any[] = [];
    selectedStateName: any;

    stateCode: string = '';
    productId: number = 0;
    properity: string = '';

    companyLogo1: any;
    companyLogo2: any;
    premiumType: string = '';
    preBonuse: string = '';
    vestingBonuse: string = '';
    credStrategies: any[] = [];
    indexesAvailable: any;
    indexes: any;
    index: any[] = [];
    tiker: any[] = [];
    pspouseAge: Number = 0;

    //Product Profile
    agecheck: number = 0;
    spouseagecheck: number = 0;
    amountcheck: number = 0;
    diffperiodecheck: number = 0;

    //topbar vars 
    incCont: string = 'contribution';
    periodeam: number = 1;
    requestValues: any = {};
    filteredArray: any = [];
    ispagination: boolean = false;
    first: number = 0;
    notFount: boolean = false
    stateCodeFilter: any;
    genderDataList: any[];
    diffperiode: number = 10;


    //sidebar vars start
    sideMenuShow: boolean = false;
    selectedtab: string = 'Sidebar';
    model: any[] = [];
    selectedStrategyFee: any;
    SelectRiderFee: any;
    selectedGroups: string[] = [];
    selectedSuryear: any[] = [];
    selectedAMBestrating: string[] = [];
    selectedSortBy: any = 'Withdrawal Amount';
    selectedSortBymodel: any = 'Withdrawal Amount';
    categories: any[] = [];
    product: ProductDetails[] = [];
    productlist: any;
    amBestRating: AMBestRating[] = [];
    riderFee: RiderFee[] = [];
    surrenderYear: SurrenderYear[] = [];
    strategyFee: StrategyFee[] = [];
    strategyFeeOptions: any[] = [];
    benifitBaseType: any[] = [];
    groups: Groups[] = [];
    confiMultiplier: ConfiMultiplier[] = [];
    stateData: StateData[] = [];
    varData: any[] = [];
    illustrationtblResponse: illustrationtblResponse[] = [];
    illustrationResData: any = {};
    ProductDetail: ProductDetail[] = [];
    ProductChildDetail: ProductChildDetail[] | undefined;
    //sidebar vars end

    //deshboard vars start
    checked: boolean = false;
    showmodel = false;
    useritems!: MenuItem[];
    items!: MenuItem[];
    itemslist!: MenuItem[];
    products!: Product[];
    griddata: GridData[] = [];
    allProducts: any[] = [];
    filterCustomself: any[] = [];
    customProductList: any[] = [];
    custoShelfShow: boolean = false;

    //Flags
    isLoader: boolean = false;
    isSubmit: boolean = false;
    isLoad: boolean = false;
    isCompare: boolean = false;
    isSwitch: boolean = false;
    comparedataItem: any;
    compEvent: any;
    compDataChk: boolean = true;
    totalMatches: any;
    IncomePeriod: any;

    // models for bind chips
    SelectFilter: any[] = [];
    SelectFilter_AMbest: any[] = [];
    SelectFilter_AMbest_name: any[] = [];
    SelectFilter4: any[] = [];
    SelectFilter6: any[] = [];

    //short by props
    compareData: any[] = [];
    totalCompare: number = 0;

    //models
    riderFeeData: any[] = [];
    filterdataobj_group: any[] = []
    filterdataobj_group_name: any[] = []
    filterdataobj_surrender_year: any[] = []
    filterdataobj_surrender_year_name: any[] = []
    filterdataobj_products: any[] = []
    filterdataobj_products_name: any[] = [];
    filterdataobj_riderfee: any[] = []
    filterdataobj_riderfee_name: any[] = []
    filterdataobj_strategyfee: any[] = []
    filterdataobj_strategyfee_name: any[] = []
    filterdataobj_short: any[] = []
    filterdataobj_confiMulti_name: any = null;
    filterdataobj_rider_type: string = '';
    filteredCustomshelfs: any[] = [];
    filtredCustomSelfObj: any[] = [];

    age: number = 55;
    ageSelectDate = new Date();
    ageSelectedDate: any;
    pouseage: number = 0;
    spageSelectDate: any = new Date();
    spageSelectedDate: any;
    isSelected: boolean = true;


    //flags
    groupfilterFlag: string = '';
    productFlag: string = '';
    productfilterFlag: string = '';
    amRatingFlag: string = '';
    amRatingfilterFlag: string = '';
    surrFlag: string = ''
    surrfilterFlag: string = '';
    riderFlag: string = ''
    strategyFlag: string = ''

    //Illustration page 
    illustration: boolean = false;
    paginateIllustration: boolean = true;
    showCompareDialog: boolean = false;
    coveredLife: any;
    purchasePay: number = 0;
    initialBenifit: number = 0;
    initial: string = '$';
    carrier: string = '';
    productIllu: string = '';
    riderIllu: string = '';
    illEffectDate = new Date();
    riderAge: any;
    riderFeeIllu: any = '';
    withdrawalPer: number = 0;
    otherVariable: string = '';
    stepups: string = '';
    rollupdata: any[] = [];
    illu_Img: string = '';
    rollUpIllu: any = '';
    rollUpTerm: any = '';
    premium: any = '';
    premiumBonus: any = '';
    benefitBonus: any;
    stateIssue: string = '';
    singleJoinIncome: string = '';
    purchasePayment: string = '';
    benefitBase: string = '';
    deferralPer: any = '';
    waitingPer: any;
    annualIncome: string = '';
    illuStepUps: string = '';
    lifeIncome: string = '';
    productProfile: boolean = false;
    saveSeavch: boolean = false;
    selectedItem: any = null;
    stateName: string = '';
    compareCardsData: any[] = [];
    uploadedFiles: any[] = [];
    urlobj: any[] = [];
    companyName: string = '';
    loogoo: any;
    altImg: string = '../../assets/layout/images/no-picture-available.jpg'

    // productProfileDetails: any[] = [];
    showEllipsis: boolean = true;
    showAccountDescription: boolean = false;
    showFullDescription: boolean = true;
    contributionIncome: string = '';
    monthlyAnnualypaymentType: boolean = true;
    dataView: string = 'grid';
    defaultsettingsvalues: any;
    menuOutsideClickListener: any;
    profileMenuOutsideClickListener: any;
    stateOptions: any[] = [];
    monthlyAnuallyopt: any[] = [];
    allOptionsSelectList: any[] = [];
    sortByDataOption: any = [];
    filteredSavedItem: number = 0;
    compareVariables: boolean = true;

    //////Save Search/////
    searchtitle: string = '';
    loginUserData: any;

    @ViewChild('p') paginator!: Paginator;
    dob: any;
    spouseDob: any;
    dataViewRows: number = 8;
    pageNumber: number = 1;
    pageSize: number = 25;
    withdrawalFrequency: string = 'Annually';
    currentProduct!: GridData;
    showProductDialog: boolean = false;
    urlSafe: any;
    ownerType: string = 'joint';
    userCustomShelfs: any[] = [];
    customShelfMasterData: any = [];
    showApprovedProductsOnly: boolean = false;
    userShelfProductIds: number[] = [];

    constructor(public layoutService: LayoutService, public renderer: Renderer2, public router: Router,
        private benefitcalculatorService: BenefitcalculatorService,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private messageService: MessageService,
        private scrollService: ScrollService,
        public sanitizer: DomSanitizer,
        private http: HttpClient

    ) {

        let loginSessionData = JSON.parse(sessionStorage.getItem('logInSession') || '');
        let logindata = this.benefitcalculatorService.decryptUsingAES256(loginSessionData);
        this.loginUserData = JSON.parse(logindata);

        this.userlogo = '';
        this.username = this.benefitcalculatorService.get('UserName')[0];

        this.benefitcalculatorService.getStateData().pipe(take(1), debounceTime(400), distinctUntilChanged()).subscribe((res: any) => {
            this.stateData = res;
        });

        // Dropdown Data
        this.genderDataList = [
            { name: 'Select', value: 'select' },
            { name: 'Male', value: 'M' },
            { name: 'Female', value: 'F' }
        ];
        this.sortByDataOption = [
            { name: 'Withdrawal Amount', value: sortByData.WithdrawalAmount },
            { name: 'Company Name', value: sortByData.CompanyName },
            { name: 'Product Name', value: sortByData.ProductName }
        ];
        this.stateOptions = [
            { label: 'Contribution', value: StateOption.Contribution },
            { label: 'Income', value: StateOption.Income },
        ];
        this.varData = [
            { varName: 'All', value: varData.All },
            { varName: 'Level', value: varData.Level },
            { varName: 'Increasing', value: varData.Increasing },
            { varName: 'Inflation Based', value: varData.InflationBased },
            { varName: 'Max Income With Reduced Income', value: varData.MaxIncome }
        ]
        this.monthlyAnuallyopt = [
            { label: 'Monthly', value: MonthAnuallyOption.Monthly },
            { label: 'Annually', value: MonthAnuallyOption.Annually },
        ]
        this.allOptionsSelectList = [
            { label: 'Select1', value: allOptions.select1 },
            { label: 'Select2', value: allOptions.select2 },
            { label: 'Select3', value: allOptions.select3 },
        ]
        this.strategyFeeOptions = [
            { strategyFee: 'Yes', value: 1 },
            { strategyFee: 'No', value: 0 },
        ]
        this.confiMultiplier = [
            {
                confiMulti: "yes"
            },
            {
                confiMulti: "no"
            }
        ];

    }

    ngOnInit(): void {
        // this.activateRouteeUser()
        this.savedtype = '';
        let uID = this.benefitcalculatorService.decryptUsingAES256(JSON.parse(sessionStorage.getItem('userId') || ''));
        this.setDataViewRows();
        // Getting Default Setting Data
        this.isLoader = true;
        this.benefitcalculatorService.getDefaultSetting(JSON.parse(uID)).subscribe((res) => {
            if (res) {
                this.dataView = res.dataView ? res.dataView : 'list';
                this.defaultsettingsvalues = res;
                this.contributionIncome = res.contribution === 0 ? 'contribution' : 'income';
                // this.monthlyAnnualypaymentType = res.paymentType
                for (let i = 0; i < this.stateData.length; i++) {
                    if (res.userState == this.stateData[i].stateCode) {
                        this.selectedStateName = this.stateData[i].stateName;
                    }
                }
                this.topbarForm.patchValue({
                    gender: res.gender,
                    age: res.age || 55,
                    pouseage: res.spouseAge || 55,
                    illinois: res.userState,
                    ridertype: res.riderType,
                    Contribution: this.contributionIncome,
                    amount: res.amount ? res.amount : 100000,
                    diffperiode: res.defPeriod,
                    // periode: this.monthlyAnnualypaymentType
                });

                this.ageSelectDate = new Date();
                this.ageSelectDate.setFullYear(this.ageSelectDate.getFullYear() - Number(res.age || 55));
                this.ageSelectedDate = this.datePipe.transform(this.ageSelectDate, "MM/dd/yyyy");
                this.spageSelectDate = new Date();
                this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - Number(res.spouseAge || 55));
                this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy")
            } else {
                this.contributionIncome = 'contribution';
                this.topbarForm.patchValue({
                    illinois: "ALL",
                    ridertype: "All",
                    Contribution: this.contributionIncome,
                    amount: 100000,
                    diffperiode: 10,
                    // periode: this.monthlyAnnualypaymentType
                });
            }
            let sidebarObject: any = (sessionStorage.getItem('sidebarObject'));

            if (!sidebarObject) {
                setTimeout(() => {
                    this.renderData();
                }, 1000);
            } else {
                let basicFilterItem = JSON.parse(sidebarObject);
                let basicFilterObj: any = (sessionStorage.getItem('basicFilterObj'))
                let basicFilterObjfnl = JSON.parse(basicFilterObj)
                this.withdrawalFrequency = basicFilterObjfnl.periode == 1 ? 'Annually' : 'Monthly';
                this.topbarForm.get('pouseage')?.patchValue(basicFilterObjfnl.spage)
                this.topbarForm.get('Contribution')?.patchValue(basicFilterObjfnl.amountType)
                this.topbarForm.get('amount')?.patchValue(basicFilterObjfnl.amount)
                this.topbarForm.get('periode')?.patchValue(basicFilterObjfnl.periode)

                if (basicFilterItem.ageBand < basicFilterObjfnl.spage) {
                    this.topbarForm.get('age')?.patchValue(basicFilterItem.ageBand);
                } else {
                    this.topbarForm.get('age')?.patchValue(basicFilterObjfnl.age);
                }

                if (this.ageSelectDate && res) {
                    this.topbarForm.get('age')?.patchValue(res.age);
                }
                if (this.spageSelectDate && res) {
                    this.topbarForm.get('pouseage')?.patchValue(res.spouseAge);
                }
                this.topbarForm.get('gender')?.patchValue(basicFilterItem.gender);
                this.topbarForm.get('illinois')?.patchValue(basicFilterItem.stateCode);
                this.topbarForm.get('ridertype')?.patchValue(basicFilterItem.benefitBaseType);
                this.topbarForm.get('diffperiode')?.patchValue(basicFilterItem.deferralYear);
                let obj: any = (sessionStorage.getItem('chipDataObject'))
                if (obj != null) {
                    let chipDataObject = JSON.parse(obj)
                    this.filterdataobj_group_name = chipDataObject?.filterdataobj_group_name;
                    this.filterdataobj_products_name = chipDataObject?.filterdataobj_products_name;
                    this.SelectFilter_AMbest_name = chipDataObject?.SelectFilter_AMbest_name;
                    this.filterdataobj_surrender_year_name = chipDataObject?.filterdataobj_surrender_year_name;
                    this.filterdataobj_riderfee_name = chipDataObject?.filterdataobj_riderfee_name;
                    this.filterdataobj_strategyfee_name = chipDataObject?.filterdataobj_strategyfee_name;
                    this.filteredSavedItem = this.filterdataobj_group_name?.length + this.SelectFilter_AMbest_name?.length + this.filterdataobj_surrender_year_name?.length + this.filterdataobj_products_name?.length + this.filterdataobj_strategyfee_name?.length + this.filterdataobj_riderfee_name?.length;
                }
                setTimeout(() => {
                    this.renderData(sidebarObject);
                }, 1000);
            }
        })
        this.getAllProducsAndCustomShelf();
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        // document.getElementById('layoutwrapper')?.addEventListener('click', ()=>{
        //     if(this.showProductDialog){
        //         this.showProductDialog = false;
        //     }
        // })
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setDataViewRows();
    }

    setDataViewRows() {
        if (window.innerWidth > 2100) {
            this.dataViewRows = 14;
        } else if (window.innerWidth > 1600 && window.innerWidth < 2101) {
            this.dataViewRows = 10;
        } else if (window.innerWidth > 1024 && window.innerWidth < 1601) {
            this.dataViewRows = 8;
        } else if (window.innerWidth > 600 && window.innerWidth <= 1024) {
            this.dataViewRows = 4;
        } else {
            this.dataViewRows = 1;
        }
        // const widthToRowsMap = {
        //     2100: 14,
        //     1600: 10,
        //     1024: 8,
        //     600: 4,
        //     0: 1,
        //   };

        //   const width = window.innerWidth;
        //   const entries = Object.entries(widthToRowsMap);

        //   let dataViewRows = 1;

        //   for (const [key, value] of entries) {
        //     const currentWidth = parseInt(key, 10);

        //     if (width > currentWidth) {
        //       dataViewRows = value;
        //       break;
        //     }
        //   }

        //   this.dataViewRows = dataViewRows;

    }

    renderData(sidebarObject?: any) {
        this.filteredArray = [];
        this.griddata = [];
        this.pageNumber = 1;
        if (sidebarObject) {
            this.getFilterSideBarInit(sidebarObject);
            this.requestValues = {
                ageBand: this.topbarForm.get('age')?.value || 55,
                deferralYear: this.topbarForm.get('diffperiode')?.value || 10,
                gender: this.topbarForm.get('gender')?.value || 'M',
                spageBand: this.topbarForm.get('pouseage')?.value || 55
            }
            this.ageSelectDate = new Date();
            this.ageSelectDate.setFullYear(this.ageSelectDate.getFullYear() - Number(this.requestValues.ageBand));
            this.ageSelectedDate = this.datePipe.transform(this.ageSelectDate, "MM/dd/yyyy")
            this.spageSelectDate = new Date();
            this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - Number(this.requestValues.spageBand));
            this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy");
            this.topbarForm.get('age')?.patchValue(this.requestValues.ageBand);
            this.topbarForm.get('pouseage')?.patchValue(this.requestValues.spageBand);
            this.agecheck = Number(this.topbarForm.get('age')?.value);
            this.spouseagecheck = Number(this.topbarForm.get('pouseage')?.value);
            this.amountcheck = Number(this.topbarForm.get('amount')?.value);
            this.diffperiodecheck = Number(this.topbarForm.get('diffperiode')?.value);
            // Card view menu button(illustration, product profile, comparison)
            this.items = [{
                label: 'Compare',
                icon: 'bi bi-arrow-left-right',
                command: () => {
                    this.onComparebtnchange(this.selectedItem, this.compEvent)
                }
            },
            {
                label: 'Illustration',
                icon: '"bi bi-file-richtext-fill',
                command: () => {
                    this.showIllustration(this.selectedItem);
                }
            },
            {
                label: 'Product Profile',
                icon: 'bi bi-files',
                command: () => {
                    if (this.topbarForm.value.illinois == null) {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Please Select State' });
                    } else if (this.selectedStateName == 'ALL') {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Select state other than ALL' });
                    } else {
                        this.onProductProfile(this.selectedItem);
                        this.pproductProfileData = [];

                        this.pproductProfileData.push(this.topbarForm.value.illinois, this.selectedItem.productid, this.topbarForm.value.pouseage);
                        this.showProductProfile(this.pproductProfileData);
                    }
                }
            }
            ];
            // List view menu button(illustration, product profile, comparison)
            this.itemslist = [{
                label: 'Compare',
                icon: 'bi bi-arrow-left-right',
                command: () => {
                    this.onComparebtnchange(this.selectedItem, this.compEvent)
                }
            },
            {
                label: 'Illustration',
                icon: '"bi bi-file-richtext-fill',
                command: () => {
                    this.showIllustration(this.selectedItem);
                }
            },
            {
                label: 'Product Profile',
                icon: 'bi bi-files',
                command: () => {
                    if (this.topbarForm.value.illinois == null) {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Please Select State' });
                    } else if (this.selectedStateName == 'ALL') {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Select state other than ALL' });
                    } else {
                        this.onProductProfile(this.selectedItem);
                        this.pproductProfileData = [];
                        this.pproductProfileData.push(this.topbarForm.value.illinois, this.selectedItem.productid, this.topbarForm.value.pouseage);
                        this.showProductProfile(this.pproductProfileData);
                    }
                }
            }
            ];

            //topbar menu button items
            this.useritems = [{
                label: 'Account Settings',
                icon: 'pi pi-user-edit',
                command: () => {
                    this.router.navigate(['/profilesetting']);
                }
            },
            {
                label: 'Default Settings',
                icon: 'pi pi-cog',
                command: () => {
                    this.router.navigate(['/adminsystem']);
                }
            },
            {
                label: 'My Saved Searches',
                icon: 'pi pi-save',
                command: () => {
                    this.router.navigate(['/savedsearch']);
                }
            },
            {
                label: 'My Custom Shelfs',
                icon: 'pi pi-book',
                command: () => {
                    this.router.navigate(['/customshelf']);
                }
            },
            {
                label: 'Logout',
                icon: 'pi pi-sign-out',
                command: () => {
                    this.onLogout();
                }
            }
            ];

            //dropdown data for sort-by
            this.categories = [
                {
                    name: "Withdrawal Amount",
                },
                {
                    name: "Company Name"
                },
                {
                    name: "Product Name"
                }
            ];

            if (this.categories.length > 0) {
                this.selectedSortBymodel = this.categories[0];
            }

            this.isLoader = true;
            this.filteredArray = [];
        } else {
            this.filterEdit = this.benefitcalculatorService.getsavedData();
            if (this.filterEdit.source._value.length == 2) {
                if (this.filterEdit.source._value[1] == "edit") {
                    this.savedtype = 'edit';
                }
                else if (this.filterEdit.source._value[1] == "view") {
                    this.savedtype = 'view';
                }
            }
            this.requestValues = {
                ageBand: this.topbarForm.get('age')?.value || 55,
                deferralYear: this.topbarForm.get('diffperiode')?.value || 10,
                gender: this.topbarForm.get('gender')?.value || 'M',
                spageBand: this.topbarForm.get('pouseage')?.value || 55
            }

            this.ageSelectDate = new Date();
            this.ageSelectDate.setFullYear(this.ageSelectDate.getFullYear() - Number(this.requestValues.ageBand));
            this.ageSelectedDate = this.datePipe.transform(this.ageSelectDate, "MM/dd/yyyy")
            this.spageSelectDate = new Date();
            this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - Number(this.requestValues.spageBand));
            this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy");
            this.topbarForm.get('age')?.patchValue(this.requestValues.ageBand);
            this.topbarForm.get('pouseage')?.patchValue(this.requestValues.spageBand);
            // savesearch Data Filter
            if (this.filterEdit.source._value.length === 0 || this.filterEdit.source._value.length === 1) {
                let uID = this.benefitcalculatorService.decryptUsingAES256(JSON.parse(sessionStorage.getItem('userId') || ''));
                this.getFilterSideBar();
            }
            else {
                let filterdatasaved = this.filterEdit.source._value[0].savedstr
                this.filterdataobj_group_name = filterdatasaved.filterdataobj_group_name
                this.filterdataobj_group = filterdatasaved.filterdataobj_group

                this.SelectFilter_AMbest_name = filterdatasaved.SelectFilter_AMbest_name
                this.SelectFilter_AMbest = filterdatasaved.SelectFilter_AMbest

                this.filterdataobj_products_name = filterdatasaved.filterdataobj_products_name
                this.filterdataobj_products = filterdatasaved.filterdataobj_products

                this.filterdataobj_surrender_year_name = filterdatasaved.filterdataobj_surrender_year_name
                this.filterdataobj_surrender_year = filterdatasaved.filterdataobj_surrender_year

                this.filterdataobj_strategyfee_name = filterdatasaved.filterdataobj_strategyfee_name
                this.filterdataobj_strategyfee = filterdatasaved.filterdataobj_strategyfee

                this.filterdataobj_riderfee_name = filterdatasaved.filterdataobj_riderfee_name
                this.filterdataobj_riderfee = filterdatasaved.filterdataobj_riderfee

                let chipDataObject = {
                    filterdataobj_group_name: this.filterdataobj_group_name,
                    filterdataobj_products_name: this.filterdataobj_products_name,
                    SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                    filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                    filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                    filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
                }
                sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
                if (this.filterdataobj_group_name.length != 0 || this.SelectFilter_AMbest_name.length != 0 || this.filterdataobj_surrender_year_name.length != 0 || this.filterdataobj_products_name.length != 0 || this.filterdataobj_strategyfee_name.length != 0 || this.filterdataobj_riderfee_name.length != 0) {
                    this.getFilterSideBar();
                }
                this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
            }
            this.agecheck = Number(this.topbarForm.get('age')?.value);
            this.spouseagecheck = Number(this.topbarForm.get('pouseage')?.value);
            this.amountcheck = Number(this.topbarForm.get('amount')?.value);
            this.diffperiodecheck = Number(this.topbarForm.get('diffperiode')?.value);
            // Card view menu button(illustration, product profile, comparison)
            this.items = [{
                label: 'Compare',
                icon: 'bi bi-arrow-left-right',
                command: () => {
                    this.onComparebtnchange(this.selectedItem, this.compEvent)
                }
            },
            {
                label: 'Illustration',
                icon: '"bi bi-file-richtext-fill',
                command: () => {
                    this.showIllustration(this.selectedItem);
                }
            },
            {
                label: 'Product Profile',
                icon: 'bi bi-files',
                command: () => {
                    if (this.topbarForm.value.illinois == null) {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Please Select State' });
                    } else if (this.selectedStateName == 'ALL') {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Select state other than ALL' });
                    } else {
                        this.onProductProfile(this.selectedItem);
                        this.pproductProfileData = [];

                        this.pproductProfileData.push(this.topbarForm.value.illinois, this.selectedItem.productid, this.topbarForm.value.pouseage);
                        this.showProductProfile(this.pproductProfileData);
                    }
                }
            }
            ];
            // List view menu button(illustration, product profile, comparison)
            this.itemslist = [{

                label: 'Compare',
                icon: 'bi bi-arrow-left-right',
                command: () => {
                    this.onComparebtnchange(this.selectedItem, this.compEvent)

                }
            },
            {
                label: 'Illustration',
                icon: '"bi bi-file-richtext-fill',
                command: () => {
                    this.showIllustration(this.selectedItem);
                }
            },
            {
                label: 'Product Profile',
                icon: 'bi bi-files',
                command: () => {

                    if (this.topbarForm.value.illinois == null) {
                        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Please Select State' });
                    } else {
                        this.onProductProfile(this.selectedItem);
                        this.pproductProfileData = [];
                        this.pproductProfileData.push(this.topbarForm.value.illinois, this.selectedItem.productid, this.topbarForm.value.pouseage);
                        this.showProductProfile(this.pproductProfileData);
                    }
                }
            }
            ];

            //topbar menu button items
            this.useritems = [{
                label: 'Account Settings',
                icon: 'pi pi-user-edit',
                command: () => {
                    this.router.navigate(['/profilesetting']);
                }
            },
            {
                label: 'Default Settings',
                icon: 'pi pi-cog',
                command: () => {
                    this.router.navigate(['/adminsystem']);
                }
            },
            {
                label: 'My Saved Searches',
                icon: 'pi pi-save',
                command: () => {
                    this.router.navigate(['/savedsearch']);
                }
            },
            {
                label: 'My Custom Shelfs',
                icon: 'pi pi-book',
                command: () => {
                    this.router.navigate(['/customshelf']);
                }
            },
            {
                label: 'Logout',
                icon: 'pi pi-sign-out',
                command: () => {
                    this.onLogout();
                }
            }
            ];

            //dropdown data for sort-by
            this.categories = [
                {
                    name: "Withdrawal Amount",
                },
                {
                    name: "Company Name"
                },
                {
                    name: "Product Name"
                }
            ];

            if (this.categories.length > 0) {
                this.selectedSortBymodel = this.categories[0];
            }

            this.isLoader = true;
            this.filteredArray = [];
        }
    }

    showIllustration(item: any) {
        // console.log('item', item.id, item.companyName , item.anualIncom);
        // window.open("https://staging.an.annuitynexus.com/index_annuity/side_by_side/basic_info?ch=,1110,1050,1447", "", "width=1315,height=1000");
        this.illustration = true;
        this.stepups = item.IncomePeriod
        let agedetail: number = 0;
        const spage: number = this.topbarForm.get('pouseage')?.value || 0;
        const age: number = this.topbarForm.get('age')?.value || 0;
        agedetail = spage > age ? age : spage;
        let data = {
            ageBand: agedetail || Number(age),
            deferralYear: this.topbarForm.get('diffperiode')?.value || 10,
            is_Joint_Single: this.topbarForm.get('pouseage')?.value != 0 ? false : true,
            id: item.id,
            contribution: this.topbarForm.get('amount')?.value || 100000,
        }
        this.isLoader = true;
        //get illustration page data
        this.benefitcalculatorService.getIllustrationData(data).subscribe((res: any) => {
            this.illustrationResData = res;
            this.illustrationResData.isShelfProduct = this.userShelfProductIds.indexOf(item.productid) > -1;
            if (res.illustrations.length > 0) {
                res.illustrations.map((x: any) => {
                    x.monthlyWithdrawal = x.annualWithdrawal > 0 ? (x.annualWithdrawal / 12) : 0;
                })
            }
            this.illustrationtblResponse = res.illustrations;
            this.carrier = res.companyName;
            this.productIllu = res.productName;
            this.riderIllu = res.riderName;
            this.riderAge = res.minAge + ' - ' + res.maxAge;
            this.riderFeeIllu = res.riderFee + '%' + ' ' + 'of' + ' ' + res.chargeBasisName;
            this.rollupdata = [];
            this.withdrawalPer = res.withdrawalPer;
            this.otherVariable = res.otherVariable;
            this.rollupdata = this.calculateRollUp(res);
            this.illu_Img = res.logo1 != null ? environment.domain + res.logo1 : this.altImg;
            this.rollUpIllu = res.rollupPerJoint || 0 + '%';
            this.rollUpTerm = item.maxRollupYears || 0 + ' years';
            this.lifeIncome = res.gmwbType;
            this.premiumBonus = res.premiumBonus == 0 ? 'None' : res.premiumBonus + '%';
            this.benefitBonus = res.bbBonus == 0 ? 'None' : res.bbBonus + '%';
            this.stateIssue = this.stateName || '-';
            this.singleJoinIncome = this.topbarForm.get('pouseage')?.value != 0 ? 'Joint' : 'Single';
            this.coveredLife = this.topbarForm.get('pouseage')?.value == 0 ? this.topbarForm.get('age')?.value : (this.topbarForm.get('age')?.value + '/' + this.topbarForm.get('pouseage')?.value);
            this.purchasePay = Number(this.topbarForm.get('amount')?.value);
            this.initialBenifit = ((data.contribution) + (((this.topbarForm.get('amount')?.value || 100000) * res.bbBonus) / 100) + (((this.topbarForm.get('amount')?.value || 100000) * res.premiumBonus) / 100));
            this.deferralPer = data.deferralYear + ' years';
            this.waitingPer = res.waitingPeriod;
            this.annualIncome = '$' + (item.anualIncom.toLocaleString('en')) + ' ' + (item.IncomePeriod);
            this.illuStepUps = res.highAnnivValStepup && res.highAnnivValStepup != '' ? res.highAnnivValStepup : 'None';
            if (this.illu_Img) {
                let myImage = '';
                // myImage = 'https://companylogoss.s3.amazonaws.com/CompanyLogo/105_logo1.jpg';
                if (this.illu_Img.includes('stage-benefitapi.an.annuitynexus.com')) {
                    myImage = this.illu_Img.replace(/\\/g, "/");
                    myImage = myImage.replace("stage-benefitapi.an.annuitynexus.com", "companylogoss.s3.amazonaws.com");
                } else {
                    if (res.logo1 != null) {
                        myImage = "companylogoss.s3.amazonaws.com" + res.logo1;
                        myImage = myImage.replace(/\\/g, "/");
                        myImage = 'https://' + myImage;
                    } else {
                        myImage = this.altImg;
                        myImage = myImage.replace(/\\/g, "/");;
                    }
                }
                // console.log('myImage', myImage);
                this.http.get(myImage, {
                    observe: 'response',
                    responseType: 'blob',
                })
                    .subscribe({
                        next: (res: any) => {
                            var reader = new FileReader();
                            reader.onload = (event) => {
                                this.illu_Img = event.target?.result as any;
                            };
                            reader.readAsDataURL(res.body!);
                        }
                    });
            }
            this.isLoader = false;
        });
    }

    hideIllustrationDialog() {
        this.paginateIllustration = true;
    }

    reditectToProduct(productId: number) {
        this.productId = productId;
        this.stateCode = this.topbarForm.get('illinois')?.value || 'ALL';
        this.setProductProfile();
    }

    setOwnerType(ownerType: string) {
        if (this.ownerType != ownerType) {
            if (ownerType != 'joint') {
                this.spageSelectDate = new Date();
                this.topbarForm.get('pouseage')?.patchValue(0);
                this.requestValues.pouseage = 0;
                this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - 0);
                this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy")
                this.spouseagecheck = 0;
                this.pouseage = 0;
            } else {
                this.spageSelectDate = new Date(this.ageSelectedDate);
                this.topbarForm.get('pouseage')?.patchValue(Number(this.age));
                this.requestValues.pouseage = Number(this.age);
                this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear());
                this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy")
                this.spouseagecheck = this.age;
                this.pouseage = this.age;
            }
            this.ownerType = ownerType;
            // this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }

    setProductProfile() {
        this.isLoader = true;
        this.tiker = [];
        this.index = [];
        const apiDomain = environment.domain;
        if (this.stateCode && this.stateCode != 'ALL' && this.stateCode != '') {
            localStorage.setItem('selectedStateCode', this.stateCode);
        }
        if (apiDomain == 'https://stage-benefitapi.an.annuitynexus.com') {
            this.router.navigate([`/product-details/indexed/${this.productId}`]);
            //this.router.navigate([`/products/view-pdf-profile/${this.productId}`]);
        } else {
            this.showCompareDialog = false;
            this.benefitcalculatorService.getProductProfile(this.productId, this.stateCode).subscribe((res: any) => {
                this.productProfileDetails = res;
                this.productProfile = true;
                this.companyLogo1 = res.logo1 != null ? environment.domain + res.logo1 : this.altImg;
                this.companyLogo2 = res.logo2 != null ? environment.domain + res.logo2 : this.altImg;
                switch (res.typeid) {
                    case 1: {
                        this.premiumType = 'Flexible';
                        break;
                    }
                    case 2: {
                        this.premiumType = 'Single';
                        break;
                    }
                    case 3: {
                        this.premiumType = 'Single With Additional';
                        break;
                    }
                }
                this.preBonuse = res.bonusesName != null ? 'Yes' : 'No';
                this.vestingBonuse = res.bonusAppliedType != null ? 'Yes' : 'No';
                this.credStrategies = res.mktgname.split(',');
                this.credStrategies = [...new Set(this.credStrategies.map((item) => item))];
                this.indexesAvailable = res.indexused.split(',');

                for (let i = 0; i < this.indexesAvailable.length; i++) {
                    this.indexes = this.indexesAvailable[i].split('@');
                    this.index.push(this.indexes[0]);
                    this.tiker.push(this.indexes[1]);
                }
                this.riderFee = this.pspouseAge != 0 ? res.riderFeeJoint : res.riderFee;
                this.strategyFee = res.strategyFee;
                this.properity = (res.restricted = 'true') ? 'Yes' : 'No';
                this.isLoader = false;
            });
        }
    }

    showProductProfile(item: any) {
        this.stateName = item[0].stateName;
        this.pspouseAge = item[2];
        this.productId = item[1];
        this.stateCode = item[0];
        this.setProductProfile();
    }
    get containerClass() {
        return {
            'layout-theme-light': this.layoutService.config.colorScheme === 'light',
            'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
            'layout-overlay': this.layoutService.config.menuMode === 'overlay',
            'layout-static': this.layoutService.config.menuMode === 'static',
            'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
            'layout-overlay-active': this.layoutService.state.overlayMenuActive,
            'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
            'p-input-filled': this.layoutService.config.inputStyle === 'filled',
            'p-ripple-disabled': !this.layoutService.config.ripple
        }
    }
    selectedTrue() {
        this.isSelected = true;
    }
    onSubmit(value: any) {
    }
    genderValue(e: any) {
        this.topbarForm.get('gender')?.patchValue(e.value);
        this.requestValues.gender = e.value;
        // this.getFilterSideBar();
        this.renderData(sessionStorage.getItem('sidebarObject'));
    }
    onSelectAge(event: any) {
        if (event) {
            var timeDiff = Math.abs(Date.now() - event.getTime());
            this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
            this.requestValues.ageBand = this.age;
        }
        // this.getFilterSideBar();
        this.renderData(sessionStorage.getItem('sidebarObject'));
        this.paginator.first = 1;
    }
    onCloseAge(event: any) {
        const timeDiff = Math.abs(Date.now() - (new Date(this.ageSelectedDate).getTime()));
        const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
        if (!isNaN(age) && this.ageSelectedDate != this.dob) {
            this.dob = this.ageSelectedDate;
            this.requestValues.ageBand = age;
            this.topbarForm.get('age')?.patchValue(age);
            // this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }
    onEnterAge(e: any) {
        if (Number(this.topbarForm.get('age')?.value) != this.agecheck) {
            this.ageSelectDate = new Date();
            this.topbarForm.get('age')?.patchValue(e.target.value == '' || !e.target.value ? 0 : Number(e.target.value));
            this.requestValues.ageBand = e.target.value == '' || !e.target.value ? 0 : Number(e.target.value);
            this.ageSelectDate.setFullYear(this.ageSelectDate.getFullYear() - (e.target.value == '' || !e.target.value ? 0 : Number(e.target.value)));
            this.ageSelectedDate = this.datePipe.transform(this.ageSelectDate, "MM/dd/yyyy")
            this.agecheck = e.target.value == '' || !e.target.value ? 0 : Number(e.target.value);
            //this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }
    onSelectSpouseAge(event: any) {
        if (event) {
            const timeDiff = Math.abs(Date.now() - event.getTime());
            this.pouseage = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
            this.requestValues.pouseage = this.pouseage;
        }
        //this.getFilterSideBar();
        this.renderData(sessionStorage.getItem('sidebarObject'));
        this.paginator.first = 1;
    }
    onSpouseAgeClose(e: any) {
        const timeDiff = Math.abs(Date.now() - (new Date(this.spageSelectedDate).getTime()));
        const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
        if (!isNaN(age) && this.spageSelectedDate != this.spouseDob) {
            this.spouseDob = this.spageSelectedDate;
            this.requestValues.pouseage = age;
            this.topbarForm.get('pouseage')?.patchValue(age);
            //this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }
    onSpouseAgeSelect(e: any) {
        if (Number(this.topbarForm.get('pouseage')?.value) != this.spouseagecheck) {
            this.spageSelectDate = new Date();
            this.topbarForm.get('pouseage')?.patchValue(e.target.value);
            this.requestValues.pouseage = e.target.value;
            this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - Number(e.target.value));
            this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy")
            this.spouseagecheck = Number(e.target.value);
            //this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }
    onSpouseAgeEnter(e: any) {
        this.spageSelectDate = new Date();
        if (Number(this.topbarForm.get('pouseage')?.value) != this.spouseagecheck) {
            this.topbarForm.get('pouseage')?.patchValue(e.target.value == '' || !e.target.value ? 0 : Number(e.target.value));
            this.requestValues.pouseage = e.target.value == '' || !e.target.value ? 0 : Number(e.target.value);
            this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - (e.target.value == '' || !e.target.value ? 0 : Number(e.target.value)));
            this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy")
            this.spouseagecheck = (e.target.value == '' || !e.target.value ? 0 : Number(e.target.value));
            this.pouseage = e.target.value == '' || !e.target.value ? 0 : e.target.value;
            // this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }

    onStateChange(e: any) {
        sessionStorage.setItem('stateName', e.originalEvent.target.innerText)
        this.selectedStateName = e.originalEvent.target.innerText;
        this.amRatingFlag = "";
        this.productfilterFlag = "";
        this.surrFlag = "";
        this.strategyFlag = "";
        this.riderFlag = "";
        this.groupfilterFlag = "";
        this.stateCodeFilter = e.value;
        //this.getFilterSideBar();
        this.renderData(sessionStorage.getItem('sidebarObject'));
        this.paginator.first = 1;
        if (this.filteredArray.length > 8) {
            this.ispagination = true
        } else {
            this.ispagination = false
        }
    }
    onStateClear(e: any) {
        this.filteredArray = this.griddata;
        // this.totalMatches = this.filteredArray.length;
        if (this.filteredArray.length > 8) {
            this.ispagination = true
        } else {
            this.ispagination = false
        }
    }
    onVarChange(e: any) {
        this.amRatingFlag = "";
        this.productfilterFlag = "";
        this.surrFlag = "";
        this.strategyFlag = "";
        this.riderFlag = "";
        this.groupfilterFlag = "";
        this.benifitBaseType = [];
        this.filterdataobj_rider_type = '';
        this.filterdataobj_rider_type = e.value;
        //this.getFilterSideBar()
        this.renderData(sessionStorage.getItem('sidebarObject'));
        this.paginator.first = 1;
    }

    calculateRollUp(data: any) {
        let rollUpdata = [];
        if (this.topbarForm.get('pouseage')?.value != 0) {
            if (data.rollupPerJoint && data.rollupPerJoint.trim() != "") {
                const rollUpPerArray = data.rollupPerJoint.split(',');
                const rollupYearsArray = data.rollupYears ? data.rollupYears.split(',') : data.maxRollupYears.split(',');
                const rollupIsSimpleCompArray = data.rollupIsSimpleComp.split(',');
                for (let index = 0; index < rollUpPerArray.length; index++) {
                    let rollUpText: any = rollUpPerArray[index] + '% ' + rollupIsSimpleCompArray[index] + ' / Years ' + rollupYearsArray[index];
                    rollUpdata.push(rollUpText);
                }
            }
        } else {
            if (data.rollupPer && data.rollupPer.trim() != "") {
                const rollUpPerArray = data.rollupPer.split(',');
                const rollupYearsArray = data.rollupYears ? data.rollupYears.split(',') : data.maxRollupYears.split(',');
                const rollupIsSimpleCompArray = data.rollupIsSimpleComp.split(',');
                for (let index = 0; index < rollUpPerArray.length; index++) {
                    let rollUpText: any = rollUpPerArray[index] + '% ' + rollupIsSimpleCompArray[index] + ' / Years ' + rollupYearsArray[index];
                    rollUpdata.push(rollUpText);
                }
            }
        }
        return rollUpdata;
    }

    contIncome(e: any) {
        this.griddata = []
        this.compareVariables = true;
        const filteredArray = JSON.parse(JSON.stringify(this.filteredArray));
        this.filteredArray = [];
        filteredArray.map((data: any) => {
            let rollUpdata = this.calculateRollUp(data);
            //   console.log('rollup percentage', data.rollupPer, data.rollupPerJoint, data.rollupYears, data.maxRollupYears, data.rollupIsSimpleComp);
            this.griddata.push(
                {
                    logo1: data.logo1 != null ? '' + data.logo1 : this.altImg,
                    logo2: data.logo2 != null ? '' + data.logo2 : this.altImg,
                    gmwbId: data.gmwbId,
                    productid: data.productid,
                    productName: data.productName,
                    companyid: data.companyid,
                    companyName: data.companyName,
                    riderName: data.riderName,
                    minAge: data.minAge,
                    maxAge: data.maxAge,
                    riderFee: data.riderFee,
                    rollupPer: data.rollupPer,
                    withdrawalPer: data.withdrawalPer,
                    riderFeeJoint: data.riderFeeJoint,
                    rollupPerJoint: data.rollupPerJoint,
                    withdrawalPerJoint: data.withdrawalPerJoint,
                    maxRollupYears: data.rollupYears,
                    rollupYears: data.rollupYears,
                    isCaseAOrC: data.isCaseAOrC,
                    ageBand1: data.ageBand1,
                    ageBand2: data.ageBand2,
                    purchasePayBonus: data.purchasePayBonus,
                    internalName: data.internalName,
                    bonusPercentage: data.bonusPercentage,
                    bonusName: data.bonusName,
                    factorSingle: data.factorSingle,
                    factorJoint: data.factorJoint,
                    premiumBonus: data.premiumBonus,
                    id: data.id,
                    otherVariable: data.otherVariable,
                    bbBonus: data.bbBonus,
                    groupId: data.groupId,
                    groupName: data.groupName,
                    bestid: data.bestid,
                    surryr: data.surryr,
                    strategyFee: data.strategyFee,
                    gender: data.gender,
                    bonusCaseId: data.bonusCaseId,
                    bonusCaseName: data.bonusCaseName,
                    availStateException: data.availStateException ? data.availStateException : "",
                    exceptions: data.exceptions,
                    rollupIsSimpleComp: data.rollupIsSimpleComp,
                    riderFeedata: data.riderFee, // (this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.riderFeeJoint : data.riderFee) ,
                    chargeBasisName: data.chargeBasisName,
                    highAnnivValStepup: data.highAnnivValStepup,
                    anualIncom: this.getanuuleIncome(data),
                    IncomePeriod: this.topbarForm.get('Contribution')?.value == 'contribution' ? (this.topbarForm.get('periode')?.value ? 'Estimated Annually' : 'Estimated Monthly') : 'Estimated Contribution',
                    rollUp: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.rollupPerJoint : data.rollupPer,
                    withdrawal: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer,
                    gmwbAvailStateExceptions: data.gmwbAvailStateExceptions,
                    isChecked: false,
                    benifitBaseType: data.benifitBaseType,
                    isCollapsed: false,
                    waitingTerm: data.waitingTerm,
                    waitingPeriod: data.waitingPeriod,
                    rollUpdata: rollUpdata,
                    marketingName: data.marketingName,
                    chargeFreqDeductedWhen: data.chargeFreqDeductedWhen,
                    isShelfProduct: this.userShelfProductIds.indexOf(data.productid) > -1
                }
            )
            this.filteredArray.push({
                logo1: data.logo1 != null ? '' + data.logo1 : this.altImg,
                logo2: data.logo2 != null ? '' + data.logo2 : this.altImg,
                gmwbId: data.gmwbId,
                productid: data.productid,
                productName: data.productName,
                companyid: data.companyid,
                companyName: data.companyName,
                riderName: data.riderName,
                minAge: data.minAge,
                maxAge: data.maxAge,
                riderFee: data.riderFee,
                rollupPer: data.rollupPer,
                withdrawalPer: data.withdrawalPer,
                riderFeeJoint: data.riderFeeJoint,
                rollupPerJoint: data.rollupPerJoint,
                withdrawalPerJoint: data.withdrawalPerJoint,
                maxRollupYears: data.rollupYears,
                rollupYears: data.rollupYears,
                isCaseAOrC: data.isCaseAOrC,
                ageBand1: data.ageBand1,
                ageBand2: data.ageBand2,
                purchasePayBonus: data.purchasePayBonus,
                internalName: data.internalName,
                bonusPercentage: data.bonusPercentage,
                bonusName: data.bonusName,
                factorSingle: data.factorSingle,
                factorJoint: data.factorJoint,
                premiumBonus: data.premiumBonus,
                id: data.id,
                otherVariable: data.otherVariable,
                bbBonus: data.bbBonus,
                groupId: data.groupId,
                groupName: data.groupName,
                bestid: data.bestid,
                surryr: data.surryr,
                strategyFee: data.strategyFee,
                gender: data.gender,
                bonusCaseId: data.bonusCaseId,
                bonusCaseName: data.bonusCaseName,
                availStateException: data.availStateException ? data.availStateException : "",
                exceptions: data.exceptions,
                rollupIsSimpleComp: data.rollupIsSimpleComp,
                riderFeedata: data.riderFee, //this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.riderFeeJoint : data.riderFee,
                chargeBasisName: data.chargeBasisName,
                highAnnivValStepup: data.highAnnivValStepup,
                anualIncom: this.getanuuleIncome(data),
                IncomePeriod: this.topbarForm.get('Contribution')?.value == 'contribution' ? (this.topbarForm.get('periode')?.value ? 'Estimated Annually' : 'Estimated Monthly') : 'Estimated Contribution', //(this.topbarForm.get('periode')?.value ? 'Annually' : 'Monthly'),
                rollUp: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.rollupPerJoint : data.rollupPer,
                withdrawal: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer,
                gmwbAvailStateExceptions: data.gmwbAvailStateExceptions,
                isChecked: false,
                rollUpdata: rollUpdata,
                marketingName: data.marketingName,
                benifitBaseType: data.benifitBaseType,
                isCollapsed: false,
                isShelfProduct: this.userShelfProductIds.indexOf(data.productid) > -1
            })
            this.paginator.first = 1;
        })

        let basicFilterObj: any = {
            spage: this.topbarForm.get('pouseage')?.value,
            amountType: this.topbarForm.get('Contribution')?.value,
            amount: this.topbarForm.get('amount')?.value,
            periode: this.topbarForm.get('periode')?.value,
        }
        sessionStorage.setItem("basicFilterObj", JSON.stringify(basicFilterObj));
    }
    onAmountSelect(e: any) {
        if (e && e.target.value && Number(this.topbarForm.get('amount')?.value) != this.amountcheck) {
            this.topbarForm.get('amount')?.patchValue(e.target.value.replaceAll(',', ''));
            this.amountcheck = Number(this.topbarForm.get('amount')?.value);
            // this.getFilterSideBar()
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }

    periodChange(event: any) {
        this.withdrawalFrequency = event.option.label;
        this.griddata = []
        this.compareVariables = true;
        const filteredArray = JSON.parse(JSON.stringify(this.filteredArray));
        this.filteredArray = [];
        filteredArray.map((data: any) => {
            let rollUpdata = this.calculateRollUp(data);
            this.griddata.push(
                {
                    logo1: data.logo1 != null ? '' + data.logo1 : this.altImg,
                    logo2: data.logo2 != null ? '' + data.logo2 : this.altImg,
                    gmwbId: data.gmwbId,
                    productid: data.productid,
                    productName: data.productName,
                    companyid: data.companyid,
                    companyName: data.companyName,
                    riderName: data.riderName,
                    minAge: data.minAge,
                    maxAge: data.maxAge,
                    riderFee: data.riderFee,
                    rollupPer: data.rollupPer,
                    withdrawalPer: data.withdrawalPer,
                    riderFeeJoint: data.riderFeeJoint,
                    rollupPerJoint: data.rollupPerJoint,
                    withdrawalPerJoint: data.withdrawalPerJoint,
                    maxRollupYears: data.rollupYears,
                    rollupYears: data.rollupYears,
                    isCaseAOrC: data.isCaseAOrC,
                    ageBand1: data.ageBand1,
                    ageBand2: data.ageBand2,
                    purchasePayBonus: data.purchasePayBonus,
                    internalName: data.internalName,
                    bonusPercentage: data.bonusPercentage,
                    bonusName: data.bonusName,
                    factorSingle: data.factorSingle,
                    factorJoint: data.factorJoint,
                    premiumBonus: data.premiumBonus,
                    id: data.id,
                    otherVariable: data.otherVariable,
                    bbBonus: data.bbBonus,
                    groupId: data.groupId,
                    groupName: data.groupName,
                    bestid: data.bestid,
                    surryr: data.surryr,
                    strategyFee: data.strategyFee,
                    gender: data.gender,
                    bonusCaseId: data.bonusCaseId,
                    bonusCaseName: data.bonusCaseName,
                    availStateException: data.availStateException ? data.availStateException : "",
                    exceptions: data.exceptions,
                    rollupIsSimpleComp: data.rollupIsSimpleComp,
                    riderFeedata: data.riderFee, //this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.riderFeeJoint : data.riderFee,
                    chargeBasisName: data.chargeBasisName,
                    highAnnivValStepup: data.highAnnivValStepup,
                    anualIncom: this.getanuuleIncome(data),
                    IncomePeriod: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('Contribution')?.value == 'contribution' ? (this.topbarForm.get('periode')?.value ? 'Estimated Annually' : 'Estimated Monthly') : 'Estimated Contribution', //(this.topbarForm.get('periode')?.value ? 'Annually' : 'Monthly'),
                    rollUp: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.rollupPerJoint : data.rollupPer,
                    withdrawal: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer,
                    gmwbAvailStateExceptions: data.gmwbAvailStateExceptions,
                    isChecked: false,
                    benifitBaseType: data.benifitBaseType,
                    isCollapsed: false,
                    waitingTerm: data.waitingTerm,
                    waitingPeriod: data.waitingPeriod,
                    rollUpdata: rollUpdata,
                    marketingName: data.marketingName,
                    chargeFreqDeductedWhen: data.chargeFreqDeductedWhen,
                    isShelfProduct: this.userShelfProductIds.indexOf(data.productid) > -1
                }
            )
            this.filteredArray.push(
                {
                    logo1: data.logo1 != null ? '' + data.logo1 : this.altImg,
                    logo2: data.logo2 != null ? '' + data.logo2 : this.altImg,
                    gmwbId: data.gmwbId,
                    productid: data.productid,
                    productName: data.productName,
                    companyid: data.companyid,
                    companyName: data.companyName,
                    riderName: data.riderName,
                    minAge: data.minAge,
                    maxAge: data.maxAge,
                    riderFee: data.riderFee,
                    rollupPer: data.rollupPer,
                    withdrawalPer: data.withdrawalPer,
                    riderFeeJoint: data.riderFeeJoint,
                    rollupPerJoint: data.rollupPerJoint,
                    withdrawalPerJoint: data.withdrawalPerJoint,
                    maxRollupYears: data.rollupYears,
                    rollupYears: data.rollupYears,
                    isCaseAOrC: data.isCaseAOrC,
                    ageBand1: data.ageBand1,
                    ageBand2: data.ageBand2,
                    purchasePayBonus: data.purchasePayBonus,
                    internalName: data.internalName,
                    bonusPercentage: data.bonusPercentage,
                    bonusName: data.bonusName,
                    factorSingle: data.factorSingle,
                    factorJoint: data.factorJoint,
                    premiumBonus: data.premiumBonus,
                    id: data.id,
                    otherVariable: data.otherVariable,
                    bbBonus: data.bbBonus,
                    groupId: data.groupId,
                    groupName: data.groupName,
                    bestid: data.bestid,
                    surryr: data.surryr,
                    strategyFee: data.strategyFee,
                    gender: data.gender,
                    bonusCaseId: data.bonusCaseId,
                    bonusCaseName: data.bonusCaseName,
                    availStateException: data.availStateException ? data.availStateException : "",
                    exceptions: data.exceptions,
                    rollupIsSimpleComp: data.rollupIsSimpleComp,
                    riderFeedata: data.riderFee, //this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.riderFeeJoint : data.riderFee,
                    chargeBasisName: data.chargeBasisName,
                    highAnnivValStepup: data.highAnnivValStepup,
                    anualIncom: this.getanuuleIncome(data),
                    IncomePeriod: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('Contribution')?.value == 'contribution' ? (this.topbarForm.get('periode')?.value ? 'Estimated Annually' : 'Estimated Monthly') : 'Estimated Contribution',//(this.topbarForm.get('periode')?.value ? 'Annually' : 'Monthly'),
                    rollUp: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.rollupPerJoint : data.rollupPer,
                    withdrawal: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer,
                    gmwbAvailStateExceptions: data.gmwbAvailStateExceptions,
                    isChecked: false,
                    rollUpdata: rollUpdata,
                    marketingName: data.marketingName,
                    benifitBaseType: data.benifitBaseType,
                    isCollapsed: false,
                    isShelfProduct: this.userShelfProductIds.indexOf(data.productid) > -1

                }
            )
            this.paginator.first = 1;
        })
        let basicFilterObj: any = {
            spage: this.topbarForm.get('pouseage')?.value,
            amountType: this.topbarForm.get('Contribution')?.value,
            amount: this.topbarForm.get('amount')?.value,
            periode: this.topbarForm.get('periode')?.value,
        }
        sessionStorage.setItem("basicFilterObj", JSON.stringify(basicFilterObj));
    }
    updateDeffChange(e: any) {
        if (e && e.target.value && Number(this.topbarForm.get('diffperiode')?.value) != this.diffperiodecheck) {
            this.topbarForm.get('diffperiode')?.patchValue(e.target.value);
            this.requestValues.deferralYear = e.target.value;
            this.diffperiodecheck = e.target.value;
            // this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }
    updateDeffSliderChange(e: any) {
        if (Number(this.topbarForm.get('diffperiode')?.value) != this.diffperiodecheck) {
            this.topbarForm.get('diffperiode')?.patchValue(e.value);
            this.requestValues.deferralYear = e.value;
            this.diffperiodecheck = e.value;
            // this.getFilterSideBar();
            this.renderData(sessionStorage.getItem('sidebarObject'));
            this.paginator.first = 1;
        }
    }
    ngOnDestroy() {
    }
    onHideShow() {
        this.showmodel = true;
    }
    ontabSelect(status: string) {
        this.selectedtab = status;
    }
    onbackClick() {
        this.selectedtab = 'Sidebar';
    }
    shortbyFilters(val: any) {
        this.selectedSortBy = val.value;
        this.selectedSortBymodel = val.value;
        this.filterdataobj_short.push(val.value);

        if (this.selectedSortBy === 'Withdrawal Amount') {
            this.griddata = this.griddata.sort((a: any, b: any) => (a.anualIncom > b.anualIncom) ? -1 : 1);
            this.paginator.first = 1;
        }
        else if (this.selectedSortBy === 'Company Name') {
            this.griddata = this.griddata.sort((a: any, b: any) => (a.companyName > b.companyName) ? 1 : -1);
            this.paginator.first = 1;
        }
        else if (this.selectedSortBy === 'Product Name') {
            this.griddata = this.griddata.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : -1);
            this.paginator.first = 1;
        }
        else {
            this.filteredArray = [...this.griddata];

            if (this.filteredArray.length > 8) {
                this.ispagination = true
            } else {
                this.ispagination = false
            }
        }
        this.filteredArray = [...this.griddata];

        if (this.filteredArray.length > 8) {
            this.ispagination = true
        } else {
            this.ispagination = false
        }
    }
    onGroupSelect(event: any) {
        this.filterdataobj_group = [];
        this.filterdataobj_group_name = [];
        this.amRatingFlag = ""
        this.productfilterFlag = ""
        this.surrFlag = ""
        this.strategyFlag = ""
        this.riderFlag = ""
        this.groupfilterFlag = "Groups"

        if (event && event.value.length > 0) {
            for (let i = 0; i < event.value.length; i++) {
                if (event.value[i].groupId) {
                    this.filterdataobj_group.push(event.value[i].groupId);
                    this.filterdataobj_group_name.push({ groupName: event.value[i].groupName, groupId: event.value[i].groupId })
                }
            }
            const selectedGroups = JSON.parse(JSON.stringify(this.filterdataobj_group_name));
            this.getFilterSideBar();
            this.paginator.first = 1;
            this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
        }
        else {
            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.getFilterSideBar();
            this.paginator.first = 1;
            this.filteredSavedItem = this.filteredSavedItem - 1;
        }

    }
    onProductSelect(event: any) {
        if (event.node.children === undefined) {
            let { node } = event;
            this.filterdataobj_products.push(node.key);
            this.filterdataobj_products_name.push({
                key: node.key,
                label: node.label,
                data: node.data
            });
            this.productfilterFlag = "Product";
            this.groupfilterFlag = "";
            this.amRatingFlag = "";
            this.surrFlag = ""
            this.strategyFlag = ""
            this.riderFlag = ""

            this.getFilterSideBar();
            this.paginator.first = 1;
            this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
        }
        else {
            const { node: { children } } = event;
            if (Array.isArray(children) && children.length) {
                for (let val of children) {
                    this.filterdataobj_products.filter((el: any, index: any) => {
                        if (el === val.key) {
                            this.filterdataobj_products.splice(index, 1);
                        }
                    })
                    this.filterdataobj_products.push(val.key)

                    this.filterdataobj_products_name.filter((el: any, index: any) => {
                        if (el.key === val.key) {
                            this.filterdataobj_products_name.splice(index, 1);
                        }
                    })
                    this.filterdataobj_products_name.push({
                        key: val.key,
                        label: val.label,
                        data: val.data
                    });
                }
            }
            this.productfilterFlag = "Product";
            this.groupfilterFlag = "";
            this.amRatingFlag = "";
            this.surrFlag = ""
            this.strategyFlag = ""
            this.riderFlag = ""
            this.getFilterSideBar();
            this.paginator.first = 1;
            this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));

        }
    };
    onProductRemove(event: any) {
        if (event.node.children === undefined) {
            const { node } = event;
            let element: any[] = [];
            if (node) element = this.filterdataobj_products.filter((val: any) => {
                return node.key === val
            })
            element.filter((res: any) => {
                this.filterdataobj_products.filter((data: any, index: any) => {
                    if (res === data) {
                        this.filterdataobj_products.splice(index, 1);

                    }
                })
            })
            element.filter((res: any) => {
                this.filterdataobj_products_name.filter((data: any, index: any) => {
                    if (res === data.key) {
                        this.filterdataobj_products_name.splice(index, 1)

                    }
                })
            })

            this.productfilterFlag = "Product";
            this.groupfilterFlag = "";
            this.amRatingFlag = "";
            this.surrFlag = ""
            this.strategyFlag = ""
            this.riderFlag = ""
            this.getFilterSideBar();
            this.paginator.first = 1;

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));

            if (this.filterdataobj_products_name.length == 0) {
            }
            this.filteredSavedItem = this.filteredSavedItem - 1;

        }
        else {

            const { node: { children } } = event;
            let element: any[] = [];
            if (children) element = this.filterdataobj_products.filter((val: any) => {
                return children.some((el: any) => el.key === val)
            })

            element.filter((res: any) => {
                this.filterdataobj_products.filter((data: any, index: any) => {
                    if (res === data) {
                        this.filterdataobj_products.splice(index, 1);
                    }
                })
            })
            element.filter((res: any) => {
                this.filterdataobj_products_name.filter((data: any, index: any) => {
                    if (res === data.key) {
                        this.filterdataobj_products_name.splice(index, 1)
                    }
                })
            })
            this.productfilterFlag = "Product";
            this.groupfilterFlag = "";
            this.amRatingFlag = "";
            this.surrFlag = ""
            this.strategyFlag = ""
            this.riderFlag = ""
            this.getFilterSideBar();
            this.paginator.first = 1;

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));

            if (this.filterdataobj_products_name.length == 0) {
            }
            if (this.filteredSavedItem >= 2) {
                this.filteredSavedItem = 0
            } else {
                this.filteredSavedItem = this.filteredSavedItem - 1;
            }
        }
    }
    onAMReatingSelect(event: any) {
        this.SelectFilter_AMbest = [];
        this.SelectFilter_AMbest_name = [];
        this.productfilterFlag = ""
        this.groupfilterFlag = ""
        this.surrFlag = ""
        this.strategyFlag = ""
        this.riderFlag = ""
        this.amRatingFlag = "amBests";

        if (event && event.value.length > 0) {

            for (let i = 0; i < event.value.length; i++) {

                if (event.value[i].bestid) {
                    this.SelectFilter_AMbest.push(event.value[i].bestid);
                    this.SelectFilter_AMbest_name.push({ bestid: event.value[i].bestid })

                }

                let chipDataObject = {
                    filterdataobj_group_name: this.filterdataobj_group_name,
                    filterdataobj_products_name: this.filterdataobj_products_name,
                    SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                    filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                    filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                    filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
                }
                sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            }
            this.getFilterSideBar();
            this.paginator.first = 1;
            this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;

        } else {

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.getFilterSideBar()
            this.paginator.first = 1;
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
    }
    onSurYearSelect(event: any) {
        this.filterdataobj_surrender_year = [];
        this.filterdataobj_surrender_year_name = [];
        this.amRatingFlag = ""
        this.productfilterFlag = ""
        this.groupfilterFlag = ""
        this.surrfilterFlag = "";
        this.strategyFlag = ""
        this.riderFlag = ""
        this.surrFlag = "surrYrs"

        if (event && event.value.length > 0) {

            for (let i = 0; i < event.value.length; i++) {

                if (event.value[i].surryr) {
                    this.filterdataobj_surrender_year.push(event.value[i].surryr);
                    this.filterdataobj_surrender_year_name.push({ surryr: event.value[i].surryr })
                }

                let chipDataObject = {
                    filterdataobj_group_name: this.filterdataobj_group_name,
                    filterdataobj_products_name: this.filterdataobj_products_name,
                    SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                    filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                    filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                    filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
                }
                sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            }
            this.getFilterSideBar();
            this.paginator.first = 1;
            this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;

        } else {
            this.getFilterSideBar();
            this.paginator.first = 1;
            this.filteredSavedItem = this.filteredSavedItem - 1;
        }

    }
    onRiderFeeSelect(event: any) {
        this.amRatingFlag = ""
        this.productfilterFlag = ""
        this.groupfilterFlag = ""
        this.surrFlag = ""
        this.strategyFlag = ""
        this.riderFlag = 'riderFee'
        this.filterdataobj_riderfee = [];
        this.filterdataobj_riderfee_name = [];

        if (event.value) {
            if (event.value) {
                this.filterdataobj_riderfee.push(String(event.value.riderFee));
                this.filterdataobj_riderfee_name.push({ riderFee: String(event.value.riderFee) });
            }

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
        }
        this.getFilterSideBar();
        this.paginator.first = 1;
        this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;

        if (this.SelectRiderFee.riderFee == 0) {
            this.SelectRiderFee.riderFee = '0'
        }
    }
    onStrategyFeeSelect(event: any) {
        this.amRatingFlag = ""
        this.productfilterFlag = ""
        this.groupfilterFlag = ""
        this.surrFlag = ""
        this.riderFlag = ""
        this.strategyFlag = 'strategyFees'
        this.filterdataobj_strategyfee = [];
        this.filterdataobj_strategyfee_name = [];
        if (event.value) {
            this.filterdataobj_strategyfee.push({ strategyFee: event.value.value });
            this.filterdataobj_strategyfee_name.push({ strategyFee: event.value.strategyFee });
        }
        let chipDataObject = {
            filterdataobj_group_name: this.filterdataobj_group_name,
            filterdataobj_products_name: this.filterdataobj_products_name,
            SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
            filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
            filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
            filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
        }
        sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
        this.getFilterSideBar();
        this.paginator.first = 1;
        this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
    }
    onConfiMultiSelect(event: any) {
        let { value } = event;
        this.filterdataobj_confiMulti_name = value;
        this.getFilterSideBar();
        this.paginator.first = 1;
        this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
    }
    removeTags(item: any): void {
        let index: number = 0;
        // console.log('item', item);
        if (item.groupName !== undefined) {
            index = this.filterdataobj_group.findIndex((e: any) => e === item.groupId);
            this.filterdataobj_group.splice(index, 1);

            index = this.filterdataobj_group_name.findIndex((e: any) => e.groupId === item.groupId);
            this.filterdataobj_group_name.splice(index, 1);
            this.selectedGroups = [...this.filterdataobj_group_name];

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
        if (item.bestid !== undefined) {

            index = this.SelectFilter_AMbest.findIndex((e: any) => e == item.bestid);
            this.SelectFilter_AMbest.splice(index, 1);

            index = this.SelectFilter_AMbest_name.findIndex((e: any) => e.bestid == item.bestid);
            this.SelectFilter_AMbest_name.splice(index, 1);
            this.selectedAMBestrating = [...this.SelectFilter_AMbest_name];

            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
        if (item.surryr !== undefined) {

            index = this.filterdataobj_surrender_year.findIndex((e: any) => e === item.surryr);
            this.filterdataobj_surrender_year.splice(index, 1);
            index = this.filterdataobj_surrender_year_name.findIndex((e: any) => e.surryr === item.surryr);
            this.filterdataobj_surrender_year_name.splice(index, 1);
            this.selectedSuryear = [...this.filterdataobj_surrender_year_name];
            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
        if (item.strategyFee !== undefined) {

            // index = this.filterdataobj_strategyfee.findIndex((e: any) => e.strategyFee === item.strategyFee);
            // this.filterdataobj_strategyfee.splice(index, 1);
            // index = this.filterdataobj_strategyfee_name.findIndex((e: any) => e.strategyFee === item.strategyFee);
            // this.filterdataobj_strategyfee_name.splice(index, 1);

            index = this.filterdataobj_strategyfee.findIndex((e: any) => e.strategyFee === this.strategyFeeOptions.find(x => x.strategyFee == item.strategyFee).value);
            if (index > -1) {
                this.filterdataobj_strategyfee.splice(index, 1);
            }
            index = this.filterdataobj_strategyfee_name.findIndex((e: any) => e.strategyFee === item.strategyFee);
            if (index > -1) {
                this.filterdataobj_strategyfee_name.splice(index, 1);
            }
            this.selectedStrategyFee = [...this.filterdataobj_strategyfee_name]
            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
        if (item.riderFee !== undefined) {
            index = this.filterdataobj_riderfee.findIndex((e: any) => e.riderFee === item.riderFee);
            this.filterdataobj_riderfee.splice(index, 1);
            index = this.filterdataobj_riderfee_name.findIndex((e: any) => e.riderFee === item.riderFee);
            this.filterdataobj_riderfee_name.splice(index, 1);
            this.SelectRiderFee = [...this.filterdataobj_riderfee_name]
            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
        if (item.confiMulti !== undefined) {
            this.SelectFilter6 = [];
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
        if (item.data !== undefined) {
            index = this.filterdataobj_products.findIndex((e: any) => e === item.key);
            this.filterdataobj_products.splice(index, 1);
            index = this.filterdataobj_products_name.findIndex((e: any) => e.key === item.key);
            this.filterdataobj_products_name.splice(index, 1);
            this.SelectFilter4 = [...this.filterdataobj_products_name];
            let chipDataObject = {
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_products_name: this.filterdataobj_products_name,
                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
            }
            sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
            this.filteredSavedItem = this.filteredSavedItem - 1
        }
        this.getFilterSideBar();
        this.paginator.first = 1;
    }
    clearallFilter() {

        this.groupfilterFlag = '';

        this.productFlag = '';
        this.productfilterFlag = '';

        this.amRatingFlag = '';
        this.amRatingfilterFlag = '';

        this.riderFlag = ""
        this.strategyFlag = ""

        this.surrFlag = ''
        this.surrfilterFlag = '';

        this.filterdataobj_group = [];
        this.filterdataobj_group_name = [];
        this.SelectFilter_AMbest = [];
        this.SelectFilter_AMbest_name = []
        this.filterdataobj_surrender_year = [];
        this.filterdataobj_surrender_year_name = [];

        this.filterdataobj_products = [];
        this.filterdataobj_products_name = [];
        this.filterdataobj_riderfee = [];
        this.filterdataobj_riderfee_name = [];
        this.filterdataobj_strategyfee = [];
        this.filterdataobj_strategyfee_name = [];

        this.selectedGroups = [];
        this.selectedAMBestrating = [];
        this.selectedStrategyFee = [];
        this.selectedSuryear = [];
        this.SelectRiderFee = [];
        this.SelectFilter4 = [];
        sessionStorage.removeItem('sessionGroup');
        sessionStorage.removeItem('sessionProduct');
        sessionStorage.removeItem('sidebarObject');
        sessionStorage.removeItem('chipDataObject');
        this.IsCustomShelf = false;
        this.filtredCustomSelfObj = [];
        this.getFilterSideBar();
        this.paginator.first = 1;
        this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
    }

    getanuuleIncome(data: any) {
        let Income: number = 0;
        if (this.topbarForm.get('Contribution')?.value == 'contribution') {
            if (this.topbarForm.get('periode')?.value) {
                Income = this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? (data.factorJoint * this.topbarForm.get('amount')?.value!) : (data.factorSingle * this.topbarForm.get('amount')?.value!)
            }
            else {
                Income = this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? ((data.factorJoint * this.topbarForm.get('amount')?.value!) / 12) : ((data.factorSingle * this.topbarForm.get('amount')?.value!) / 12)
            }
        }
        else {
            if (this.topbarForm.get('periode')?.value) {
                Income = this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? (this.topbarForm.get('amount')?.value! / data.factorJoint) : (this.topbarForm.get('amount')?.value! / data.factorSingle)
            }
            else {
                Income = this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? (this.topbarForm.get('amount')?.value! / data.factorJoint / 12) : (this.topbarForm.get('amount')?.value! / data.factorSingle / 12);
            }
        }
        return Math.round(Income);
    }
    getFilterSideBar() {
        let groupIds = [];
        let productIds = [];
        let amBestRatingIds = [];
        let surrYearIds = [];
        let riderFeeId: any;
        let strategyFeeId: any;
        let coMultiplier: any;
        // let riderTypes: any;
        let basicFilterObj: any = {
            age: this.topbarForm.get('age')?.value,
            spage: this.topbarForm.get('pouseage')?.value,
            amountType: this.topbarForm.get('Contribution')?.value,
            amount: this.topbarForm.get('amount')?.value,
            periode: this.topbarForm.get('periode')?.value,
        }
        this.withdrawalFrequency = basicFilterObj.periode == 1 ? 'Annually' : 'Monthly';
        sessionStorage.setItem("basicFilterObj", JSON.stringify(basicFilterObj));
        if (this.filterdataobj_group != undefined) {
            for (let i = 0; i < this.filterdataobj_group.length; i++) {
                if (this.filterdataobj_group[i]) {
                    groupIds.push(this.filterdataobj_group[i]);
                }
            }
        }
        if (this.filterdataobj_products != undefined) {
            for (let i = 0; i < this.filterdataobj_products.length; i++) {
                if (this.filterdataobj_products[i]) {
                    productIds.push(this.filterdataobj_products[i]);
                }

            }
        }
        if (this.SelectFilter_AMbest != undefined) {
            for (let i = 0; i < this.SelectFilter_AMbest.length; i++) {
                if (this.SelectFilter_AMbest[i]) {
                    amBestRatingIds.push(this.SelectFilter_AMbest[i]);
                }
            }
        }
        if (this.filterdataobj_surrender_year != undefined) {
            for (let i = 0; i < this.filterdataobj_surrender_year.length; i++) {
                if (this.filterdataobj_surrender_year[i]) {
                    surrYearIds.push(this.filterdataobj_surrender_year[i]);
                }
            }
        }
        if (this.filterdataobj_riderfee != undefined) {
            if (this.filterdataobj_riderfee[0]) {
                riderFeeId = this.filterdataobj_riderfee[0];
            }
        }
        if (this.filterdataobj_strategyfee != undefined) {
            if (this.filterdataobj_strategyfee[0]) {
                strategyFeeId = this.filterdataobj_strategyfee[0].strategyFee;
            } else {
                strategyFeeId = 2;
            }
        }
        if (this.filterdataobj_confiMulti_name != undefined) {
            if (this.filterdataobj_confiMulti_name) {
                coMultiplier = this.filterdataobj_confiMulti_name === 'yes' ? true : false;
            }
        }
        var agedetail: number = 0;
        var spage: number = this.topbarForm.get('pouseage')?.value || 0;
        var age: number = this.topbarForm.get('age')?.value || 0;
        agedetail = this.topbarForm.get('age')?.value || 0

        let sidebarObj: any;
        if (spage > age) {
            agedetail = age;
            sidebarObj = {
                "ageBand": agedetail || Number(age),
                "deferralYear": this.topbarForm.get('diffperiode')?.value,
                "gender": this.topbarForm.get('gender')?.value || 'select',
                "groupId": groupIds || [],
                "productId": productIds || [],
                "amBestRating": amBestRatingIds || [],
                "surrYear": surrYearIds || [],
                "riderFee": riderFeeId == undefined ? null : riderFeeId || null,
                // "strategyFee": strategyFeeId / 100 || null,
                "strategyFee": (strategyFeeId == 0 || strategyFeeId == 1) ? strategyFeeId : 2,
                "coMultiplier": coMultiplier || null,
                "benefitBaseType": this.topbarForm.get('ridertype')?.value,
                "stateCode": this.topbarForm.get('illinois')?.value
            }
        } else {
            agedetail = spage
            sidebarObj = {
                "ageBand": agedetail || Number(age),
                "deferralYear": this.topbarForm.get('diffperiode')?.value,
                "gender": this.topbarForm.get('gender')?.value || 'select',
                "groupId": groupIds || [],
                "productId": productIds || [],
                "amBestRating": amBestRatingIds || [],
                "surrYear": surrYearIds || [],
                "riderFee": riderFeeId == undefined ? null : riderFeeId || null,
                // "strategyFee": strategyFeeId / 100 || null,
                "strategyFee": (strategyFeeId == 0 || strategyFeeId == 1) ? strategyFeeId : 2,
                "coMultiplier": coMultiplier || null,
                "benefitBaseType": this.topbarForm.get('ridertype')?.value,
                "stateCode": this.topbarForm.get('illinois')?.value
            }
        }
        sidebarObj.sortBy = sidebarObj.sortBy ? sidebarObj.sortBy : 'WithdrawalAmount';
        sidebarObj.joint = this.topbarForm.get('pouseage')?.value ? 'Yes' : 'No';
        // console.log('getFilterSideBar obj', sidebarObj);
        sessionStorage.setItem("sidebarObject", JSON.stringify(sidebarObj));
        this.isLoader = true;
        this.filteredArray = [];
        this.griddata = [];
        this.pageNumber = 1;
        sidebarObj['pageNumber'] = this.pageNumber;
        sidebarObj['pageSize'] = this.pageSize;
        this.userShelfProductIds = this.loginUserData.userSelfProductListIndex.map((x: any) => x.productid);
        sidebarObj['productId'] = [...new Set([...sidebarObj['productId'], ...this.userShelfProductIds])];
        if (this.showApprovedProductsOnly) {
            let approvedProductIds: number[] = this.loginUserData.userSelfProductListIndex_approved.map((x: any) => x.productid);
            sidebarObj['productId'] = approvedProductIds;
        }
        this.benefitcalculatorService.postProductsDetails(sidebarObj).subscribe((res: any) => {
            this.isLoader = false;
            this.setProductList(res);
        }, (error) => {
            this.isLoader = false;
            this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Something went wrong, please try sometime' });
        });

    }

    getFilterSideBarInit(sidebarObj1: any) {
        let obj = JSON.parse(sidebarObj1);
        obj.sortBy = obj.sortBy ? obj.sortBy : 'WithdrawalAmount';
        obj.joint = this.topbarForm.get('pouseage')?.value ? 'Yes' : 'No';
        obj.ageBand = this.topbarForm.get('age')?.value || 55;
        obj.deferralYear = this.topbarForm.get('diffperiode')?.value || 10;
        obj.gender = this.topbarForm.get('gender')?.value || 'M';
        obj.spageBand = this.topbarForm.get('pouseage')?.value || 55;
        obj.benefitBaseType = this.topbarForm.get('ridertype')?.value || 'All';
        obj.stateCode = this.topbarForm.get('illinois')?.value || 'ALL';
        let basicFilterObj: any = {
            age: this.topbarForm.get('age')?.value,
            spage: this.topbarForm.get('pouseage')?.value,
            amountType: this.topbarForm.get('Contribution')?.value,
            amount: this.topbarForm.get('amount')?.value,
            periode: this.topbarForm.get('periode')?.value,
        }
        sessionStorage.setItem("basicFilterObj", JSON.stringify(basicFilterObj));
        if (!this.selectedStateName && obj.stateCode) {
            const state = this.stateData.find(x => x.stateCode == obj.stateCode);
            if (state) {
                this.selectedStateName = state.stateName;
            }
        }
        sessionStorage.setItem("sidebarObject", JSON.stringify(obj));
        this.filterdataobj_group = obj.groupId != null ? obj.groupId : [];
        this.filterdataobj_products = obj.productId != null ? obj.productId : [];
        this.SelectFilter_AMbest = obj.amBestRating != null ? obj.amBestRating : [];
        this.filterdataobj_surrender_year = obj.surrYear != null ? obj.surrYear : [];
        this.filterdataobj_riderfee = obj.riderFee != null ? obj.groupId : null;
        this.filterdataobj_strategyfee = obj.strategyFee != null ? obj.groupId : null;
        this.isLoader = true;
        obj['pageNumber'] = this.pageNumber;
        obj['pageSize'] = this.pageSize;
        let productIds: number[] = [];

        if (!obj['productId']) {
            obj['productId'] = [];
        }
        if (this.showApprovedProductsOnly) {
            productIds = this.loginUserData.userSelfProductListIndex_approved.map((x: any) => x.productid);
        } else {
            productIds = this.loginUserData.userSelfProductListIndex.map((x: any) => x.productid);
            this.userShelfProductIds = Object.assign([], productIds);
        }
        if (this.filterdataobj_products.length > 0) {
            obj['productId'] = this.filterdataobj_products.filter((value: number) => productIds.indexOf(value) > -1);
        }
        const selectedShelfName = sessionStorage.getItem('CustomShelfName');
        if (selectedShelfName) {
            let customShelfProducts = this.userCustomShelfs.find(x => x.shelfName == selectedShelfName).items.map((x: any) => x.productId);
            if (customShelfProducts.length > 0) {
                obj['productId'] = [...obj['productId'], customShelfProducts];
            }
        }
        if (obj['productId'].length == 0) {
            obj['productId'] = productIds;
        } else {
            obj['productId'] = obj['productId'].filter((value: number) => productIds.indexOf(value) > -1);
        }
        this.benefitcalculatorService.postProductsDetails(obj).subscribe((res: any) => {
            this.isLoader = false;
            this.setProductList(res);
        }, (error) => {
            this.isLoader = false;
            this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Something went wrong, please try sometime' });
        });
    }

    onExport() {
        this.paginateIllustration = false;
        this.isLoad = true;
        setTimeout(() => {
            html2canvas(this.exportPdf.nativeElement, { useCORS: true, scale: 2 }).then((successRef: any) => {
                // const doc = new jsPDF('p', 'mm', 'a4');
                // const img = successRef.toDataURL('image/png;base64');
                // const bufferX = 5;
                // const bufferY = 5;
                // const imgProps = (<any>doc).getImageProperties(img);
                // const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
                // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                // doc.addImage(
                //     img,
                //     'PNG',
                //     bufferX,
                //     bufferY,
                //     pdfWidth,
                //     pdfHeight,
                //     undefined,
                //     'FAST'
                // );
                // return doc;

                const imgWidth = 208;
                const pageHeight = 295;
                const imgHeight = (successRef.height * imgWidth) / successRef.width;
                let heightLeft = imgHeight;
                let position = 0;
                heightLeft -= pageHeight;
                const doc = new jsPDF('p', 'mm', 'a4');
                doc.addImage(successRef, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(successRef, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                    heightLeft -= pageHeight;
                }
                return doc;
            })
                .then((doc: any) => {
                    doc.save(`Illustration_${Date.now()}.pdf`);
                    this.isLoad = false;
                    this.paginateIllustration = true;
                });
        }, 500);

    }

    onCompare(event: any) {
        this.totalCompare = 0;
        this.isCompare = event.checked;
        if (this.isCompare === false) {
            this.oncompareDatachange(this.compareData)
            this.filteredArray.map((data: any) => {
                if (data.isChecked == true) {
                    data.isChecked = false;
                }
            })
        } else {
            this.compareData = []
        }
    }
    onComparechange(event: any, e: any) {
        if (e.checked == true) {
            this.totalCompare = this.compareData.length + 1;
        } else {
            this.totalCompare = this.totalCompare > 0 ? this.totalCompare - 1 : 0;
            if (this.totalCompare == 0) {
                this.checked = false;
                this.compareData = [];
                this.isCompare = false;
                this.filteredArray.map((data: any) => {
                    if (data.isChecked == true) {
                        data.isChecked = false;
                    }
                });
                return;
            }
        }

        if (this.isCompare === false) {
            this.isCompare = true;
        }

        if (e.checked == true) {
            if (this.compareData.length < 4) {
                this.rollupdata = [];
                let test = this.filteredArray.find((x: any) => x.id == event.id)
                if (test) {
                    test.isChecked = true;
                }
                this.filteredArray.map((data: any) => {
                    if (Number(event.id) === data.id && Number(event.withdrawalPer) === data.withdrawalPer) {
                        // let rollupdata = [];
                        // let rollupyrs;
                        // let rollupsimplecomp;
                        // let rollupTerm;
                        // let rollupAge;
                        // let rollupAgecheck;
                        // let rollup = [];
                        // // if (data.rollupAge == null) {
                        // //     rollupAge = data.rollupAge;
                        // // }
                        // // else {
                        // //     rollupAge = data.rollupAge != '' && data.rollupAge != '-' ? data.rollupAge.split(',') : null;
                        // // }
                        //  if (data.maxRollupYears == null) {
                        //     rollupAge = data.maxRollupYears;
                        // }
                        // else {
                        //     rollupAge = data.maxRollupYears != '' && data.maxRollupYears != '-' ? data.maxRollupYears.split(',') : null;
                        // }
                        // // console.log('role up age', rollupAge);
                        // if (data.rollupTerm == null) {
                        //     data.rollupTerm = '0';
                        //     rollupTerm = data.rollupTerm;
                        // }
                        // else {
                        //     rollupTerm = data.rollupTerm.split(',');
                        // }

                        // if (data.rollUp){
                        //     rollup = data.rollUp.split(',');
                        // }

                        // if (data.rollupYears == null) {
                        //     data.rollupYears = '0';
                        //     rollupyrs = data.rollupYears;
                        // }
                        // else {
                        //     rollupyrs = data.rollupYears.split(',');
                        // }

                        // if (data.rollupIsSimpleComp == null) {
                        //     data.rollupIsSimpleComp = ' " " ';
                        //     rollupsimplecomp = data.rollupIsSimpleComp;
                        // }
                        // else {
                        //     rollupsimplecomp = data.rollupIsSimpleComp.split(',');
                        // }
                        // // console.log('rollUp length', rollup);
                        // for (let i = 0; i < rollup.length; i++) {
                        //     if (rollup.length == 2) {
                        //         rollupAgecheck = rollupAge[i].split('-');
                        //         if (Number(rollupAgecheck[0]) <= Number(data.ageBand) && Number(rollupAgecheck[1]) >= Number(data.ageBand)) {
                        //             // this.rollupdata.push('Years' + ' ' + rollupyrs[i] + ' ' + '/' + ' ' + rollup[i] + '%' + ' ' + rollupsimplecomp[i] + ' ' + '/' + (Number(rollupTerm[i]) == 1 ? 'Age' : ' ') + ' ' + '(' + rollupAge[i] + ')')
                        //             rollupdata.push(rollup[i] + '% '+ rollupsimplecomp[i]+ ' / Years ' + (rollupAge ? (Number(rollupTerm[i]) == 1 ? 'Age ' : '') + rollupAge[i] :''));
                        //         }
                        //     }
                        //     else {
                        //         // this.rollupdata.push('Years' + ' ' + rollupyrs[i] + ' ' + '/' + ' ' + rollup[i] + '%' + ' ' + rollupsimplecomp[i] + ' ' + '/' + (Number(rollupTerm[i]) == 1 ? 'Age' : ' ') + ' ' + '(' + rollupAge[i] + ')')
                        //         rollupdata.push(rollup[i] + '% '+ rollupsimplecomp[i]+ ' / Years ' + (rollupAge ? (Number(rollupTerm[i]) == 1 ? 'Age ' : '') + rollupAge[i] :''));
                        //     }
                        // }
                        // if (data.rollUp == null) {
                        //     rollup = data.rollUp;
                        // }
                        // else {
                        //     rollup = data.rollUp.split(',');
                        // }
                        // if (data.maxRollupYears == null) {
                        //     data.maxRollupYears = '0';
                        //     rollupyrs = data.maxRollupYears;
                        // }
                        // else {
                        //     rollupyrs = data.maxRollupYears.split(',');
                        // }
                        // if (data.rollupIsSimpleComp == null) {
                        //     data.rollupIsSimpleComp = ' ';
                        //     rollupsimplecomp = data.rollupIsSimpleComp;
                        // }
                        // else {
                        //     rollupsimplecomp = data.rollupIsSimpleComp.split(',');
                        // }
                        // for (let i = 0; i < rollup.length; i++) {
                        //     this.rollupdata.push('Years' + ' ' + rollupyrs[i] + ' ' + '/' + ' ' + rollup[i] + '%' + ' ' + rollupsimplecomp[i])
                        // }

                        let rollUpdata = [];
                        //   console.log('rollup percentage', data.rollupPer, data.rollupPerJoint, data.rollupYears, data.maxRollupYears, data.rollupIsSimpleComp);
                        if (this.topbarForm.get('pouseage')?.value != 0) {
                            if (data.rollupPerJoint != null && data.rollupPerJoint != '') {
                                const rollUpPerArray = data.rollupPerJoint.split(',');
                                const rollupYearsArray = data.maxRollupYears.split(',');
                                const rollupIsSimpleCompArray = data.rollupIsSimpleComp.split(',');
                                // const rollupIsSimpleCompArray = data.rollupIsSimpleComp.split(',');
                                for (let index = 0; index < rollUpPerArray.length; index++) {
                                    let rollUpText: any = rollUpPerArray[index] + '% ' + rollupIsSimpleCompArray[index] + ' / Years ' + rollupYearsArray[index];
                                    rollUpdata.push(rollUpText);
                                }
                            }
                        } else {
                            if (data.rollupPer != null && data.rollupPer != '') {
                                const rollUpPerArray = data.rollupPer.split(',');
                                const rollupYearsArray = data.maxRollupYears.split(',');
                                const rollupIsSimpleCompArray = data.rollupIsSimpleComp.split(',');
                                for (let index = 0; index < rollUpPerArray.length; index++) {
                                    let rollUpText: any = rollUpPerArray[index] + '% ' + rollupIsSimpleCompArray[index] + ' / Years ' + rollupYearsArray[index];
                                    rollUpdata.push(rollUpText);
                                }
                            }
                        }
                        let rollupsimplecomp = '';
                        let riderAge = '';

                        if ((this.agecheck > 0 && this.spouseagecheck == 0) || (this.agecheck == 0 && this.spouseagecheck > 0)) {
                            riderAge = this.agecheck > 0 ? this.agecheck.toString() : this.spouseagecheck.toString();
                        } else if (this.agecheck > 0 && this.spouseagecheck > 0) {
                            riderAge = `Age ${this.agecheck} / Spouse Age ${this.spouseagecheck}`;
                        }
                        // console.log('bbBonus', data.bbBonus);
                        // console.log('premiumBonus', data.premiumBonus);
                        this.compareData.push(
                            {
                                logo1: data.logo1,
                                logo2: data.logo2,
                                gmwbId: data.gmwbId,
                                productid: data.productid,
                                productName: data.productName,
                                companyid: data.companyid,
                                companyName: data.companyName,
                                riderName: data.riderName,
                                riderAge: riderAge, //`Min. Age ${data.minAge} / Max. Age ${data.maxAge}`,
                                riderFeeBasis: data.riderFee + ' ' + 'of' + ' ' + 'Benefit Base',
                                singleJoinIncomeData: this.topbarForm.get('pouseage')?.value != 0 ? 'Joint' : 'Single',
                                singleJointAge: this.topbarForm.get('pouseage')?.value == 0 ? this.topbarForm.get('age')?.value : (this.topbarForm.get('age')?.value + '/' + this.topbarForm.get('pouseage')?.value),
                                purchasePayment: this.topbarForm.get('amount')?.value,
                                initialBenifitdata: ((this.topbarForm.get('amount')?.value || 100000) + (((this.topbarForm.get('amount')?.value || 100000) * data.bbBonus) / 100) + (((this.topbarForm.get('amount')?.value || 100000) * data.premiumBonus) / 100)),
                                deffralPer: this.topbarForm.get('diffperiode')?.value || 10,
                                //   annualIncome: '$' + this.getanuuleIncome(data).toLocaleString('en') + ' ' + (this.topbarForm.get('periode')?.value ? 'Annually' : 'Monthly'),
                                annualIncome: '$' + this.getanuuleIncome(data).toLocaleString('en'),
                                rolldata: data.rollUpdata,
                                minAge: data.minAge,
                                maxAge: data.maxAge,
                                riderFee: data.riderFee,
                                rollupPer: data.rollupPer,
                                withdrawalPer: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer, //data.withdrawalPer,
                                riderFeeJoint: data.riderFeeJoint,
                                rollupPerJoint: data.rollupPerJoint,
                                withdrawalPerJoint: data.withdrawalPerJoint,
                                maxRollupYears: data.rollupYears,
                                rollupYears: data.rollupYears,
                                isCaseAOrC: data.isCaseAOrC,
                                ageBand1: data.ageBand1,
                                ageBand2: data.ageBand2,
                                purchasePayBonus: data.purchasePayBonus,
                                internalName: data.internalName,
                                bonusPercentage: data.bonusPercentage,
                                bonusName: data.bonusName,
                                factorSingle: data.factorSingle,
                                factorJoint: data.factorJoint,
                                premiumBonus: data.premiumBonus,
                                bonusPercentageBasis: data.BonusPercentageBasis,
                                id: data.id,
                                otherVariable: data.otherVariable,
                                benifitBaseType: data.benifitBaseType,
                                bbBonus: data.bbBonus,
                                groupId: data.groupId,
                                groupName: data.groupName,
                                bestid: data.bestid,
                                surryr: data.surryr,
                                strategyFee: data.strategyFee,
                                gender: data.gender,
                                bonusCaseId: data.bonusCaseId,
                                bonusCaseName: data.bonusCaseName,
                                availStateException: data.availStateException ? data.availStateException : "",
                                exceptions: data.exceptions,
                                rollupIsSimpleComp: data.rollupIsSimpleComp,
                                riderFeedata: data.riderFee, //(this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.riderFeeJoint : data.riderFee) ,
                                chargeBasisName: data.chargeBasisName,
                                highAnnivValStepup: data.highAnnivValStepup,
                                anualIncom: this.getanuuleIncome(data),
                                IncomePeriod: this.topbarForm.get('periode')?.value ? 'Estimated Annually' : 'Estimated Monthly',
                                rollUp: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.rollupPerJoint : data.rollupPer,
                                withdrawal: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer,
                                gmwbAvailStateExceptions: data.gmwbAvailStateExceptions,
                                isChecked: true,
                                waitingTerm: data.waitingTerm,
                                waitingPeriod: data.waitingPeriod ? parseInt(data.waitingPeriod) : 0,
                                chargeFreqDeductedWhen: data.chargeFreqDeductedWhen,
                                marketingName: data.marketingName,
                                isShelfProduct: this.userShelfProductIds.indexOf(data.productid) > -1
                            }
                        )
                    }
                })
                // console.log('comparedata', this.compareData);
            }
        }
        else {
            let index: number = 0;
            index = this.compareData.findIndex((e: any) => e.id === event.id);
            this.compareData.splice(index, 1);
        }
    }
    onComparebtnchange(event: any, e: any) {
        this.compareData = []
        this.checked = true;
        if (this.isCompare === false) {
            this.isSwitch = true
            this.isCompare = true;
            // this.totalCompare = 1
        }

        if (this.compareData.length < 2) {
            this.rollupdata = [];
            let test = this.filteredArray.find((x: any) => x.id == event.id)
            if (test) {
                test.isChecked = true;
            }
            this.filteredArray.map((data: any) => {
                if (Number(event.id) === data.id && Number(event.withdrawalPer) === data.withdrawalPer) {
                    // let rollUpdata = [];
                    // if (this.topbarForm.get('pouseage')?.value != 0) {
                    //     if (data.rollupPerJoint != null && data.rollupPerJoint != '') {
                    //         const rollUpPerArray = data.rollupPerJoint.split(',');
                    //         const rollupYearsArray = data.maxRollupYears.split(',');
                    //         const rollupIsSimpleCompArray = data.rollupIsSimpleComp.split(',');
                    //         console.log('rollupYearsArray', rollupYearsArray);
                    //         for (let index = 0; index < rollUpPerArray.length; index++) {
                    //             let rollUpText: any = rollUpPerArray[index] + '% ' + rollupIsSimpleCompArray[index] + ' / Years ' + rollupYearsArray[index];
                    //             rollUpdata.push(rollUpText);
                    //         }
                    //     }
                    // } else {
                    //     if (data.rollupPer != null && data.rollupPer != '') {
                    //         const rollUpPerArray = data.rollupPer.split(',');
                    //         const rollupYearsArray = data.maxRollupYears.split(',');
                    //         const rollupIsSimpleCompArray = data.rollupIsSimpleComp.split(',');
                    //         for (let index = 0; index < rollUpPerArray.length; index++) {
                    //             let rollUpText: any = rollUpPerArray[index] + '% ' + rollupIsSimpleCompArray[index] + ' / Years ' + rollupYearsArray[index];
                    //             rollUpdata.push(rollUpText);
                    //         }
                    //     }
                    // }
                    let riderAge = '';
                    if ((this.agecheck > 0 && this.spouseagecheck == 0) || (this.agecheck == 0 && this.spouseagecheck > 0)) {
                        riderAge = this.agecheck > 0 ? this.agecheck.toString() : this.spouseagecheck.toString();
                    } else if (this.agecheck > 0 && this.spouseagecheck > 0) {
                        riderAge = `Age ${this.agecheck} / Spouse Age ${this.spouseagecheck}`;
                    }
                    this.compareData.push(
                        {
                            logo1: data.logo1,
                            logo2: data.logo2,
                            gmwbId: data.gmwbId,
                            productid: data.productid,
                            productName: data.productName,
                            companyid: data.companyid,
                            companyName: data.companyName,
                            riderName: data.riderName,
                            riderAge: riderAge, //`Min. Age ${data.minAge} / Max. Age ${data.maxAge}`,
                            riderFeeBasis: data.riderFee + ' ' + 'of' + ' ' + 'Benefit Base',
                            singleJoinIncomeData: this.topbarForm.get('pouseage')?.value != 0 ? 'Joint' : 'Single',
                            singleJointAge: this.topbarForm.get('pouseage')?.value == 0 ? this.topbarForm.get('age')?.value : (this.topbarForm.get('age')?.value + '/' + this.topbarForm.get('pouseage')?.value),
                            purchasePayment: this.topbarForm.get('amount')?.value,
                            initialBenifitdata: ((this.topbarForm.get('amount')?.value || 100000) + (((this.topbarForm.get('amount')?.value || 100000) * data.bbBonus) / 100) + (((this.topbarForm.get('amount')?.value || 100000) * data.premiumBonus) / 100)),
                            deffralPer: this.topbarForm.get('diffperiode')?.value || 10,
                            //annualIncome: '$' + this.getanuuleIncome(data).toLocaleString('en') + ' ' + (this.topbarForm.get('periode')?.value ? 'Annually' : 'Monthly'),
                            annualIncome: '$' + this.getanuuleIncome(data).toLocaleString('en'),
                            rolldata: data.rollUpdata,
                            minAge: data.minAge,
                            maxAge: data.maxAge,
                            riderFee: data.riderFee,
                            rollupPer: data.rollupPer,
                            withdrawalPer: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer, //data.withdrawalPer,
                            riderFeeJoint: data.riderFeeJoint,
                            rollupPerJoint: data.rollupPerJoint,
                            withdrawalPerJoint: data.withdrawalPerJoint,
                            maxRollupYears: data.rollupYears,
                            rollupYears: data.rollupYears,
                            isCaseAOrC: data.isCaseAOrC,
                            ageBand1: data.ageBand1,
                            ageBand2: data.ageBand2,
                            purchasePayBonus: data.purchasePayBonus,
                            internalName: data.internalName,
                            bonusPercentage: data.bonusPercentage,
                            bonusName: data.bonusName,
                            factorSingle: data.factorSingle,
                            factorJoint: data.factorJoint,
                            premiumBonus: data.premiumBonus,
                            bonusPercentageBasis: data.BonusPercentageBasis,
                            id: data.id,
                            otherVariable: data.otherVariable,
                            benifitBaseType: data.benifitBaseType,
                            bbBonus: data.bbBonus,
                            groupId: data.groupId,
                            groupName: data.groupName,
                            bestid: data.bestid,
                            surryr: data.surryr,
                            strategyFee: data.strategyFee,
                            gender: data.gender,
                            bonusCaseId: data.bonusCaseId,
                            bonusCaseName: data.bonusCaseName,
                            availStateException: data.availStateException ? data.availStateException : "",
                            exceptions: data.exceptions,
                            rollupIsSimpleComp: data.rollupIsSimpleComp,
                            riderFeedata: data.riderFee, //(this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.riderFeeJoint : data.riderFee) ,
                            chargeBasisName: data.chargeBasisName,
                            highAnnivValStepup: data.highAnnivValStepup,
                            anualIncom: this.getanuuleIncome(data),
                            IncomePeriod: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('periode')?.value ? 'Estimated Annually' : 'Estimated Monthly',
                            rollUp: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.rollupPerJoint : data.rollupPer,
                            withdrawal: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer,
                            gmwbAvailStateExceptions: data.gmwbAvailStateExceptions,
                            isChecked: true,
                            waitingTerm: data.waitingTerm,
                            waitingPeriod: data.waitingPeriod ? parseInt(data.waitingPeriod) : 0,
                            chargeFreqDeductedWhen: data.chargeFreqDeductedWhen,
                            marketingName: data.marketingName,
                            isShelfProduct: this.userShelfProductIds.indexOf(data.productid) > -1
                        }
                    )

                }
            })
        } else {
            // this.compareData = [];
        }
        // this.onCompare(e);
    }
    oncompChipRemove(compdata: any) {
        let test = this.filteredArray.find((x: any) => x.id == compdata.id)
        if (test) {
            test.isChecked = false;
        }
        let index: number = 0;
        index = this.compareData.findIndex((e: any) => e.id === compdata.id);
        this.compareData.splice(index, 1);
        compdata.isChecked = false;
        this.compDataChk = false;
        this.totalCompare = this.totalCompare - 1
    }
    oncompareDatachange(val: any) {
        if (this.compareData.length >= 2) {
            // this.showCompareDialog = true;
            this.compareCardsData = val;
            let productsId: any = this.compareData.map(x => x.productid);
            productsId = [...new Set(productsId)].toString();
            this.router.navigateByUrl(`/indexed-product-comparison?products=${productsId}`);
        }
    }
    clearCompareChange() {
        this.isCompare = false;
        this.totalCompare = 0;
        this.checked = false;
        this.compareData = [];
        this.filteredArray.map((data: any) => {
            if (data.isChecked == true) {
                data.isChecked = false;
            }
        })
    }
    onProductProfile(item: any) {
    }
    onLogout() {
        // sessionStorage.removeItem('Token');
        // sessionStorage.removeItem('UserName');
        // sessionStorage.removeItem('userId');
        // sessionStorage.removeItem('sidebarObject');
        // sessionStorage.removeItem('chipDataObject');
        // sessionStorage.removeItem('basicFilterObj');
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
    }
    saveContinue() {
        this.saveSeavch = true;
    }
    saveedSearches() {
        this.router.navigate(['/savedsearch'])
    }
    saveSearch() {
        this.saveFormSubmitted = true;
        if (this.searchDataForm.valid) {
            let SelectFilter_AMbest = [];
            let surrYearIds = [];
            let groupIds = [];
            let productIds = [];
            let riderFeeId = [];
            let strategyFeeId = [];

            if (this.filterdataobj_group != undefined) {
                for (let i = 0; i < this.filterdataobj_group.length; i++) {
                    if (this.filterdataobj_group[i]) {
                        groupIds.push(this.filterdataobj_group[i]);
                    }
                }
            }
            if (this.filterdataobj_products != undefined) {
                for (let i = 0; i < this.filterdataobj_products.length; i++) {
                    if (this.filterdataobj_products[i]) {
                        productIds.push(this.filterdataobj_products[i]);
                    }

                }
            }
            if (this.SelectFilter_AMbest != undefined) {
                for (let i = 0; i < this.SelectFilter_AMbest.length; i++) {
                    if (this.SelectFilter_AMbest[i]) {
                        SelectFilter_AMbest.push(this.SelectFilter_AMbest[i]);
                    }
                }
            }
            if (this.filterdataobj_surrender_year != undefined) {
                for (let i = 0; i < this.filterdataobj_surrender_year.length; i++) {
                    if (this.filterdataobj_surrender_year[i]) {
                        surrYearIds.push(this.filterdataobj_surrender_year[i]);
                    }
                }
            }
            if (this.filterdataobj_riderfee != undefined) {
                if (this.filterdataobj_riderfee[0]) {
                    riderFeeId.push(this.filterdataobj_riderfee[0]);
                }
            }
            if (this.filterdataobj_strategyfee != undefined) {
                if (this.filterdataobj_strategyfee[0]) {
                    strategyFeeId.push(this.filterdataobj_strategyfee[0]);
                }
            }

            let chipobj = {
                filled: true,
                filterdataobj_group_name: this.filterdataobj_group_name,
                filterdataobj_groupid: groupIds,

                SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
                SelectFilter_AMbestid: SelectFilter_AMbest,

                filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
                filterdataobj_surrender_yearid: surrYearIds,

                filterdataobj_products_name: this.filterdataobj_products_name,
                filterdataobj_productsid: productIds,

                filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name,
                filterdataobj_strategyfeeid: strategyFeeId,

                filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
                filterdataobj_riderfeeid: riderFeeId,

                gender: this.topbarForm.get('gender')?.value,
                age: this.topbarForm.get('age')?.value,
                pouseage: this.topbarForm.get('pouseage')?.value,
                state: this.topbarForm.get('illinois')?.value,
                Contribution: this.topbarForm.get('Contribution')?.value,
                income: this.topbarForm.get('income')?.value,
                amount: this.topbarForm.get('amount')?.value,
                periode: this.topbarForm.get('periode')?.value,
                diffperiode: this.topbarForm.get('diffperiode')?.value,
                searchtitle: this.searchDataForm.value.searchtitle
            }
            let savedsearchdata = JSON.stringify(chipobj);
            let uID = this.benefitcalculatorService.decryptUsingAES256(JSON.parse(sessionStorage.getItem('userId') || ''));
            let savedsearchobj = {
                // id: ,
                userId: JSON.parse(uID),
                searchString: savedsearchdata,
                lastUpdatedDate: this.illEffectDate
            }

            //save serach insert
            this.benefitcalculatorService.postSaveSearch(savedsearchobj).subscribe((res: any) => {

            },
                err => {

                })
            // this.set('saveSearch', chipobj)
            this.messageService.add({ key: 'tc', severity: 'success', summary: 'success', detail: 'Search history has been saved Successfully !!' });
            this.saveSeavch = false;
            this.saveFormSubmitted = false;
            setTimeout(() => {
                this.router.navigate(['/savedsearch'])
            }, 2000);
        }
    }
    updateContinue() {
        let SelectFilter_AMbest = [];
        let surrYearIds = [];
        let groupIds = [];
        let productIds = [];
        let riderFeeId = [];
        let strategyFeeId = [];

        for (let i = 0; i < this.filterdataobj_group.length; i++) {
            if (this.filterdataobj_group[i]) {
                groupIds.push(this.filterdataobj_group[i]);
            }
        }

        for (let i = 0; i < this.filterdataobj_products.length; i++) {
            if (this.filterdataobj_products[i]) {
                productIds.push(this.filterdataobj_products[i]);
            }
        }

        for (let i = 0; i < this.SelectFilter_AMbest.length; i++) {
            if (this.SelectFilter_AMbest[i]) {
                SelectFilter_AMbest.push(this.SelectFilter_AMbest[i]);
            }
        }

        for (let i = 0; i < this.filterdataobj_surrender_year.length; i++) {
            if (this.filterdataobj_surrender_year[i]) {
                surrYearIds.push(this.filterdataobj_surrender_year[i]);
            }
        }

        if (this.filterdataobj_riderfee[0]) {
            riderFeeId.push(this.filterdataobj_riderfee[0]);
        }

        if (this.filterdataobj_strategyfee[0]) {
            strategyFeeId.push(this.filterdataobj_strategyfee[0]);
        }


        let chipobj = {
            filled: true,
            filterdataobj_group_name: this.filterdataobj_group_name,
            filterdataobj_groupid: groupIds,

            SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
            SelectFilter_AMbestid: SelectFilter_AMbest,

            filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
            filterdataobj_surrender_yearid: surrYearIds,

            filterdataobj_products_name: this.filterdataobj_products_name,
            filterdataobj_productsid: productIds,

            filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name,
            filterdataobj_strategyfeeid: strategyFeeId,

            filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
            filterdataobj_riderfeeid: riderFeeId,

            gender: this.topbarForm.get('gender')?.value,
            age: this.topbarForm.get('age')?.value,
            // acalendar: this.topbarForm.get('gender')?.value,
            pouseage: this.topbarForm.get('pouseage')?.value,
            // scalendar: this.topbarForm.get('gender')?.value,
            state: this.topbarForm.get('illinois')?.value,
            Contribution: this.topbarForm.get('Contribution')?.value,
            income: this.topbarForm.get('income')?.value,
            amount: this.topbarForm.get('amount')?.value,
            periode: this.topbarForm.get('periode')?.value,
            diffperiode: this.topbarForm.get('diffperiode')?.value,
        }
        let savedsearchdata = JSON.stringify(chipobj);

        let savedsearchobj = {
            id: this.filterEdit.source._value[0].id,
            userId: this.filterEdit.source._value[0].userId,
            searchString: savedsearchdata,
            lastUpdatedDate: this.illEffectDate
        }
        //save serach insert
        this.benefitcalculatorService.putSaveSearch(savedsearchobj).subscribe((res: any) => {
        },
            err => {

            })
        // this.set('saveSearch', chipobj)
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'success', detail: 'Update history Successfully !!' });
        this.saveSeavch = false;
        this.router.navigate(['/savedsearch']);
        let viewdata = [''];
        this.benefitcalculatorService.setsavedData(viewdata);
    }
    set(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
    remove(key: string): void {
        sessionStorage.removeItem(key);
    }
    saveCancel() {
        this.saveSeavch = false;
    }

    cancelCustomsearch() {
        this.createShelfPopup = false
        if (this.IsCustomShelf) {
            this.IsCustomShelf = !this.IsCustomShelf
        }
    }
    sidebarOpen() {
        this.sideMenuShow = !this.sideMenuShow
    }
    onAddchip(e: any) {
    }
    closeIllustration() {
        this.illustration = false;
    }
    closeProductProfile() {
        this.productProfile = false;
    }
    getAllProducsAndCustomShelf() {
        this.benefitcalculatorService.getProducsCustomShelf().subscribe((res: any) => {
            this.customProductList = res ? res : [];
            let mainArray = [];
            let datalist = res;
            let companyid = [];
            for (let i = 0; i < datalist.length; i++) {
                const objcompany = {
                    companyid: datalist[i].companyid,
                    companyname: datalist[i].companyName,
                }; 0
                companyid.push(objcompany);
            }

            let unique = [
                ...new Map(
                    companyid.map((companyid) => [
                        companyid['companyid'],
                        companyid,
                    ])
                ).values(),
            ];
            unique = unique.sort(function (objdata, objdatatwo) {
                if (objdata.companyname < objdatatwo.companyname) {
                    return -1;
                } else if (objdata.companyname > objdatatwo.companyname) {
                    return 1;
                } else {
                    return 0;
                }
            });

            for (let i = 0; i < unique.length; i++) {
                const objdatalist = datalist.filter(
                    (x: any) => x.companyid === unique[i].companyid
                );
                const childrens = [];
                for (let j = 0; j < objdatalist.length; j++) {
                    // var chaildobj1 = {
                    //     key: objdatalist[j].productid,
                    //     label: (objdatalist[j].name).trim(),
                    //     data: (objdatalist[j].name).trim(),
                    // };
                    let chaildobj1 = {
                        key: objdatalist[j].productid,
                        label: (objdatalist[j].name).trim() + ' ' + '(' + objdatalist[j].totalProducts + ')',
                        data: (objdatalist[j].name).trim(),
                        total: objdatalist[j].total,
                        type: 'product',
                        isShelfProduct: this.userShelfProductIds.indexOf(objdatalist[j].productid) > -1
                    };
                    childrens.push(chaildobj1);
                }

                const obj1 = {
                    children: childrens,
                    data: unique[i].companyname,
                    key: unique[i].companyid,
                    label: unique[i].companyname,
                };
                mainArray.push(obj1);
            }
            this.customShelfMasterData = JSON.parse(JSON.stringify(mainArray));
        });
        this.benefitcalculatorService.getUserCustomShelfs(this.loginUserData.id).subscribe((res: any) => {
            if (res.data.length > 0) {
                this.custoShelfShow = true;
                for (let item of res.data) {
                    item.shelfName = item.shelfName ? item.shelfName : 'Custom Shelf';
                    const index = this.userCustomShelfs.findIndex((x: any) => x.shelfName == item.shelfName);
                    if (index > -1) {
                        this.userCustomShelfs[index].items.push(item);
                        if (!this.userCustomShelfs[index].allCompanies.includes(item.companyid)) {
                            this.userCustomShelfs[index].allCompanies.push(item.companyid);
                        }
                    } else {
                        this.userCustomShelfs.push({
                            shelfName: item.shelfName ? item.shelfName : 'Custom Shelf',
                            items: [item],
                            allCompanies: [item.companyid]
                        })
                    }
                }
                this.userCustomShelfs.unshift({ shelfName: 'Select', items: [] });
            }
        });
    }

    applyCustomShelf(customShelfProducts = []) {
        this.onCustomShelf(customShelfProducts);
    }

    onCustomShelf(customShelfProducts: any) {
        this.filterCustomself = [];
        this.filteredCustomshelfs = [];
        this.customProductList.forEach((customProduct: any) => {
            customShelfProducts.forEach((resProduct: any) => {
                if (customProduct.productid === resProduct.productId) {
                    this.filterCustomself.push({
                        productid: customProduct.productid,
                    });
                }
            });
        });
        this.filterCustomself.forEach((filteredProduct: any) => {
            this.filteredCustomshelfs.push(filteredProduct.productid);
        });
        if (this.filteredCustomshelfs.length > 0) {
            this.filtredCustomSelfObj = JSON.parse(JSON.stringify(this.filterdataobj_products));
            if (this.filtredCustomSelfObj && this.filtredCustomSelfObj.length > 0) {
                this.filterdataobj_products = [...new Set([...this.filtredCustomSelfObj, ...this.filteredCustomshelfs])];
            } else {
                this.filterdataobj_products = JSON.parse(JSON.stringify(this.filteredCustomshelfs));
            }
            this.resetDataViewItems();
            this.getFilterSideBar();
        } else {
            const sidebarObject = sessionStorage.getItem('sidebarObject');
            if (sidebarObject) {
                const sidebarData = JSON.parse(sidebarObject);
                sidebarData.productId = this.filtredCustomSelfObj;
                this.resetDataViewItems();
                this.getFilterSideBarInit(JSON.stringify(sidebarData));
            }
        }
    }

    resetDataViewItems() {
        this.isLoader = true;
        this.griddata = [];
        this.filteredArray = [];
    }

    onApprovedProducts(event: any) {
        this.resetDataViewItems();
        let sidebarObject: any = (sessionStorage.getItem('sidebarObject'));
        if (sidebarObject) {
            this.pageNumber = 1;
            this.getFilterSideBarInit(sidebarObject);
        } else {
            this.getFilterSideBar();
        }
    }

    createCustomNew() {
        this.router.navigate(['/customshelf'])
    }

    get searchDataFormControls(): any {
        return this.searchDataForm['controls'];
    }

    public noWhitespaceValidator(control: FormControl) {
        return (control.value || '').trim().length ? null : { 'whitespace': true };
    }


    onScroll(event: any) {
        const element = event.target;
        const threshold = 20; // Adjust the threshold as needed
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + threshold && !this.isLoader &&
            this.filteredArray.length < this.totalMatches) {
            this.loadMoreProductRiders();
        }
    }

    loadMoreProductRiders() {
        this.pageNumber = this.pageNumber + 1;
        let sidebarObject: any = (sessionStorage.getItem('sidebarObject'));
        this.getFilterSideBarInit(sidebarObject);
    }

    setProductList(res: any) {
        const sidebarObject: any = (sessionStorage.getItem('sidebarObject'));
        const sidebarObj = JSON.parse(sidebarObject);
        if (res.productRiders.length > 0) {
            res.productRiders.map((data: any) => {
                let rollUpdata = this.calculateRollUp(data);
                this.griddata.push(
                    {
                        logo1: data.logo1 != null ? environment.domain + data.logo1 : this.altImg,
                        logo2: data.logo2 != null ? environment.domain + data.logo2 : this.altImg,
                        gmwbId: data.gmwbId,
                        productid: data.productid,
                        productName: data.productName,
                        companyid: data.companyid,
                        companyName: data.companyName,
                        riderName: data.riderName,
                        minAge: data.minAge,
                        maxAge: data.maxAge,
                        riderFee: data.riderFee,
                        rollupPer: data.rollupPer,
                        withdrawalPer: data.withdrawalPer,
                        riderFeeJoint: data.riderFeeJoint,
                        rollupPerJoint: data.rollupPerJoint,
                        withdrawalPerJoint: data.withdrawalPerJoint,
                        maxRollupYears: data.rollupYears,
                        rollupYears: data.rollupYears,
                        isCaseAOrC: data.isCaseAOrC,
                        ageBand1: data.ageBand1,
                        ageBand2: data.ageBand2,
                        purchasePayBonus: data.purchasePayBonus,
                        internalName: data.internalName,
                        bonusPercentage: data.bonusPercentage,
                        bonusName: data.bonusName,
                        factorSingle: data.factorSingle,
                        factorJoint: data.factorJoint,
                        premiumBonus: data.premiumBonus,
                        id: data.id,
                        otherVariable: data.otherVariable,
                        bbBonus: data.bbBonus,
                        groupId: data.groupId,
                        groupName: data.groupName,
                        bestid: data.bestid,
                        surryr: data.surryr,
                        strategyFee: data.strategyFee,
                        gender: data.gender,
                        bonusCaseId: data.bonusCaseId,
                        bonusCaseName: data.bonusCaseName,
                        availStateException: data.availStateException ? data.availStateException : "",
                        exceptions: data.exceptions,
                        rollupIsSimpleComp: data.rollupIsSimpleComp,
                        riderFeedata: data.riderFee, //this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.riderFeeJoint : data.riderFee,
                        chargeBasisName: data.chargeBasisName,
                        highAnnivValStepup: data.highAnnivValStepup,
                        anualIncom: this.getanuuleIncome(data),
                        IncomePeriod: this.topbarForm.get('periode')?.value ? 'Estimated Annually' : 'Estimated Monthly',
                        rollUp: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.rollupPerJoint : data.rollupPer,
                        withdrawal: this.topbarForm.get('pouseage')?.value && this.topbarForm.get('pouseage')?.value != 0 ? data.withdrawalPerJoint : data.withdrawalPer,
                        gmwbAvailStateExceptions: data.gmwbAvailStateExceptions,
                        isChecked: false,
                        benifitBaseType: data.benifitBaseType,
                        isCollapsed: false,
                        waitingTerm: data.waitingTerm,
                        waitingPeriod: data.waitingPeriod,
                        chargeFreqDeductedWhen: data.chargeFreqDeductedWhen,
                        rollUpdata: rollUpdata,
                        marketingName: data.marketingName,
                        isShelfProduct: this.userShelfProductIds.indexOf(data.productid) > -1
                    }
                )
            })
        }
        this.filteredArray = JSON.parse(JSON.stringify(this.griddata));
        if (this.filteredArray.length > 8) {
            this.ispagination = true
        } else {
            this.ispagination = false
        }
        this.totalMatches = res.ridersCount;
        setTimeout(() => {
            this.scrollService.scrollToElementById(`btn_load_more`);
        }, 1000);

        if (this.groupfilterFlag != "Groups") {
            this.groups = res.groups;
            if (sidebarObj.groupId.length == 0) {
                this.selectedGroups = [];
            }
            else {
                this.selectedGroups = res.groups;
            }
        }
        else {
            if (sidebarObj.groupId.length == 0) {
                this.selectedGroups = [];
            }
            else {
                this.selectedGroups = res.groups;
            }
        }
        // console.log('selectedGroups', res.groups);
        if (this.productfilterFlag != "Product") {
            var mainArray = [];
            var datalist = res.products;
            var companyid = [];
            for (let i = 0; i < datalist.length; i++) {
                var objcompany = {
                    companyid: datalist[i].companyid,
                    companyname: datalist[i].companyName,
                };
                companyid.push(objcompany);

            }

            var unique = [
                ...new Map(
                    companyid.map((companyid) => [
                        companyid['companyid'],
                        companyid,
                    ])
                ).values(),
            ];
            unique = unique.sort(function (objdata, objdatatwo) {
                if (objdata.companyname < objdatatwo.companyname) {
                    return -1;
                } else if (objdata.companyname > objdatatwo.companyname) {
                    return 1;
                } else {
                    return 0;
                }
            });
            const userShelfProductIds = this.loginUserData.userSelfProductListIndex.map((x: any) => x.productid);
            for (let i = 0; i < unique.length; i++) {
                let objdatalist = datalist.filter(
                    (x: any) => x.companyid === unique[i].companyid
                );
                let childrens = [];
                for (let j = 0; j < objdatalist.length; j++) {
                    let chaildobj1 = {
                        key: objdatalist[j].productid,
                        label: (objdatalist[j].name).trim() + ' ' + '(' + objdatalist[j].totalProducts + ')',
                        data: (objdatalist[j].name).trim(),
                        total: objdatalist[j].total,
                        type: 'product',
                        isShelfProduct: userShelfProductIds.indexOf(objdatalist[j].productid) > -1
                    };
                    childrens.push(chaildobj1);
                }

                let obj1 = {
                    children: childrens,
                    data: unique[i].companyname,
                    key: unique[i].companyid,
                    label: unique[i].companyname,
                };
                mainArray.push(obj1);

            }
            let allProducts: any = sessionStorage.getItem('allProducts');
            if (!allProducts) {
                sessionStorage.setItem('allProducts', JSON.stringify(mainArray));
                this.benefitcalculatorService.allProducts = JSON.parse(JSON.stringify(mainArray));
                this.productlist = mainArray;
            }
        }
        if (this.amRatingFlag != "amBests") {
            this.amBestRating = res.amBests;
            if (sidebarObj.amBestRating.length == 0) {
                this.selectedAMBestrating = [];
            }
            else {
                this.selectedAMBestrating = res.amBests;
            }
        }
        if (this.riderFlag != 'riderFee') {
            this.riderFee = [];
            this.riderFeeData = [];
            this.riderFee.push(
                {
                    minRiderFee: res.riderFees.minRiderFee,
                    maxRiderFee: res.riderFees.maxRiderFee
                }
            )
            for (let i = 0; i < this.riderFee.length; i++) {

                for (this.riderFee[i].minRiderFee; this.riderFee[i].minRiderFee <= this.riderFee[i].maxRiderFee; this.riderFee[i].minRiderFee += 0.5) {
                    this.riderFeeData.push({ riderFee: this.riderFee[i].minRiderFee });
                }
                this.riderFeeData.push({ riderFee: this.riderFee[i].minRiderFee });
            }
        }
        if (this.surrFlag != "surrYrs") {
            this.surrenderYear = res.surrYrs;
            if (sidebarObj.surrYear.length == 0) {
                this.selectedSuryear = [];
            }
            else {
                this.selectedSuryear = res.surrYrs;
            }
        }
        if (this.strategyFlag != 'strategyFees') {
            var strategyFeedata = res.strategyFees;
            this.strategyFee = [];
            for (let i = 0; i < strategyFeedata.length; i++) {
                this.strategyFee.push({ strategyFee: (strategyFeedata[i].strategyFee * 100) });
            }
        }
        if (this.filteredArray.length == 0) {
            this.notFount = true;
        }
        if (this.filteredArray.length > 8) {
            this.ispagination = true
        } else {
            this.ispagination = false
        }
    }

    expandCard(product: any) {
        // (document.getElementById(`collapse_${product.id}`) as HTMLElement).classList.toggle("show");
        // (document.getElementById(`product_${product.id}`) as HTMLElement).classList.toggle("card-collapsed");
        // if(!(document.getElementById(`collapse_${product.id}`) as HTMLElement).classList.contains('show')){
        //     // product.isCollapsed = false;
        // }else{
        //     this.currentProduct = product;
        //     this.showProductDialog = true;
        //     // product.isCollapsed = true;
        // }
        this.currentProduct = product;
        this.showProductDialog = true;
    }


    getBonusBasis(bonusPercentaseBaseId: number | null) {
        let bonusType = '';
        switch (bonusPercentaseBaseId) {
            case BonusTypeId.FixedAccountValue:
                bonusType = BonusType.FixedAccountValue;
                break;
            case BonusTypeId.ContractValue:
                bonusType = BonusType.ContractValue;
                break;
            case BonusTypeId.Others:
                bonusType = BonusType.Others;
                break;
            case BonusTypeId.PurchasePayments:
                bonusType = BonusType.PurchasePayments;
                break;
            case BonusTypeId.BenefitBase:
                bonusType = BonusType.BenefitBase;
                break;
            case BonusTypeId.InterestBonus:
                bonusType = BonusType.InterestBonus;
                break;
            case BonusTypeId.AccumulationValue:
                bonusType = BonusType.AccumulationValue;
                break;
            default:
                break;
        }
        return bonusType;
    }

    resetProductList(res: any) {
        this.filteredArray = [];
        this.griddata = [];
        this.pageNumber = 1;
        this.isLoader = false;
        this.setProductList(res);
        // this.resetSideBarObject();
    }



    resetSideBarObject() {
        const sidebarObj1 = sessionStorage.getItem('sidebarObject');
        if (sidebarObj1) {
            let obj = JSON.parse(sidebarObj1);
            this.filterdataobj_group = obj.groupId != null ? obj.groupId : [];
            this.filterdataobj_products = obj.productId != null ? obj.productId : [];
            this.SelectFilter_AMbest = obj.amBestRating != null ? obj.amBestRating : [];
            this.filterdataobj_surrender_year = obj.surrYear != null ? obj.surrYear : [];
            this.filterdataobj_riderfee = obj.riderFee != null ? obj.groupId : null;
            this.filterdataobj_strategyfee = obj.strategyFee != null ? obj.groupId : null;
        }
    }

    showAdvanceFilterSpinner() {
        this.isLoader = true;
        this.filteredArray = 0;
    }
}