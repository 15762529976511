import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { TreeModule } from 'primeng/tree';
import {InputNumberModule} from 'primeng/inputnumber';
import { CalendarModule } from "primeng/calendar";
import {SliderModule} from 'primeng/slider';
import {ListboxModule} from 'primeng/listbox';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import {ProgressSpinnerModule} from 'primeng/progressspinner'
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {ImageModule} from 'primeng/image';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { EllipsisModule } from 'ngx-ellipsis';
import { DataViewModule } from 'primeng/dataview';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { VirtualScrollerModule } from 'primeng/virtualscroller';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    TreeModule,
    InputNumberModule,
    CalendarModule,
    DropdownModule,
    SliderModule,
    ListboxModule,
    DropdownModule,
    ButtonModule,
    CommonModule,
    ChartModule,
    MenuModule,
    TableModule,
    StyleClassModule,
    PanelMenuModule,
    ProgressSpinnerModule,
    ChipModule,
    DialogModule,
    CheckboxModule,
    ImageModule,
    FileUploadModule,
    ToastModule,
    EllipsisModule,
    DataViewModule,
    MultiSelectModule,
    SelectButtonModule,
    ToggleButtonModule,
    VirtualScrollerModule,
    ProgressSpinnerModule
  ]
})
export class BeaconSharedModule { }
