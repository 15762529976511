import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { GridData } from '../../models/customer';
import { BenefitcalculatorService } from '../../service/benefitcalculator.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUpdateCustomShelfComponent } from './add-update-custom-shelf/add-update-custom-shelf.component';
import { ViewCustomShelfComponent } from './view-custom-shelf/view-custom-shelf.component';

@Component({
  selector: 'app-custom-shelf',
  templateUrl: './custom-shelf.component.html',
  styleUrls: ['./custom-shelf.component.scss'],
  providers: [MessageService]
})
export class CustomShelfComponent implements OnInit {

  useritems!: MenuItem[];
  selectedcustomcreateName: any[] = []
  userlogo: any;
  username: any;
  setting: boolean = true;
  showeShelf: boolean = false
  selectSaveshelf: any;
  toggleSaveShelf: boolean = true;
  mainItemData: any[] = [];
  isLoader: boolean = false;
  childData: any[] = [];
  customSelfData: any[] = [];

  createShelf: boolean = false;
  savedShelf: boolean = false;
  updateEnable: boolean = false;
  selectedcustomcreate: any[] = [];
  deleteShelf: boolean = false;
  loginUserData: any;
  savdeUpdateCustomData: any[] = [];


  createCustomForm = this.fb.group({
    selectedcustomcreate: ['']
  })
  creatCustomShelf: any[] = [];
  savedCustomData: any[] = [];
  companyLength: number = 0;
  userCustomerSelfs: any[] = [];
  deleteUserShelfName!: string;

  constructor(public layoutService: LayoutService,
    private router: Router,
    public dialogService: DialogService,
    private benefitcalculatorService: BenefitcalculatorService,
    private fb: FormBuilder, private messageService: MessageService) {
    let test = JSON.parse(sessionStorage.getItem('logInSession') || '');
    let logindata = this.benefitcalculatorService.decryptUsingAES256(test);
    this.loginUserData = JSON.parse(logindata);
  }

  ngOnInit(): void {

    this.userlogo = '';
    this.username = this.benefitcalculatorService.get('UserName')[0];
    this.isLoader = true;

    this.getAllProducsCustomShelf();
    setTimeout(() => {
      this.getSavedCustomShelfs();
    }, 1500);

    this.selectSaveshelf = [
      { name: 'Select', value: 'select' },
      { name: 'Select1', value: 'M' },
      { name: 'Select2', value: 'F' }
    ];

    this.useritems = [
      {
        label: 'Account Settings',
        icon: 'pi pi-user-edit',
        command: () => {
          this.router.navigate(['/profilesetting']);
        }
      },
      {
        label: 'Default Settings',
        icon: 'pi pi-cog',
        command: () => {
          this.router.navigate(['/adminsystem']);
        }
      },
      {
        label: 'My Saved Searches',
        icon: 'pi pi-save',
        command: () => {
          this.router.navigate(['/savedsearch']);
        }
      },
      {
        label: 'My Custom Shelfs',
        icon: 'pi pi-book',
        command: () => {
          this.router.navigate(['/adminsystem']);
        }
      },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => {
          this.onLogout();
        }
      }
    ];
  }


  onLogout() {
    // sessionStorage.removeItem('Token');
    // sessionStorage.removeItem('UserName');
    // sessionStorage.removeItem('userId');
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }
  defaultContent() {
    this.setting = true
    this.router.navigate(['/adminsystem']);
  }
  profileContent() {
    this.setting = false;
    this.router.navigate(['profilesetting'])
  }
  Oncancel() {
    this.updateEnable = false
  }
  savedSearch() {
    this.setting = false;
    this.router.navigate(['/savedsearch']);
  }
  customShelf() {
    this.router.navigate(['/customshelf'])
  }
  myFavourites() {
    this.router.navigate(['/myfavourites'])
  }
  closeCustomShelf() {
    this.router.navigate(['/home'])
  }

  getAllProducsCustomShelf() {
    this.benefitcalculatorService.getProducsCustomShelf().subscribe((res: any) => {
      let mainArray = [];
      let datalist = res;
      let companyid = [];
      for (let i = 0; i < datalist.length; i++) {
        const objcompany = {
          companyid: datalist[i].companyid,
          companyname: datalist[i].companyName,
        }; 0
        companyid.push(objcompany);
      }

      let unique = [
        ...new Map(
          companyid.map((companyid) => [
            companyid['companyid'],
            companyid,
          ])
        ).values(),
      ];
      unique = unique.sort(function (objdata, objdatatwo) {
        if (objdata.companyname < objdatatwo.companyname) {
          return -1;
        } else if (objdata.companyname > objdatatwo.companyname) {
          return 1;
        } else {
          return 0;
        }
      });

      for (let i = 0; i < unique.length; i++) {
        const objdatalist = datalist.filter(
          (x: any) => x.companyid === unique[i].companyid
        );
        const childrens = [];
        for (let j = 0; j < objdatalist.length; j++) {
          var chaildobj1 = {
            key: objdatalist[j].productid,
            label: (objdatalist[j].name).trim(),
            data: (objdatalist[j].name).trim(),
          };
          childrens.push(chaildobj1);
        }

        const obj1 = {
          children: childrens,
          data: unique[i].companyname,
          key: unique[i].companyid,
          label: unique[i].companyname,
        };
        mainArray.push(obj1);
      }
      this.mainItemData = mainArray;
    });
  }

  onProductSelect(event: any) {
    if (event.node.children === undefined) {
      let { node } = event;
      this.selectedcustomcreate.push(node.key);
      this.selectedcustomcreateName = [];
      this.selectedcustomcreateName.push({
        key: node.key,
        label: node.label,
        data: node.data
      });
    }
    else {
      const { node: { children } } = event;
      if (Array.isArray(children) && children.length) {
        for (let val of children) {
          this.selectedcustomcreate.filter((el: any, index: any) => {
            if (el === val.key) {
              this.selectedcustomcreate.splice(index, 1);
            }
          })
          this.selectedcustomcreate.push(val.key)

          this.selectedcustomcreateName.filter((el: any, index: any) => {
            if (el.key === val.key) {
              this.selectedcustomcreateName.splice(index, 1);
            }
          })
          this.selectedcustomcreateName.push({
            key: val.key,
            label: val.label,
            data: val.data
          });
        }
      }
    }
  };

  onProductRemove(event: any) {
    if (event.node.children === undefined) {
      const { node } = event;
      let element: any[] = [];
      if (node) element = this.selectedcustomcreate.filter((val: any) => {
        return node.key === val
      })
      element.filter((res: any) => {
        this.selectedcustomcreate.filter((data: any, index: any) => {
          if (res === data) {
            this.selectedcustomcreate.splice(index, 1);

          }
        })
      })
      element.filter((res: any) => {
        this.selectedcustomcreateName.filter((data: any, index: any) => {
          if (res === data.key) {
            this.selectedcustomcreateName.splice(index, 1)

          }
        })
      })
    }
    else {

      const { node: { children } } = event;
      let element: any[] = [];
      if (children) element = this.selectedcustomcreate.filter((val: any) => {
        return children.some((el: any) => el.key === val)
      })

      element.filter((res: any) => {
        this.selectedcustomcreate.filter((data: any, index: any) => {
          if (res === data) {
            this.selectedcustomcreate.splice(index, 1);
          }
        })
      })
      element.filter((res: any) => {
        this.selectedcustomcreateName.filter((data: any, index: any) => {
          if (res === data.key) {
            this.selectedcustomcreateName.splice(index, 1)
          }
        })
      })
    }
  }

  getSavedCustomShelfs() {
    this.createShelf = true;
    this.benefitcalculatorService.getUserCustomShelfs(this.loginUserData.id).subscribe((res: any) => {
      if (res.data.length > 0) {
        this.savedShelf = true;
        this.createShelf = false;
        for (let item of res.data) {
          item.shelfName = item.shelfName ? item.shelfName : 'Custom Shelf';
          const index = this.userCustomerSelfs.findIndex((x: any) => x.shelfName == item.shelfName);
          if (index > -1) {
            this.userCustomerSelfs[index].items.push(item);
            if (!this.userCustomerSelfs[index].allCompanies.includes(item.companyid)) {
              this.userCustomerSelfs[index].allCompanies.push(item.companyid);
            }
          } else {
            this.userCustomerSelfs.push({
              shelfName: item.shelfName ? item.shelfName : 'Custom Shelf',
              items: [item],
              allCompanies: [item.companyid]
            })
          }
        }
      } else {
        this.createShelf = true;
      }
    });
  }

  onDelete(customSelf: any) {
    this.deleteShelf = true;
    this.deleteUserShelfName = customSelf.shelfName;
  }

  viewCustomeShelf(customShelf: any) {
    let children: any = []
    for (let mainData of this.mainItemData) {
      for (let child of mainData.children) {
        for (let data of customShelf.items) {
          if (data.productId == child.key) {
            if (data.companyid == mainData.key) {
              children.push({
                label: data.productName,
                data: data.productName,
                key: data.productId
              })
            }
          }
        }
      }
    }
    const ref: any = this.dialogService.open(ViewCustomShelfComponent, {
      header: 'View Custom Shelf',
      width: '50%',
      contentStyle: { "overflow-y": "auto" },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        customShelfData: children,
        shelfName: customShelf.shelfName,
      }
    });
    // ref.onClose.subscribe((res: boolean) => {
    //   if (res === true) {
    //     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Custom Shelf has updated successfully' });
    //     this.getSavedCustomShelfs();
    //   }
    // });
  }

  onCancel() {
    this.deleteShelf = false;
  }

  deleteUserShelf() {
    this.benefitcalculatorService.deleteCustomShelf(this.loginUserData.id, this.deleteUserShelfName).subscribe((res: any) => {
      if (res.returnStatus === true) {
        const index = this.userCustomerSelfs.findIndex(x => x.shelfName == this.deleteUserShelfName);
        this.userCustomerSelfs.splice(index, 1);
        this.deleteShelf = false;
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Delete', detail: 'Custom Shelf Deleted Successfully !!' });
      }
    })
  }

  addCustomSelf() {
    const ref: any = this.dialogService.open(AddUpdateCustomShelfComponent, {
      header: 'Add Custom Self',
      width: '70%',
      contentStyle: { "overflow-y": "auto" },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        allProducts: this.mainItemData
      }
    });
    ref.onClose.subscribe((res: any) => {

    });
    ref.onClose.subscribe((res: boolean) => {
      if (res === true) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Custom Shelf has been successfully' });
        this.getSavedCustomShelfs();
      }
    });
  }

  updateCustomSelf(customShelf: any) {
    let children: any = []
    for (let mainData of this.mainItemData) {
      for (let child of mainData.children) {
        for (let data of customShelf.items) {
          if (data.productId == child.key) {
            if (data.companyid == mainData.key) {
              children.push({
                label: data.productName,
                data: data.productName,
                key: data.productId
              })
            }
          }
        }
      }
    }
    const ref: any = this.dialogService.open(AddUpdateCustomShelfComponent, {
      header: 'Update Custom Self',
      width: '70%',
      contentStyle: { "overflow-y": "auto" },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        allProducts: this.mainItemData,
        seletedItems: children,
        shelfName: customShelf.shelfName,
        isEdit: true
      }
    });
    ref.onClose.subscribe((res: boolean) => {
      if (res === true) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Custom Shelf has updated successfully' });
        this.getSavedCustomShelfs();
      }
    });
  }

  ngOnDestroy() {
  }
}
