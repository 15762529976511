import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './modules/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
            { path: 'home', component: AppLayoutComponent, canActivate: [AuthGuard] },
            { path: 'uikit', loadChildren: () => import('./modules/uikit/uikit.module').then(m => m.UIkitModule), canActivate: [AuthGuard] },
            { path: 'utilities', loadChildren: () => import('./modules/utilities/utilities.module').then(m => m.UtilitiesModule), canActivate: [AuthGuard] },
            { path: 'documentation', loadChildren: () => import('./modules/documentation/documentation.module').then(m => m.DocumentationModule), canActivate: [AuthGuard] },
            { path: 'blocks', loadChildren: () => import('./modules/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule), canActivate: [AuthGuard] },
            { path: 'pages', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
            { path: 'landing', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule), canActivate: [AuthGuard] },
            { path: 'illustration', loadChildren: () => import('./modules/illustration/illustration.module').then(m => m.IllustrationModule), canActivate: [AuthGuard] },
            { path: 'logoupload', loadChildren: () => import('./modules/logo-upload/logo-upload.module').then(m => m.LogoUploadModule), canActivate: [AuthGuard] },
            { path: 'adminsystem', loadChildren: () => import('./modules/admin-system/admin-system.module').then(m => m.AdminSystemModule), canActivate: [AuthGuard] },
            { path: 'savedsearch', loadChildren: () => import('./modules/saved-search/saved-search.module').then(m => m.SavedSearchModule), canActivate: [AuthGuard] },
            { path: 'profilesetting', loadChildren: () => import('./modules/profile-setting/profile-setting.module').then(m => m.ProfileSettingModule), canActivate: [AuthGuard] },
            { path: 'customshelf', loadChildren: () => import('./modules/custom-shelf/custom-shelf.module').then(m => m.CustomShelfModule), canActivate: [AuthGuard] },
            { path: 'myfavourites', loadChildren: () => import('./modules/myfavourites/myfavourites.module').then(m => m.MyfavouritesModule), canActivate: [AuthGuard] },
            { path: 'notfound', component: NotfoundComponent },
            { path: 'products', loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule), canActivate: [AuthGuard] },
            { path: 'product-details', loadChildren: () => import('./modules/product-profile/product-profile.module').then(m => m.ProductProfileModule), canActivate: [AuthGuard] },
            { path: 'indexed-product-comparison', loadChildren: () => import('./modules/indexed-product-comparison/indexed-product-comparison.module').then(m => m.ProductComparisonModule), canActivate: [AuthGuard] },
            { path: '**', redirectTo: '/notfound' },
        ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload'
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
