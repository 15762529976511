import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Companies } from '../../models/filter';
import { BenefitcalculatorService } from '../../service/benefitcalculator.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-logo-upload',
    templateUrl: './logo-upload.component.html',
    styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent implements OnInit {
    uploadImage: boolean = false;
    // selectedCountry: string = '';
    selectedCompany: string = '';

    isImg1: boolean = false;
    isImg2: boolean = false;
    uploadedFiles: any[] = [];
    logoName1: string = '';
    urlobj: any[] = [];
    companies: Companies[] = [];
    companyId: number = 0;
    companyName: string = '';


    uploadedFiles1: any[] = [];
    logoName2: string = '';
    loogoo: any;
    loogoo2: any;
    // file: File = [];
    urlobj1: any[] = [];
    comp_btn: boolean = true;
    compSub_btn: boolean = true;
    isSubmit: boolean = false;
    companyLogoForm: any;
    imageUrl: any;
    imageUrl2: any;
    logoURL1: any;
    logoURL2: any;
    altImg: string = '../../assets/layout/images/no-picture-available.jpg';

    constructor(private fb: FormBuilder, private benefitcalculatorService: BenefitcalculatorService,
        private route: Router) { }

    ngOnInit(): void {
        this.companyLogoForm = this.fb.group({
            companyName: [''],
            companyLogo: [''],
            companyLogo1: [''],
            // companies:[],

        })

        this.benefitcalculatorService.getAllCompanyData().subscribe((res: any) => {
            this.companies = res;
        })
    }

    backToHome() {
        this.route.navigate(['home']);
    }
    onCompanyChange(e: any) {
        this.logoURL2 = null;
        this.logoURL1 = null;
        this.logoName1 = '';
        this.logoName2 = '';
        this.comp_btn = false;

        this.compSub_btn = false;
        this.companyId = e.value.id;
        this.companyName = e.value.name;
        this.benefitcalculatorService.getCompanyDatabyId(e.value.id).subscribe((res: any) => {
            this.imageUrl = res.imgLogo1
            this.imageUrl2 = res.imgLogo2
            this.logoURL1 = res.logo1 != null ? environment.domain + res.logo1 : this.altImg
            this.logoURL2 = res.logo2 != null ? environment.domain + res.logo2 : this.altImg //environment.domain+res.logo2
            if (this.logoURL1) {
                this.isImg1 = true;
            }
            if (this.logoURL2) {
                this.isImg2 = true;
            }

            if (res.imgLogo1 != null) {
                for (let file of res.imgLogo1) {
                    this.uploadedFiles.push(file);
                }

                for (let file of res.imgLogo1) {
                    let obj = { url: file.objectURL.changingThisBreaksApplicationSecurity }
                    this.urlobj.push(obj);
                }
            }
            if (res.imgLogo2 != null) {
                for (let file of res.imgLogo2) {
                    this.uploadedFiles1.push(file);
                }

                for (let file of res.imgLogo2) {
                    let obj = { url: file.objectURL.changingThisBreaksApplicationSecurity }
                    this.urlobj1.push(obj);
                }
            }
        })
        // console.log( this.imageUrl ,this.imageUrl2,'qwertyuiop');
        // this.comp_btn = false;
    }
    onCompanyClear(e: any) {
        this.comp_btn = true;
        this.compSub_btn = true;
        this.selectedCompany = '';
        this.urlobj = [];
        this.uploadedFiles = [];
        this.urlobj1 = [];
        this.uploadedFiles1 = [];
        this.logoName1 = '';
        this.logoName2 = '';
        this.logoURL1 = '';
        this.logoURL2 = '';
        this.isImg1 = false;
        this.isImg2 = false;
        this.benefitcalculatorService.getAllCompanyData().subscribe((res: any) => {
            this.companies = res;
        })
    }
    showUploadImage() {
        this.benefitcalculatorService.getAllCompanyData().subscribe((res: any) => {
            this.companies = res;
        })
        this.uploadImage = true;
    }

    onUploadLogo(event: any) {

        this.logoName1 = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
        this.logoURL1 = '';
        if (this.logoName1) {
            this.isImg1 = true
        }
        this.loogoo = event.files[0];


        const reader = new FileReader();
        if (event.files && event.files.length) {
            const uploadFiles = event.files[0]; // use this if you want to send imageObject to API
            const uploadLogo = uploadFiles;
            reader.readAsDataURL(uploadFiles);
            // const reader = new FileReader();
            reader.onload = () => {
                this.imageUrl = reader.result as string; // use this when you want to send base64 value of an image to API
                this.companyLogoForm.patchValue({ companyLogo: reader.result });
                this.companyLogoForm.get('companyLogo').updateValueAndValidity();
                // this.clientMasterForm.patchValue({ clientLogo: uploadFiles });
            };
            // reader.readAsDataURL(uploadFiles);
        }
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        for (let file of event.files) {
            let obj = { url: file.objectURL.changingThisBreaksApplicationSecurity }
            this.urlobj.push(obj);
        }
        // this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }
    onRemoveLogo(event: any) {

        //    let index = this.urlobj.findIndex((e: any) => e.url === event[0].objectURL.changingThisBreaksApplicationSecurity);
        //     this.urlobj.splice(index, 1);
        //     this.urlobj = [...this.urlobj];
        this.urlobj = [];
        this.uploadedFiles = [];
        this.companyLogoForm.get('companyLogo')?.patchValue('');
        this.logoName1 = '';
        this.isImg1 = false;
    }

    onUploadLogo1(event: any) {

        this.logoName2 = event.files[0].objectURL.changingThisBreaksApplicationSecurity;
        this.logoURL2 = '';
        if (this.logoName2) {
            this.isImg2 = true;
        }
        this.loogoo2 = event.files[0];

        const reader = new FileReader();
        if (event.files && event.files.length) {
            const uploadFiles = event.files[0]; // use this if you want to send imageObject to API
            const uploadLogo = uploadFiles;
            reader.readAsDataURL(uploadFiles);
            // const reader = new FileReader();
            reader.onload = () => {
                this.imageUrl2 = reader.result as string; // use this when you want to send base64 value of an image to API
                this.companyLogoForm.patchValue({ companyLogo1: reader.result || null });
                this.companyLogoForm.get('companyLogo1').updateValueAndValidity();
                // this.clientMasterForm.patchValue({ clientLogo: uploadFiles });
            };
            // reader.readAsDataURL(uploadFiles);
        }
        for (let file of event.files) {
            this.uploadedFiles1.push(file);
        }

        for (let file of event.files) {
            let obj = { url: file.objectURL.changingThisBreaksApplicationSecurity }
            this.urlobj1.push(obj);
        }
        //this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }

    onRemoveLogo1(event: any) {
        //    let index = this.urlobj1.findIndex((e: any) => e.url === event[0].objectURL.changingThisBreaksApplicationSecurity);
        //     this.urlobj1.splice(index, 1);
        //    this.urlobj1 = [...this.urlobj1];
        this.urlobj1 = [];
        this.uploadedFiles1 = [];
        this.companyLogoForm.get('companyLogo1')?.patchValue('');
        this.logoName2 = '';
        this.isImg2 = false;

    }



    onSubmit() {
        if (this.loogoo != undefined) { this.isSubmit = true; }
        if (this.loogoo2 != undefined) { this.isSubmit = true; }

        const formData1 = new FormData();
        if (this.companyId) {
            formData1.append('Id', this.companyId.toString());
        }
        if (this.companyName) {
            formData1.append('Name', this.companyName);
        }
        if (this.loogoo != undefined) {
            formData1.append('ImgLogo1', this.loogoo, this.loogoo.name);
        }
        if (this.loogoo2 != undefined) {
            formData1.append('ImgLogo2', this.loogoo2, this.loogoo2.name);
        }
        if (this.loogoo != undefined) {
            formData1.append('Logo1', this.loogoo.name);
        }
        if (this.loogoo2 != undefined) {
            formData1.append('Logo2', this.loogoo2.name);
        }
        this.benefitcalculatorService.postLogoData(formData1).subscribe((res: any) => {
            console.log(res, 'res');

            this.isSubmit = false;
        })
    }
    onModelHide() {
        this.selectedCompany = '';
        this.urlobj = [];
        this.uploadedFiles = [];
        this.urlobj1 = [];
        this.uploadedFiles1 = [];
        this.comp_btn = true;
        this.compSub_btn = true;
        this.logoName1 = '';
        this.logoName2 = '';
        this.logoURL1 = '';
        this.logoURL2 = '';
        this.isImg1 = false;
        this.isImg2 = false;

    }







}
