import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-illustration',
  templateUrl: './illustration.component.html',
  styleUrls: ['./illustration.component.scss']
})
export class IllustrationComponent implements OnInit {

  value1?: number;
  property?: string;
  products: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.products = [
      {
        		endofyear: 1,
            age:26,
            purchasepayment: 1000,
            benefitbase: 100000,
            rollup: 7.00,
            annualAvailable: 4345,
            annualPercentage:3.5
        	},
          {
        		endofyear: 2,
            age:38,
            purchasepayment: 1000,
            benefitbase: 100000,
            rollup: 7.00,
            annualAvailable: 4345,
            annualPercentage:3.7
        	},
          {
        		endofyear: 3,
            age:69,
            purchasepayment: 1000,
            benefitbase: 100000,
            rollup: 7.00,
            annualAvailable: 4345,
            annualPercentage:4.5
        	},
          {
        		endofyear: 4,
            age:25,
            purchasepayment: 1000,
            benefitbase: 100000,
            rollup: 7.00,
            annualAvailable: 4345,
            annualPercentage:4.9
        	},
          {
        		endofyear: 5,
            age:25,
            purchasepayment: 1000,
            benefitbase: 100000,
            rollup: 7.00,
            annualAvailable: 4345,
            annualPercentage:6.3
        	},
          {
        		endofyear: 6,
            age:27,
            purchasepayment: 1000,
            benefitbase: 100000,
            rollup: 7.00,
            annualAvailable: 4345,
            annualPercentage:6.5
        	}
    ]
   
  }

}
