import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IllustrationComponent } from './illustration.component';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { IllustrationRoutingModule } from './illustration-routing.module';



@NgModule({
  declarations: [
    IllustrationComponent
  ],
  imports: [
    CommonModule,
    IllustrationRoutingModule,
    FormsModule,
    InputNumberModule,
    InputTextModule,
    TableModule,
  ],
  exports:[
    IllustrationComponent,
  ]
})
export class IllustrationModule { }
