<div class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Tree</h5>
			<p-tree [value]="files1" selectionMode="checkbox" [(selection)]="selectedFiles1"></p-tree>
		</div>
	</div>
	<div class="col-12">
		<div class="card">
			<h5>TreeTable</h5>
			<p-treeTable [value]="files2" [columns]="cols" selectionMode="checkbox" [(selection)]="selectedFiles2">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns">
							{{col.header}}
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
					<tr>
						<td *ngFor="let col of columns; let i = index">
							<p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
							<p-treeTableCheckbox [value]="rowNode" *ngIf="i === 0"></p-treeTableCheckbox>
							{{rowData[col.field]}}
						</td>
					</tr>
				</ng-template>
			</p-treeTable>
		</div>
	</div>
	<div class="col-12">
		<div class="card">
			<h5>Horizontal Tree</h5>
			<p-tree [value]="files3" layout="horizontal" selectionMode="single" [(selection)]="selectedFiles3"></p-tree>
		</div>
	</div>
</div>
