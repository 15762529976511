<!-- <app-header></app-header> -->
<div class="align-items-center sidebar-menu">
    <div class="row row2 m-0 content bg-white shadow-sm rounded-4">
        <div class="col-lg-4 col-xl-3 sidebar">
            <div class="p-3 d-flex justify-content-center">
                <div class="w-100">
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="profileContent()">
                        <span class="sidebar-btn">Account Settings</span>
                    </button><br>
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="defaultContent()">
                        <span class="sidebar-btn">Default Settings</span>
                    </button><br>
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="savedSearch()"
                        [ngClass]="savedSearchTab == true? 'default-btn-focus' : '' ">
                        <span class="sidebar-btn">My Saved Searches</span>
                    </button><br>
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="customShelf()">
                        <span class="sidebar-btn">My Custom Shelfs</span>
                    </button><br>
                    <!-- <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="myFavourites()">
                        <span class="sidebar-btn">My Favoutite</span>
                    </button> -->
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-xl-9 mainsettingdata border-md-start" *ngIf="savedSearchTab == true">
            <!-- <div>
                <h3 class="Default-name">My Saved Searches</h3>
            </div> -->
            <div class="saved-search-settings d-flex">
                <p-button
                    icon="pi pi-arrow-left"
                    (click)="goBack()"
                ></p-button>
                <div>   
                    <h3 class="Default-name">My Saved Searches</h3>
                    <div class="headingdata">
                        <h4>{{saveSearchLength}} Saved Searches</h4>
                    </div>
                </div>
            </div>
            <div *ngIf="showLoader" class="pt-8 text-center">
                <p-progressSpinner  [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"></p-progressSpinner>    
            </div>
            <div class="accordian state" *ngIf="dataview.length > 0 && !showLoader">
                <p-dataView [value]="dataview">
                    <ng-template let-item pTemplate="listItem">
                        <p-accordion class="col-12">
                            <div class="col-12">
                                <div class="row px-0">
                                    <p-header class="d-flex headerItems">
                                        <div class="headerItems">
                                            <h4 class="">{{item.searchtitle}}</h4>
                                        </div>
                                    </p-header>
                                </div>
                                <div class="row px-1">
                                    <div class="col-10 chipData pl-4">
                                        <div class="row">
                                            <div class="getteddata">{{item.saveddata}}</div>
                                        </div>
                                        <div class="row fnlchipdata getteddata p-0" *ngIf="item.advance_filter != ''">
                                           <b> Advanced Filters: </b> <div  [innerHTML]="item.advance_filter"></div>
                                        </div>
                                    </div>
                                    <div class="col-2 pt-0">
                                        <div class="text-end">
                                            <button pButton pRipple type="button" icon="pi pi-eye"
                                                class="p-button-rounded m-1" (click)="filterviewclick(item)"></button>
                                            <button pButton pRipple type="button" icon="pi pi-file-edit"
                                                class="p-button-rounded p-button-secondary m-1"
                                                (click)="editSavedSearch(item)"></button>
                                            <button pButton pRipple type="button" icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger m-1"
                                                (click)="filterdeleteclick(item)"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-accordion>
                    </ng-template>
                </p-dataView>
            </div>
            <div *ngIf="dataview.length == 0 && !showLoader" class="row">
                <div class="col-8">
                    <div class="no-saved-search-data text-center">
                        <img
                            width="42"
                            height="auto"
                            src="../../../../assets/layout/images/Icon.svg"
                        />
                        <p>No saved searches</p>
                    </div>
                </div>
            </div>
            <div class="row ml-6">
                <div class="col-12 d-flex px-0">
                    <div class="savebtn">
                        <p-button label="Create Saved Search" (click)="onNewSavedSearch()">
                        </p-button>
                    </div>
                    <div class="cancelbtn">
                        <p-button label="Cancel" [routerLink]="['/home']" ></p-button>
                    </div>
                </div>
            </div>
            <app-advance-filter-menu *ngIf="showSideMenu" [savedType]="savedType" (hideAdvanceFilter)="hideAdvanceFilterMenu($event)"></app-advance-filter-menu>
        </div>
    </div>
    
</div>
<p-dialog header="New Save Searches" [(visible)]="deleteSearch" [modal]="true"
    [style]="{'width': '508px', 'height': '320px'}" [baseZIndex]="10000">
    <p-header>
        <div class="searchHeading">
            <label>Delete Saved Searches</label>
        </div>
    </p-header>
    <hr class="m-0">
    <div class="deletDialog">
        <label>Are you sure you want to delete this saved search ?</label>
    </div>
    <div class="">
        <div class="save d-flex">
            <div class="canceSearch">
                <button type="button" pButton class="m-2" (click)="saveCancel()">CANCEL</button>
            </div>
            <div class="searchSave">
                <button type="button" pButton class=" p-button-danger m-2 " (click)="deleteSearchclc()">Delete</button>
            </div>
        </div>
    </div>
</p-dialog>