<div class="home---grid-view">
    <div class="base-b-g">
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
        <div class="card">
            <div class="base-b-g">
                <div class="more">
                    <div class="frame-1-5-6">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2">$ 5,150 annually</p>
            <div class="line-9">
                <div class="other-details">
                    <p class="text-3">Min / Max Rider Age</p>
                    <p class="text-4">50-80</p>
                    <p class="text-5">Rider Fee</p>
                    <p class="text-6">1.00%</p>
                    <p class="text-7">Roll-up %</p>
                    <p class="text-8">0%</p>
                    <p class="text-9">Roll-up Period</p>
                    <p class="text-1-0">-</p>
                    <p class="text-1-1">Roll-up Type</p>
                    <p class="text-1-2">-</p>
                    <p class="text-1-3">Rider Bonus</p>
                    <p class="text-1-4">0%</p>
                    <p class="text-1-5">Withdrawal %</p>
                    <p class="text-1-6">5.75%</p>
                    <p class="text-1-7">Premium Bonus</p>
                    <p class="text-1-8">0%</p>
                    <p class="text-1-9">Other Variable</p>
                    <p class="text-2-0">-</p>
                </div>
            </div>

            <div class="line-8">
                <div class="product-rider-details">
                    <p class="text-2-1">Product</p>
                    <p class="text-2-2">Product Name</p>
                    <p class="text-2-3">Rider</p>
                    <p class="text-2-4">Rider Name</p>
                </div>
            </div>

            <div class="line-7">
                <div class="logo">
                    <div class="b-g">
                        <img src="" />
                    </div>
                </div>
            </div>
            <p class="text-2-6">Allianz Life Insurance Company of North America</p>
        </div>
    </div>
    <div class="mid-panel d-flex">
        <div class="search-results">
            <p class="text-2-0-9">Search Result</p>
            <p class="text-2-1-0">99 items found</p>
        </div>
        <div class="tabs">
            <div class="options">
                <div class="op-1">
                    <p class="text-2-1-1">Annually</p>
                </div>
                <div class="op-2">
                    <p class="text-2-1-2">Monthly</p>
                </div>
            </div>
        </div>
        <div class="grid-list-view-tabs">
            <div class="options">
                <div class="op-1">
                    <img src="" />
                    <p class="text-2-1-4">Grid View</p>
                </div>
                <div class="op-2">
                    <img src="" />
                    <p class="text-2-1-6">List View</p>
                </div>
            </div>
        </div>
        <div class="btn---normal">
            <img src="" />
            <p class="text-2-1-8">Compare</p>
        </div>
        <div class="btn---icon">
            <img src="" />
        </div>
    </div>
    <div class="advance-search d-flex">
        <div class="b-g">
            <p class="text-2-2-0">Advance Search</p>
        </div>
        <div class="dropdown">
            <p class="text-2-2-1">Rider Type</p>
            <div class="drop-down">
                <p class="text-2-2-2">Select rider type</p>
                <img src="" />
            </div>
        </div>
        <div class="tabs">
            <p class="text-2-2-4">Amount Type</p>
            <div class="options">
                <div class="op-1">
                    <p class="text-2-2-5">Contribution</p>
                </div>
                <div class="op-2">
                    <p class="text-2-2-6">Income</p>
                </div>
            </div>
        </div>
        <div class="fields">
            <div class="input">
                <img src="" />
                <div class="line-6">
                    <p class="text-2-2-8">Enter amount</p>
                </div>
            </div>
            <p class="text-2-2-9">* Amount</p>
        </div>
        <div class="sliders">
            <p class="text-2-3-0">Deferral Period</p>
            <div class="slider-a-i-o">
                <div class="slider">
                    <div class="base">
                    </div>
                    <div class="progress">
                    </div>
                    <div class="bottom-circle">
                    </div>
                    <div class="top-circle">
                    </div>
                </div>
                <div class="input-box">
                    <div class="frame-1-5-5">
                        <p class="text-2-3-1">36</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="dropdown">
            <p class="text-2-3-2">* Gender</p>
            <div class="drop-down">
                <p class="text-2-3-3">Select gender</p>
                <img src="" />
            </div>
        </div>
        <div class="d-o-b-age">
            <div class="picker">
                <div class="input">
                    <p class="text-2-3-5">Select date</p>
                    <img src="" />
                </div>
                <p class="text-2-3-7">* Date of Birth / Age</p>
            </div>
            <div class="fields">
                <div class="input">
                    <p class="text-2-3-8">Age</p>
                </div>
            </div>
        </div>
        <div class="spouse-age">
            <div class="picker">
                <div class="input">
                    <p class="text-2-3-9">Select date</p>
                    <img src="" />
                </div>
                <p class="text-2-4-1">* Spouse Date of Birth / Age</p>
            </div>
            <div class="fields">
                <div class="input">
                    <p class="text-2-4-2">Age</p>
                </div>
            </div>
        </div>
        <div class="dropdown">
            <p class="text-2-4-3">State</p>
            <div class="drop-down">
                <p class="text-2-4-4">Select state</p>
            </div>
        </div>
    </div>
    <div class="header">
        <div class="rectangle-1">
            <img src="" />
        </div>
        <p class="text-2-4-7">Katie Pena</p>
        <p class="text-2-4-8">User</p>
        <div class="ellipse-3">
            <img src="" />
        </div>
        <div class="ellipse-4">
            <img src="" />
        </div>
    </div>
</div>