<!-- <app-header></app-header> -->
<div class="align-items-center sidebar-menu">
    <div class="row row2 m-0 content bg-white shadow-sm rounded-4">
        <div class="col-md-4 col-xl-3 sidebar">
            <div class="p-3 d-flex justify-content-center">
                <div class="w-100">
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="profileContent()">
                        <span class="sidebar-btn">Account Settings</span></button><br />
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="defaultContent()">
                        <span class="sidebar-btn">Default Settings</span></button><br />
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="savedSearch()">
                        <span class="sidebar-btn">My Saved Searches</span></button><br />
                    <button class="p-link sidebar-menu-button w-100 px-3 px-lg-4" (click)="customShelf()"
                        [ngClass]="toggleSaveShelf == true ? 'default-btn-focus' : ''">
                        <span class="sidebar-btn">My Custom Shelfs</span></button><br />
                </div>
            </div>
        </div>

        <div class="col-md-8 col-xl-9 mainsettingdata border-md-start" *ngIf="toggleSaveShelf == true">
            <div class="download-btn d-flex">
                <p-button icon="pi pi-arrow-left" (onClick)="closeCustomShelf()"></p-button>
                <h3 class="Default-name">My Custom Shelf</h3>
            </div>

            <div class="row">
                <div class="col-lg-8 px-0">
                    <div class="text-end">
                        <a pButton label="Add" class="annuity-menu-btn" (click)="addCustomSelf()"></a>
                    </div>
                </div>
            </div>

            <div *ngIf="createShelf == true" class="row">
                <div class="col-8">
                    <div class="selfNodata text-center">
                        <img width="42" height="auto" src="../../../../assets/layout/images/Icon.svg" />
                        <p>No shelf created</p>
                    </div>
                </div>
            </div>

            <!-- saved data -->
            <div class="row d-grid mt-4 ml-6" *ngIf="savedShelf == true">
                <ng-container *ngFor="let customShelf of userCustomerSelfs">
                    <div class="row">
                        <div class="col-lg-8 px-0">
                            <div class="selfData mb-3 align-items-center d-flex justify-content-between p-4">
                                <div>
                                    <h4>{{ customShelf.shelfName }}</h4>
                                    <p>
                                        {{ customShelf.allCompanies.length }}+ companies & its
                                        products included
                                    </p>
                                </div>
                                <div class="d-inline-flex">
                                    <span class="editIcon me-2">
                                        <button style="border: none" type="button"
                                            (click)="viewCustomeShelf(customShelf)">
                                            <img src="../../../../assets/layout/images/eye-open.svg" />
                                        </button>
                                    </span>
                                    <!-- <span class="editIcon me-2">
                                        <button style="border: none" type="button"
                                            (click)="customEditClick(customShelf)">
                                            <img src="../../../../assets/layout/images/edit.svg" />
                                        </button>
                                    </span> -->
                                    <span class="editIcon me-2">
                                        <button style="border: none" type="button"
                                            (click)="updateCustomSelf(customShelf)">
                                            <img src="../../../../assets/layout/images/edit.svg" />
                                        </button>
                                    </span>
                                    <span class="deleteIcon">
                                        <button style="border: none" type="button" (click)="onDelete(customShelf)">
                                            <img src="../../../../assets/layout/images/delete.svg" />
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<p-dialog header="New Save Searches" [(visible)]="deleteShelf" [modal]="true"
    [style]="{ width: '508px', height: '268px' }" [baseZIndex]="10000">
    <p-header>
        <div class="searchHeading">
            <label>Delete Shelf</label>
        </div>
    </p-header>
    <hr class="m-0" />
    <div class="deletDialog">
        <label>Are you sure you want to delete this shelf?</label>
    </div>
    <div class="">
        <div class="save d-flex">
            <div class="canceSearch">
                <button type="button" pButton class="m-2" (click)="onCancel()">
                    CANCEL
                </button>
            </div>
            <div class="searchSave">
                <button type="button" pButton class="p-button-danger m-2" (click)="deleteUserShelf()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</p-dialog>