import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, Renderer2, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService, TreeNode } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { take, debounceTime, distinctUntilChanged } from 'rxjs';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { GridData } from '../../models/customer';
import { AMBestRating, RiderFee, SurrenderYear, StrategyFee, Groups, ConfiMultiplier, StateData, illustrationtblResponse } from '../../models/filter';
import { ProductDetails, ProductDetail, ProductChildDetail, Product } from '../../models/product';
import { BenefitcalculatorService } from '../../service/benefitcalculator.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUpdateCustomShelfComponent } from '../custom-shelf/add-update-custom-shelf/add-update-custom-shelf.component';

enum StateOption {
  Contribution = "contribution",
  Income = "income"
}
enum MonthAnuallyOption {
  Monthly = 0,
  Annually = 1
}
enum sortByData {
  WithdrawalAmount = "WithdrawalAmount",
  CompanyName = "CompanyName",
  ProductName = "ProductName",
  // Contribution = "Contribution Amount",

}
enum allOptions {
  select1 = 'Slect1',
  select2 = 'Slect1',
  select3 = 'Slect1'
}
enum varData {
  All = "All",
  Level = "Level",
  Increasing = "Increasing",
  InflationBased = "Inflation Based",
  MaxIncome = "Max Income Option : MAWA"
}

@Component({
  selector: 'app-advance-filter-menu',
  templateUrl: './advance-filter-menu.component.html',
  styleUrls: ['./advance-filter-menu.component.scss']
})
export class AdvanceFilterMenuComponent implements OnInit {
  @Input() savedType: string = '';
  @Input() showApprovedProducts: boolean = false;
  @Input() customShelfMasterData: any[] = [];
  @Input() userCustomShelfs: any[] = [];

  @Output() hideAdvanceFilter: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateFilter: EventEmitter<any> = new EventEmitter();
  @Output() onShowSpinner: EventEmitter<any> = new EventEmitter();
  @Output() onApplyCustomShelf: EventEmitter<any> = new EventEmitter();

  showMenu: boolean = true;
  filterEdit: any
  IsCustomShelf: boolean = false;
  // CustomShelflist: any[] = [];
  createShelfPopup: boolean = false;
  //topbar form
  topbarForm = this.fb.group({
    gender: ['', Validators.required],
    age: [0, Validators.required],
    acalendar: ['', Validators.required],
    pouseage: [0],
    scalendar: [''],
    illinois: [''],
    ridertype: [''],
    Contribution: [''],
    income: [''],
    amount: [100000, Validators.required],
    periode: [true],
    diffperiode: [10],
  })
  //Product Profile
  userlogo: any;
  username: any;
  pproductProfileDetails: any = {};
  pproductProfileData: any[] = [];
  selectedStateName: any;

  stateCode: string = '';
  productId: number = 0;
  properity: string = '';

  companyLogo1: any;
  companyLogo2: any;
  premiumType: string = '';
  preBonuse: string = '';
  vestingBonuse: string = '';
  credStrategies: any[] = [];
  indexesAvailable: any;
  indexes: any;
  index: any[] = [];
  tiker: any[] = [];
  pspouseAge: Number = 0;

  //Product Profile
  agecheck: number = 0;
  spouseagecheck: number = 0;
  amountcheck: number = 0;
  diffperiodecheck: number = 0;

  //topbar vars 
  incCont: string = 'contribution';
  periodeam: number = 1;
  requestValues: any = {};
  filteredArray: any[] = [];
  ispagination: boolean = false;
  first: number = 0;
  notFount: boolean = false
  stateCodeFilter: any;
  genderDataList: any[] = [];
  diffperiode: number = 10;
  showSpinner: boolean = false;
  //sidebar vars start
  sideMenushow: boolean = false;
  selectedtab: string = 'Sidebar';
  model: any[] = [];
  selectedStrategyFee: any;
  SelectRiderFee: any;
  selectedGroups: string[] = [];
  selectedSuryear: any[] = [];
  selectedAMBestrating: string[] = [];
  selectedSortBy: any = 'WithdrawalAmount';
  selectedSortBymodel: any = 'Withdrawal Amount';
  categories: any[] = [];
  product: ProductDetails[] = [];
  productlist: any;
  amBestRating: AMBestRating[] = [];
  riderFee: RiderFee[] = [];
  surrenderYear: SurrenderYear[] = [];
  strategyFee: StrategyFee[] = [];
  strategyFeeOptions: any[] = [];
  benifitBaseType: any[] = [];
  groups: Groups[] = [];
  confiMultiplier: ConfiMultiplier[] = [];
  stateData: StateData[] = [];
  varData: any[] = [];
  illustrationtblResponse: illustrationtblResponse[] = [];
  illustrationResData: any[] = [];
  ProductDetail: ProductDetail[] = [];
  ProductChildDetail: ProductChildDetail[] | undefined;
  //sidebar vars end

  //deshboard vars start
  checked: boolean = false;
  showmodel = false;
  useritems!: MenuItem[];
  items!: MenuItem[];
  itemslist!: MenuItem[];
  products!: Product[];
  griddata: GridData[] = [];
  filterCustomself: any[] = [];
  customProductList: any[] = [];
  custoShelfShow: boolean = false;

  //Flags
  isLoader: boolean = false;
  isSubmit: boolean = false;
  isLoad: boolean = false;
  isCompare: boolean = false;
  isSwitch: boolean = false;
  comparedataItem: any;
  compEvent: any;
  compDataChk: boolean = true;
  totalMatches: any;
  IncomePeriod: any;

  // models for bind chips
  SelectFilter: any[] = [];
  SelectFilter_AMbest: any[] = [];
  SelectFilter_AMbest_name: any[] = [];
  SelectFilter4: any[] = [];
  SelectFilter6: any[] = [];

  //short by props
  compareData: any[] = [];
  totalCompare: number = 0;

  //models
  riderFeeData: any[] = [];
  filterdataobj_group: any[] = []
  filterdataobj_group_name: any[] = []
  filterdataobj_surrender_year: any[] = []
  filterdataobj_surrender_year_name: any[] = []
  filterdataobj_products: any[] = []
  filterdataobj_products_name: any[] = [];
  filterdataobj_riderfee: any[] = []
  filterdataobj_riderfee_name: any[] = []
  filterdataobj_strategyfee: any[] = []
  filterdataobj_strategyfee_name: any[] = []
  filterdataobj_short: any[] = []
  filterdataobj_confiMulti_name: any = null;
  filterdataobj_rider_type: string = '';
  filteredCustomshelfs: any[] = [];
  filtredCustomSelfObj: any[] = [];

  age: number = 55;
  ageSelectDate = new Date();
  ageSelectedDate: any;
  pouseage: number = 0;
  spageSelectDate = new Date();
  spageSelectedDate: any;
  isSelected: boolean = true;


  //flags
  groupfilterFlag: string = '';
  productFlag: string = '';
  productfilterFlag: string = '';
  amRatingFlag: string = '';
  amRatingfilterFlag: string = '';
  surrFlag: string = ''
  surrfilterFlag: string = '';
  riderFlag: string = ''
  strategyFlag: string = ''

  //Illustration page 
  illustration: boolean = false;
  cards: boolean = false;
  coveredLife: any;
  purchasePay: number = 0;
  initialBenifit: number = 0;
  initial: string = '$';
  carrier: string = '';
  productIllu: string = '';
  riderIllu: string = '';
  illEffectDate = new Date();
  riderAge: any;
  riderFeeIllu: any = '';
  withdrawalPer: number = 0;
  otherVariable: string = '';
  stepups: string = '';
  rollupdata: any[] = [];
  illu_Img: string = '';
  rollUpIllu: any = '';
  rollUpTerm: any = '';
  premium: any = '';
  premiumBonus: any = '';
  benefitBonus: any;
  stateIssue: string = '';
  singleJoinIncome: string = '';
  purchasePayment: string = '';
  benefitBase: string = '';
  deferralPer: any = '';
  waitingPer: any;
  annualIncome: string = '';
  lifeIncome: string = '';
  productProfile: boolean = false;
  savedSearch: boolean = false;
  selectedItem: any = null;
  stateName: string = '';
  compareCardsData: any[] = [];
  uploadedFiles: any[] = [];
  urlobj: any[] = [];
  companyName: string = '';
  loogoo: any;
  altImg: string = '../../assets/layout/images/no-picture-available.jpg'

  productProfileDetails: any[] = [];
  showEllipsis: boolean = true;
  contributionIncome: string = '';
  monthlyAnnualypaymentType: boolean = true;
  dataView: string = 'grid';
  defaultsettingsvalues: any;
  menuOutsideClickListener: any;
  profileMenuOutsideClickListener: any;
  stateOptions: any[] = [];
  monthlyAnuallyopt: any[] = [];
  allOptionsSelectList: any[] = [];
  sortByDataOption: any = [];
  filteredSavedItem: number = 0;
  compareVariables: boolean = true;

  //////Save Search/////
  searchtitle: string = '';
  LoginUserData: any;

  @ViewChild('p') paginator!: Paginator;
  dob: any;
  spouseDob: any;

  searchDataForm = this.fb.group({
    searchtitle: ['', [Validators.required, this.noWhitespaceValidator]]
  })

  saveFormSubmitted: boolean = false;
  searchTitle: string = '';
  allAMBestRatings: any = [];
  selectedCustomShelfName: string = 'Select';
  customShelfApplied: boolean = false;
  approvedProductIds: number[] = [];
  userShelfProductIds: number[] = [];

  constructor(public layoutService: LayoutService, public renderer: Renderer2, public router: Router,
    private benefitcalculatorService: BenefitcalculatorService,
    public dialogService: DialogService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private messageService: MessageService,
  ) {
    let test = JSON.parse(sessionStorage.getItem('logInSession') || '');
    let logindata = this.benefitcalculatorService.decryptUsingAES256(test);
    this.LoginUserData = JSON.parse(logindata);

    this.userlogo = '';
    this.username = this.benefitcalculatorService.get('UserName')[0];

    this.benefitcalculatorService.getStateData().pipe(take(1), debounceTime(400), distinctUntilChanged()).subscribe((res: any) => {
      this.stateData = res;
    });

    // Dropdown Data
    this.genderDataList = [
      { name: 'Select', value: 'select' },
      { name: 'Male', value: 'M' },
      { name: 'Female', value: 'F' }
    ];
    this.sortByDataOption = [
      { name: 'Withdrawal Amount', value: sortByData.WithdrawalAmount },
      { name: 'Company Name', value: sortByData.CompanyName },
      { name: 'Product Name', value: sortByData.ProductName },
      // { name: 'Contribution Amount', value: sortByData.Contribution }
    ];
    this.stateOptions = [
      { label: 'Contribution', value: StateOption.Contribution },
      { label: 'Income', value: StateOption.Income },
    ];
    this.varData = [
      { varName: 'All', value: varData.All },
      { varName: 'Level', value: varData.Level },
      { varName: 'Increasing', value: varData.Increasing },
      { varName: 'Inflation Based', value: varData.InflationBased },
      { varName: 'Max Income With Reduced Income', value: varData.MaxIncome }
    ]
    this.monthlyAnuallyopt = [
      { label: 'Monthly', value: MonthAnuallyOption.Monthly },
      { label: 'Annually', value: MonthAnuallyOption.Annually },
    ]
    this.allOptionsSelectList = [
      { label: 'Select1', value: allOptions.select1 },
      { label: 'Select2', value: allOptions.select2 },
      { label: 'Select3', value: allOptions.select3 },
    ]
    this.strategyFeeOptions = [
      { strategyFee: 'Yes', value: 1 },
      { strategyFee: 'No', value: 0 },
    ];
    this.confiMultiplier = [
      {
        confiMulti: "yes"
      },
      {
        confiMulti: "no"
      }
    ];
  }

  ngOnInit(): void {
    let uID = this.benefitcalculatorService.decryptUsingAES256(JSON.parse(sessionStorage.getItem('userId') || ''));
    this.showSpinner = true;
    const selectedShelfName = sessionStorage.getItem('CustomShelfName');
    if (selectedShelfName) {
      this.selectedCustomShelfName = selectedShelfName;
      this.customShelfApplied = true;
    }

    let loginSessionData = JSON.parse(sessionStorage.getItem('logInSession') || '');
    let logindata = this.benefitcalculatorService.decryptUsingAES256(loginSessionData);
    let loginUserData = JSON.parse(logindata);
    this.userShelfProductIds = loginUserData.userSelfProductListIndex.map((x: any) => x.productid);
    this.approvedProductIds = loginUserData.userSelfProductListIndex_approved.map((x: any) => x.productid);

    this.getAMRatings();
    // Getting Default Setting Data
    this.benefitcalculatorService.getDefaultSetting(JSON.parse(uID)).subscribe((res) => {
      if (res) {
        this.dataView = res.dataView ? res.dataView : 'list';
        this.defaultsettingsvalues = res;
        this.contributionIncome = res.contribution === 0 ? 'contribution' : 'income';
        this.monthlyAnnualypaymentType = res.paymentType;
        this.topbarForm.patchValue({
          gender: res.gender,
          age: res.age,
          pouseage: res.spouseAge,
          illinois: res.userState,
          ridertype: res.riderType,
          Contribution: this.contributionIncome,
          amount: res.amount === null ? 100000 : res.amount,
          diffperiode: res.defPeriod,
          // periode: this.monthlyAnnualypaymentType
        });
      } else {
        this.contributionIncome = 'contribution';
        this.topbarForm.patchValue({
          illinois: "ALL",
          ridertype: "All",
          Contribution: this.contributionIncome,
          amount: 100000,
          diffperiode: 10,
          // periode: this.monthlyAnnualypaymentType
        });
      }
      let sidebarObject: any = (sessionStorage.getItem('sidebarObject'))
      if (sidebarObject == null) {
        //setTimeout(() => {
        this.renderData();
        // }, 1000);
      } else {
        let obj: any = (sessionStorage.getItem('chipDataObject'))
        if (obj != null) {
          let chipDataObject = JSON.parse(obj)
          this.filterdataobj_group_name = chipDataObject?.filterdataobj_group_name;
          this.filterdataobj_products_name = chipDataObject?.filterdataobj_products_name;
          this.SelectFilter_AMbest_name = chipDataObject?.SelectFilter_AMbest_name;
          this.filterdataobj_surrender_year_name = chipDataObject?.filterdataobj_surrender_year_name;
          this.filterdataobj_riderfee_name = chipDataObject?.filterdataobj_riderfee_name;
          this.filterdataobj_strategyfee_name = chipDataObject?.filterdataobj_strategyfee_name;
          this.filteredSavedItem = this.filterdataobj_group_name?.length + this.SelectFilter_AMbest_name?.length + this.filterdataobj_surrender_year_name?.length + this.filterdataobj_products_name?.length + this.filterdataobj_strategyfee_name?.length + this.filterdataobj_riderfee_name?.length;
        }
        // setTimeout(() => {
        this.renderData(sidebarObject);
        // }, 1000);
      }
      this.resetFilteredSavedCount();
    });
  }

  renderData(sidebarObject?: any) {
    // console.log('renderData', sidebarObject);
    if (sidebarObject) {
      sidebarObject = JSON.parse(sidebarObject);
      this.selectedSortBymodel = sidebarObject.sortBy ? sidebarObject.sortBy : this.selectedSortBymodel;
      this.getFilterSideBarInit(sidebarObject);
      this.agecheck = Number(this.topbarForm.get('age')?.value);
      this.spouseagecheck = Number(this.topbarForm.get('pouseage')?.value);
      this.amountcheck = Number(this.topbarForm.get('amount')?.value);
      this.diffperiodecheck = Number(this.topbarForm.get('diffperiode')?.value);
      this.requestValues = {
        ageBand: this.topbarForm.get('age')?.value || sidebarObject.ageBand,
        deferralYear: this.topbarForm.get('diffperiode')?.value || sidebarObject.deferralYear,
        gender: this.topbarForm.get('gender')?.value || sidebarObject.gender,
        spageBand: this.topbarForm.get('pouseage')?.value || 55
      }
      this.ageSelectDate = new Date();
      this.ageSelectDate.setFullYear(this.ageSelectDate.getFullYear() - Number(this.requestValues.ageBand));
      this.ageSelectedDate = this.datePipe.transform(this.ageSelectDate, "MM/dd/yyyy")
      this.spageSelectDate = new Date();
      this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - Number(this.requestValues.spageBand));
      this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy")
      // this.isLoader = true;
      this.filteredArray = [];
    } else {
      this.filterEdit = this.benefitcalculatorService.getsavedData();
      if (this.savedType == '') {
        if (this.filterEdit.source._value.length == 2) {
          if (this.filterEdit.source._value[1] == "edit") {
            this.savedType = 'edit';
          }
          else if (this.filterEdit.source._value[1] == "view") {
            this.savedType = 'view';
          }
        }
      }
      // savesearch Data Filter
      const searchTitle = this.filterEdit.source._value.length > 0 ? this.filterEdit.source._value[0].searchtitle : '';
      if (this.filterEdit.source._value.length === 0 || this.filterEdit.source._value.length === 1) {
        this.searchDataForm.get('searchtitle')?.setValue(searchTitle);
        this.getFilterSideBar();
      }
      else {
        this.searchDataForm.get('searchtitle')?.patchValue(searchTitle);
        this.searchTitle = searchTitle;
        let filterdatasaved = this.filterEdit.source._value[0].savedstr;
        this.filterdataobj_group_name = filterdatasaved.filterdataobj_group_name
        this.filterdataobj_group = filterdatasaved.filterdataobj_group

        this.SelectFilter_AMbest_name = filterdatasaved.SelectFilter_AMbest_name
        this.SelectFilter_AMbest = filterdatasaved.SelectFilter_AMbest

        this.filterdataobj_products_name = filterdatasaved.filterdataobj_products_name
        this.filterdataobj_products = filterdatasaved.filterdataobj_products

        this.filterdataobj_surrender_year_name = filterdatasaved.filterdataobj_surrender_year_name
        this.filterdataobj_surrender_year = filterdatasaved.filterdataobj_surrender_year

        this.filterdataobj_strategyfee_name = filterdatasaved.filterdataobj_strategyfee_name
        this.filterdataobj_strategyfee = filterdatasaved.filterdataobj_strategyfee

        this.filterdataobj_riderfee_name = filterdatasaved.filterdataobj_riderfee_name
        this.filterdataobj_riderfee = filterdatasaved.filterdataobj_riderfee

        let chipDataObject = {
          filterdataobj_group_name: this.filterdataobj_group_name,
          filterdataobj_products_name: this.filterdataobj_products_name,
          SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
          filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
          filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
          filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
        }
        sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
        if (this.filterdataobj_group_name.length != 0 || this.SelectFilter_AMbest_name.length != 0 || this.filterdataobj_surrender_year_name.length != 0 || this.filterdataobj_products_name.length != 0 || this.filterdataobj_strategyfee_name.length != 0 || this.filterdataobj_riderfee_name.length != 0) {
          this.getFilterSideBar();
        }
        this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
        this.resetFilteredSavedCount();
      }
      this.agecheck = Number(this.topbarForm.get('age')?.value);
      this.spouseagecheck = Number(this.topbarForm.get('pouseage')?.value);
      this.amountcheck = Number(this.topbarForm.get('amount')?.value);
      this.diffperiodecheck = Number(this.topbarForm.get('diffperiode')?.value);
      this.requestValues = {
        ageBand: this.topbarForm.get('age')?.value || 55,
        deferralYear: this.topbarForm.get('diffperiode')?.value || 10,
        gender: this.topbarForm.get('gender')?.value || 'M',
        spageBand: this.topbarForm.get('pouseage')?.value || 55
      }
      this.ageSelectDate = new Date();
      this.ageSelectDate.setFullYear(this.ageSelectDate.getFullYear() - Number(this.requestValues.ageBand));
      this.ageSelectedDate = this.datePipe.transform(this.ageSelectDate, "MM/dd/yyyy")
      this.spageSelectDate = new Date();
      this.spageSelectDate.setFullYear(this.spageSelectDate.getFullYear() - Number(this.requestValues.spageBand));
      this.spageSelectedDate = this.datePipe.transform(this.spageSelectDate, "MM/dd/yyyy")
      // this.isLoader = true;
      this.filteredArray = [];
    }
  }

  onVarChange(e: any) {
    this.amRatingFlag = "";
    this.productfilterFlag = "";
    this.surrFlag = "";
    this.strategyFlag = "";
    this.riderFlag = "";
    this.groupfilterFlag = "";
    this.benifitBaseType = [];
    this.filterdataobj_rider_type = '';
    this.filterdataobj_rider_type = e.value;
    this.getFilterSideBar()
    this.paginator.first = 1;
  }


  onTabSelect(status: string) {
    this.selectedtab = status;
  }

  onBackClick() {
    this.selectedtab = 'Sidebar';
  }

  filterByCustomShelf(event: any) {
    if (event.value && event.value.trim() != '' && event.value.trim() != 'Select') {
      const userCustomSelf = this.userCustomShelfs.find(x => x.shelfName == event.value)
      if (userCustomSelf) {
        const products = userCustomSelf.items;
        if (!this.customShelfApplied) {
          this.filteredSavedItem++;
        }
        this.customShelfApplied = true;
        sessionStorage.setItem('CustomShelfName', this.selectedCustomShelfName);
        // this.onApplyCustomShelf.emit(products);
        let sidebarObject: any = sessionStorage.getItem('sidebarObject')
        if (sidebarObject) {
          sidebarObject = JSON.parse(sidebarObject);
          this.onShowSpinner.emit();
          // console.log('getFilterSideBarInit');
          this.getFilterSideBarInit(sidebarObject, true)
        } else {
          // console.log('getFilterSideBar');
          this.getFilterSideBar()
        }
      }
    } else {
      this.removeShelf();
    }
  }


  removeShelf() {
    this.selectedCustomShelfName = 'Select';
    sessionStorage.removeItem('CustomShelfName');
    this.customShelfApplied = false;
    this.filteredSavedItem--;
    let sidebarObject: any = sessionStorage.getItem('sidebarObject')
    if (sidebarObject) {
      sidebarObject = JSON.parse(sidebarObject);
      this.onShowSpinner.emit();
      // console.log('getFilterSideBarInit');
      this.getFilterSideBarInit(sidebarObject, true)
    } else {
      // console.log('getFilterSideBar');
      this.getFilterSideBar()
    }
  }

  shortByFilters(val: any) {
    this.selectedSortBy = val.value;
    this.selectedSortBymodel = val.value;
    this.filterdataobj_short.push(val.value);
    let sidebarObject: any = sessionStorage.getItem('sidebarObject')
    if (sidebarObject) {
      sidebarObject = JSON.parse(sidebarObject);
      this.onShowSpinner.emit();
      // console.log('getFilterSideBarInit');
      this.getFilterSideBarInit(sidebarObject, true)
    } else {
      // console.log('getFilterSideBar');
      this.getFilterSideBar()
    }
  }

  onGroupSelect(event: any) {
    this.filterdataobj_group = [];
    this.filterdataobj_group_name = [];
    this.amRatingFlag = ""
    this.productfilterFlag = ""
    this.surrFlag = ""
    this.strategyFlag = ""
    this.riderFlag = ""
    this.groupfilterFlag = "Groups"

    if (event && event.value.length > 0) {
      for (let i = 0; i < event.value.length; i++) {
        if (event.value[i].groupId) {
          this.filterdataobj_group.push(event.value[i].groupId);
          this.filterdataobj_group_name.push({ groupName: event.value[i].groupName, groupId: event.value[i].groupId })
        }
      }
      this.getFilterSideBar();
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
      this.resetFilteredSavedCount();
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
    }
    else {
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.getFilterSideBar();
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filteredSavedItem - 1;
    }

  }

  onProductSelect(event: any) {
    if (event.node.children === undefined) {
      let { node } = event;
      this.filterdataobj_products.push(node.key);
      this.filterdataobj_products_name.push({
        key: node.key,
        label: node.label,
        data: node.data
      });
      this.productfilterFlag = "Product";
      this.groupfilterFlag = "";
      this.amRatingFlag = "";
      this.surrFlag = ""
      this.strategyFlag = ""
      this.riderFlag = ""

      this.getFilterSideBar();
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
    }
    else {
      const { node: { children } } = event;
      if (Array.isArray(children) && children.length) {
        for (let val of children) {
          this.filterdataobj_products.filter((el: any, index: any) => {
            if (el === val.key) {
              this.filterdataobj_products.splice(index, 1);
            }
          })
          this.filterdataobj_products.push(val.key);
          this.filterdataobj_products_name.filter((el: any, index: any) => {
            if (el.key === val.key) {
              this.filterdataobj_products_name.splice(index, 1);
            }
          })
          this.filterdataobj_products_name.push({
            key: val.key,
            label: val.label,
            data: val.data
          });
        }
      }
      this.productfilterFlag = "Product";
      this.groupfilterFlag = "";
      this.amRatingFlag = "";
      this.surrFlag = ""
      this.strategyFlag = ""
      this.riderFlag = ""
      this.getFilterSideBar();
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;

      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));

    }
    this.resetFilteredSavedCount();
  }

  onProductRemove(event: any) {
    if (event.node.children === undefined) {
      const { node } = event;
      let element: any[] = [];
      if (node) element = this.filterdataobj_products.filter((val: any) => {
        return node.key === val
      })
      element.filter((res: any) => {
        this.filterdataobj_products.filter((data: any, index: any) => {
          if (res === data) {
            this.filterdataobj_products.splice(index, 1);

          }
        })
      })
      element.filter((res: any) => {
        this.filterdataobj_products_name.filter((data: any, index: any) => {
          if (res === data.key) {
            this.filterdataobj_products_name.splice(index, 1)
            this.filteredSavedItem--;
          }
        })
      })
      this.productfilterFlag = "Product";
      this.groupfilterFlag = "";
      this.amRatingFlag = "";
      this.surrFlag = ""
      this.strategyFlag = ""
      this.riderFlag = ""
      this.getFilterSideBar();
      // this.paginator.first = 1;

      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));

      // if (this.filterdataobj_products_name.length == 0) {
      //   this.filteredSavedItem = this.filteredSavedItem - 1;
        
      // }
    }
    else {

      const { node: { children } } = event;
      let element: any[] = [];
      if (children) element = this.filterdataobj_products.filter((val: any) => {
        return children.some((el: any) => el.key === val)
      })

      element.filter((res: any) => {
        this.filterdataobj_products.filter((data: any, index: any) => {
          if (res === data) {
            this.filterdataobj_products.splice(index, 1);
          }
        })
      })
      element.filter((res: any) => {
        this.filterdataobj_products_name.filter((data: any, index: any) => {
          if (res === data.key) {
            this.filterdataobj_products_name.splice(index, 1);
            this.filteredSavedItem--;
          }
        })
      })
      this.productfilterFlag = "Product";
      this.groupfilterFlag = "";
      this.amRatingFlag = "";
      this.surrFlag = ""
      this.strategyFlag = ""
      this.riderFlag = ""
      this.getFilterSideBar();
      // this.paginator.first = 1;

      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      // if (this.filteredSavedItem >= 2) {
      //   if(this.filterdataobj_products_name.length == 0 && !this.customShelfApplied){
      //     this.filteredSavedItem = 0;
      //   }
      //  // this.filteredSavedItem = !this.customShelfApplied ? 0 : this.filteredSavedItem;
      // } else {
      //   if (this.filterdataobj_products_name.length == 0) {
      //     this.filteredSavedItem = this.filteredSavedItem - 1;
      //   }
      // }
    }
    //console.log('onProductRemove', this.filterdataobj_products_name)
  }

  onAMReatingSelect(event: any) {
    this.SelectFilter_AMbest = [];
    this.SelectFilter_AMbest_name = [];
    this.productfilterFlag = ""
    this.groupfilterFlag = ""
    this.surrFlag = ""
    this.strategyFlag = ""
    this.riderFlag = ""
    this.amRatingFlag = "amBests";

    if (event && event.value.length > 0) {

      for (let i = 0; i < event.value.length; i++) {

        if (event.value[i].bestid) {
          this.SelectFilter_AMbest.push(event.value[i].bestid);
          this.SelectFilter_AMbest_name.push({ bestid: event.value[i].bestid })

        }

        let chipDataObject = {
          filterdataobj_group_name: this.filterdataobj_group_name,
          filterdataobj_products_name: this.filterdataobj_products_name,
          SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
          filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
          filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
          filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
        }
        sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      }
      this.getFilterSideBar();
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
      this.resetFilteredSavedCount();
    } else {

      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.getFilterSideBar()
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filteredSavedItem - 1
    }
  }

  onSurYearSelect(event: any) {
    this.filterdataobj_surrender_year = [];
    this.filterdataobj_surrender_year_name = [];
    this.amRatingFlag = ""
    this.productfilterFlag = ""
    this.groupfilterFlag = ""
    this.surrfilterFlag = "";
    this.strategyFlag = ""
    this.riderFlag = ""
    this.surrFlag = "surrYrs"

    if (event && event.value.length > 0) {

      for (let i = 0; i < event.value.length; i++) {

        if (event.value[i].surryr) {
          this.filterdataobj_surrender_year.push(event.value[i].surryr);
          this.filterdataobj_surrender_year_name.push({ surryr: event.value[i].surryr })
        }

        let chipDataObject = {
          filterdataobj_group_name: this.filterdataobj_group_name,
          filterdataobj_products_name: this.filterdataobj_products_name,
          SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
          filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
          filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
          filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
        }
        sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      }
      this.getFilterSideBar();
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
      this.resetFilteredSavedCount();
    } else {
      this.getFilterSideBar();
      // this.paginator.first = 1;
      this.filteredSavedItem = this.filteredSavedItem - 1;
    }
  }

  onRiderFeeSelect(event: any) {
    this.amRatingFlag = ""
    this.productfilterFlag = ""
    this.groupfilterFlag = ""
    this.surrFlag = ""
    this.strategyFlag = ""
    this.riderFlag = 'riderFee'
    this.filterdataobj_riderfee = [];
    this.filterdataobj_riderfee_name = [];

    if (event.value) {
      if (event.value) {
        this.filterdataobj_riderfee.push(String(event.value.riderFee));
        this.filterdataobj_riderfee_name.push({ riderFee: String(event.value.riderFee) });
      }
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
    }
    this.getFilterSideBar();
    // this.paginator.first = 1;
    this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
    if (this.SelectRiderFee.riderFee == 0) {
      this.SelectRiderFee.riderFee = '0'
    }
    this.resetFilteredSavedCount();
  }

  onStrategyFeeSelect(event: any) {
    this.amRatingFlag = ""
    this.productfilterFlag = ""
    this.groupfilterFlag = ""
    this.surrFlag = ""
    this.riderFlag = ""
    this.strategyFlag = 'strategyFees'
    this.filterdataobj_strategyfee = [];
    this.filterdataobj_strategyfee_name = [];
    if (event.value) {
      this.filterdataobj_strategyfee.push({ strategyFee: event.value.value });
      this.filterdataobj_strategyfee_name.push({ strategyFee: event.value.strategyFee });
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
    }
    this.getFilterSideBar();
    // this.paginator.first = 1;
    this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
    this.resetFilteredSavedCount();
  }

  onConfiMultiSelect(event: any) {
    let { value } = event;
    this.filterdataobj_confiMulti_name = value;
    this.getFilterSideBar();
    // this.paginator.first = 1;
    this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
    this.resetFilteredSavedCount();
  }

  removeTags(item: any): void {
    let index: number = 0;
    if (item.groupName !== undefined) {
      index = this.filterdataobj_group.findIndex((e: any) => e === item.groupId);
      this.filterdataobj_group.splice(index, 1);
      index = this.filterdataobj_group_name.findIndex((e: any) => e.groupId === item.groupId);
      this.filterdataobj_group_name.splice(index, 1);
      this.selectedGroups = [...this.filterdataobj_group_name];
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.filteredSavedItem = this.filteredSavedItem - 1;
    }
    if (item.bestid !== undefined) {
      index = this.SelectFilter_AMbest.findIndex((e: any) => e == item.bestid);
      this.SelectFilter_AMbest.splice(index, 1);
      index = this.SelectFilter_AMbest_name.findIndex((e: any) => e.bestid == item.bestid);
      this.SelectFilter_AMbest_name.splice(index, 1);
      this.selectedAMBestrating = [...this.SelectFilter_AMbest_name];
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.filteredSavedItem = this.filteredSavedItem - 1
    }
    if (item.surryr !== undefined) {
      index = this.filterdataobj_surrender_year.findIndex((e: any) => e === item.surryr);
      this.filterdataobj_surrender_year.splice(index, 1);
      index = this.filterdataobj_surrender_year_name.findIndex((e: any) => e.surryr === item.surryr);
      this.filterdataobj_surrender_year_name.splice(index, 1);
      this.selectedSuryear = [...this.filterdataobj_surrender_year_name];
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.filteredSavedItem = this.filteredSavedItem - 1
    }
    if (item.strategyFee !== undefined) {
      // index = this.filterdataobj_strategyfee.findIndex((e: any) => e.strategyFee === item.strategyFee);
      // this.filterdataobj_strategyfee.splice(index, 1);
      // index = this.filterdataobj_strategyfee_name.findIndex((e: any) => e.strategyFee === item.strategyFee);
      // this.filterdataobj_strategyfee_name.splice(index, 1);
      // this.selectedStrategyFee = [...this.filterdataobj_strategyfee_name]
      index = this.filterdataobj_strategyfee.findIndex((e: any) => e.strategyFee === this.strategyFeeOptions.find(x => x.strategyFee == item.strategyFee).value);
      this.filterdataobj_strategyfee.splice(index, 1);
      index = this.filterdataobj_strategyfee_name.findIndex((e: any) => e.strategyFee === item.strategyFee);
      this.filterdataobj_strategyfee_name.splice(index, 1);
      this.selectedStrategyFee = [...this.filterdataobj_strategyfee_name]
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.filteredSavedItem = this.filteredSavedItem - 1
    }
    if (item.riderFee !== undefined) {
      index = this.filterdataobj_riderfee.findIndex((e: any) => e.riderFee === item.riderFee);
      this.filterdataobj_riderfee.splice(index, 1);
      index = this.filterdataobj_riderfee_name.findIndex((e: any) => e.riderFee === item.riderFee);
      this.filterdataobj_riderfee_name.splice(index, 1);
      this.SelectRiderFee = [...this.filterdataobj_riderfee_name]
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.filteredSavedItem = this.filteredSavedItem - 1
    }
    if (item.confiMulti !== undefined) {
      this.SelectFilter6 = [];
      this.filteredSavedItem = this.filteredSavedItem - 1
    }
    if (item.data !== undefined) {
      index = this.filterdataobj_products.findIndex((e: any) => e === item.key);
      this.filterdataobj_products.splice(index, 1);
      index = this.filterdataobj_products_name.findIndex((e: any) => e.key === item.key);
      this.filterdataobj_products_name.splice(index, 1);
      this.SelectFilter4 = [...this.filterdataobj_products_name];
      let chipDataObject = {
        filterdataobj_group_name: this.filterdataobj_group_name,
        filterdataobj_products_name: this.filterdataobj_products_name,
        SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
        filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
        filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
        filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name
      }
      sessionStorage.setItem("chipDataObject", JSON.stringify(chipDataObject));
      this.filteredSavedItem = this.filteredSavedItem - 1
    }
    if (this.filteredSavedItem == 0) {
      this.resetFilteredSavedCount();
    }
    this.getFilterSideBar();
  }

  clearAllFilter() {
    this.groupfilterFlag = '';

    this.productFlag = '';
    this.productfilterFlag = '';

    this.amRatingFlag = '';
    this.amRatingfilterFlag = '';

    this.riderFlag = ""
    this.strategyFlag = ""

    this.surrFlag = ''
    this.surrfilterFlag = '';

    this.filterdataobj_group = [];
    this.filterdataobj_group_name = [];
    this.SelectFilter_AMbest = [];
    this.SelectFilter_AMbest_name = []
    this.filterdataobj_surrender_year = [];
    this.filterdataobj_surrender_year_name = [];

    this.filterdataobj_products = [];
    this.filterdataobj_products_name = [];
    this.filterdataobj_riderfee = [];
    this.filterdataobj_riderfee_name = [];
    this.filterdataobj_strategyfee = [];
    this.filterdataobj_strategyfee_name = [];

    this.selectedGroups = [];
    this.selectedAMBestrating = [];
    this.selectedStrategyFee = [];
    this.selectedSuryear = [];
    this.SelectRiderFee = [];
    this.SelectFilter4 = [];
    sessionStorage.removeItem('sessionGroup');
    sessionStorage.removeItem('sessionProduct');
    sessionStorage.removeItem('chipDataObject');
    let obj: any = sessionStorage.getItem('sidebarObject')
    if(obj){
      obj = JSON.parse(obj);
      let sidebarObj = {
        joint: obj.joint,
        ageBand: obj.ageBand,
        deferralYear: obj.deferralYear,
        gender: obj.gender,
        spageBand: obj.spageBand,
        benefitBaseType: obj.benefitBaseType,
        stateCode: obj.stateCode
      }
      sessionStorage.removeItem('sidebarObject');
      sessionStorage.setItem('sidebarObject', JSON.stringify(sidebarObj));
    }
    this.IsCustomShelf = false;
    this.filtredCustomSelfObj = [];
    this.selectedCustomShelfName = 'Select';
    this.customShelfApplied = false;
    this.getFilterSideBar();
    // this.paginator.first = 1;
    this.filteredSavedItem = this.filterdataobj_group_name.length + this.SelectFilter_AMbest_name.length + this.filterdataobj_surrender_year_name.length + this.filterdataobj_products_name.length + this.filterdataobj_strategyfee_name.length + this.filterdataobj_riderfee_name.length;
    this.resetFilteredSavedCount();
  }


  resetFilteredSavedCount() {
    if (this.customShelfApplied) {
      this.filteredSavedItem++;
    }
  }

  getAnualIncome(data: any) {
    let Income: number = 0;
    if (this.topbarForm.get('Contribution')?.value == 'contribution') {
      if (this.topbarForm.get('periode')?.value) {
        Income = this.topbarForm.get('pouseage')?.value != 0 ? (data.factorJoint * this.topbarForm.get('amount')?.value!) : (data.factorSingle * this.topbarForm.get('amount')?.value!)
      }
      else {
        Income = this.topbarForm.get('pouseage')?.value != 0 ? ((data.factorJoint * this.topbarForm.get('amount')?.value!) / 12) : ((data.factorSingle * this.topbarForm.get('amount')?.value!) / 12)
      }
    }
    else {
      if (this.topbarForm.get('periode')?.value) {
        Income = this.topbarForm.get('pouseage')?.value != 0 ? (this.topbarForm.get('amount')?.value! / data.factorJoint) : (this.topbarForm.get('amount')?.value! / data.factorSingle)
      }
      else {
        Income = this.topbarForm.get('pouseage')?.value != 0 ? ((this.topbarForm.get('amount')?.value! * 12) / data.factorJoint) : ((this.topbarForm.get('amount')?.value! * 12) / data.factorSingle)
      }
    }
    return Math.round(Income);
  }

  getFilterSideBar() {
    let groupIds = [];
    let productIds = [];
    let amBestRatingIds = [];
    let surrYearIds = [];
    let riderFeeId: any;
    let strategyFeeId: any;
    let coMultiplier: any;
    // let riderTypes: any;
    // let basicFilterObj: any = {
    //   age: this.topbarForm.get('age')?.value,
    //   spage: this.topbarForm.get('pouseage')?.value,
    //   amountType: this.topbarForm.get('Contribution')?.value,
    //   amount: this.topbarForm.get('amount')?.value,
    //   periode: this.topbarForm.get('periode')?.value,
    // }
    // sessionStorage.setItem("basicFilterObj", JSON.stringify(basicFilterObj));
    if (this.filterdataobj_group != undefined) {
      for (let i = 0; i < this.filterdataobj_group.length; i++) {
        if (this.filterdataobj_group[i]) {
          groupIds.push(this.filterdataobj_group[i]);
        }
      }
    }
    if (this.filterdataobj_products != undefined) {
      for (let i = 0; i < this.filterdataobj_products.length; i++) {
        if (this.filterdataobj_products[i]) {
          productIds.push(this.filterdataobj_products[i]);
        }

      }
    }
    if (this.SelectFilter_AMbest != undefined) {
      for (let i = 0; i < this.SelectFilter_AMbest.length; i++) {
        if (this.SelectFilter_AMbest[i]) {
          amBestRatingIds.push(this.SelectFilter_AMbest[i]);
        }
      }
    }
    if (this.filterdataobj_surrender_year != undefined) {
      for (let i = 0; i < this.filterdataobj_surrender_year.length; i++) {

        if (this.filterdataobj_surrender_year[i]) {
          surrYearIds.push(this.filterdataobj_surrender_year[i]);
        }
      }
    }
    if (this.filterdataobj_riderfee != undefined) {
      if (this.filterdataobj_riderfee[0]) {
        riderFeeId = this.filterdataobj_riderfee[0];
      }
    }
    if (this.filterdataobj_strategyfee != undefined) {
      // if (this.filterdataobj_strategyfee[0]) {
      strategyFeeId = this.filterdataobj_strategyfee[0];
      // }
    }
    if (this.filterdataobj_confiMulti_name != undefined) {
      if (this.filterdataobj_confiMulti_name) {
        coMultiplier = this.filterdataobj_confiMulti_name === 'yes' ? true : false;
      }
    }

    // if (this.filterdataobj_rider_type != undefined) {
    //     if (this.filterdataobj_rider_type) {
    //         riderTypes = this.filterdataobj_rider_type
    //     }
    // }


    let agedetail: number = 55;
    let spage: number =  55;
    let age: number = 55;
    let basicFilterObj: any = sessionStorage.getItem('basicFilterObj');
    if(basicFilterObj){
      basicFilterObj  = JSON.parse(basicFilterObj)
      spage = basicFilterObj.spage ? basicFilterObj.spage: spage;
      age = basicFilterObj.age ? basicFilterObj.age: age;
      agedetail = basicFilterObj.age ? basicFilterObj.age: age;
    }
    let sidebarObj: any = sessionStorage.getItem('sidebarObject');
    if(sidebarObj){
      sidebarObj = JSON.parse(sidebarObj);
    }
    let searchObj: any;
    if (spage > age) {
      agedetail = age;
      searchObj = {
        "ageBand": sidebarObj.ageBand,
        "deferralYear": sidebarObj.deferralYear,
        "gender": sidebarObj.gender,
        "groupId": groupIds || [],
        "productId": productIds || [],
        "amBestRating": amBestRatingIds || [],
        "surrYear": surrYearIds || [],
        "riderFee": riderFeeId == undefined ? null : riderFeeId || null,
        "strategyFee": (strategyFeeId == 0 || strategyFeeId == 1) ? strategyFeeId : 2,
        "coMultiplier": coMultiplier || null,
        "benefitBaseType": sidebarObj.benefitBaseType,
        "stateCode": sidebarObj.stateCode
      }
    } else {
      agedetail = spage
      searchObj = {
        "ageBand": sidebarObj.ageBand,
        "deferralYear": sidebarObj.deferralYear,
        "gender": sidebarObj.gender,
        "groupId": groupIds || [],
        "productId": productIds || [],
        "amBestRating": amBestRatingIds || [],
        "surrYear": surrYearIds || [],
        "riderFee": riderFeeId == undefined ? null : riderFeeId || null,
        "strategyFee": (strategyFeeId == 0 || strategyFeeId == 1) ? strategyFeeId : 2,
        "coMultiplier": coMultiplier || null,
        "benefitBaseType": sidebarObj.benefitBaseType,
        "stateCode": sidebarObj.stateCode
      }
    }
    searchObj.joint = sidebarObj.joint;
    searchObj.sortBy = this.selectedSortBy ? this.selectedSortBy : 'WithrawalAmount';
    this.onShowSpinner.emit();
    this.getProductRiders(searchObj, true);
  }

  getFilterSideBarInit(obj: any, refreshPage = false) {
    this.filterdataobj_group = obj.groupId != null ? obj.groupId : [];
    this.filterdataobj_products = obj.productId != null ? obj.productId : [];
    this.SelectFilter_AMbest = obj.amBestRating != null ? obj.amBestRating : [];
    this.filterdataobj_surrender_year = obj.surrYear != null ? obj.surrYear : [];
    this.filterdataobj_riderfee = obj.riderFee != null ? obj.groupId : null;
    this.filterdataobj_strategyfee = obj.strategyFee != null ? obj.groupId : null;
    obj.sortBy = refreshPage ? this.selectedSortBy : obj.sortBy;
    this.getProductRiders(obj, refreshPage);
  }


  getAMRatings() {
    this.benefitcalculatorService.getAMBestRatings().subscribe((res: any) => {
      this.allAMBestRatings = res;
    })
  }

  getProductRiders(sidebarObj: any, refreshPage: boolean) {
    this.isLoader = true;
    this.filteredArray = [];
    sessionStorage.setItem("sidebarObject", JSON.stringify(sidebarObj));
    if (this.savedType != 'edit') {
      sidebarObj['pageNumber'] = 1;
      sidebarObj['pageSize'] = 25;
    }
    let productIds: number[] = [];
    if (this.showApprovedProducts) {
      productIds = Object.assign([], this.approvedProductIds);
    } else {
      productIds = Object.assign([], this.userShelfProductIds);
    }
    if (!sidebarObj['productId']) {
      sidebarObj['productId'] = [];
    }

    if (this.filterdataobj_products.length > 0) {
      sidebarObj['productId'] = this.filterdataobj_products.filter((value: number) => productIds.indexOf(value) > -1);
    }
    if (this.customShelfApplied) {
      let customShelfProducts = this.userCustomShelfs.find(x => x.shelfName == this.selectedCustomShelfName).items.map((x: any) => x.productId);
      if (customShelfProducts.length > 0) {
        sidebarObj['productId'] = [...sidebarObj['productId'], ...customShelfProducts];
      }
    }
    if (sidebarObj['productId'].length == 0) {
      sidebarObj['productId'] = productIds;
    } else {
      sidebarObj['productId'] = sidebarObj['productId'].filter((value: number) => productIds.indexOf(value) > -1);
    }
    this.benefitcalculatorService.postProductsDetails(sidebarObj).subscribe((res: any) => {
      this.isLoader = false;
      this.showSpinner = false;
      if (this.groupfilterFlag != "Groups") {
        this.groups = res.groups;
        if (sidebarObj.groupId.length == 0) {
          this.selectedGroups = [];
        }
        else {
          this.selectedGroups = res.groups;
        }
      }
      else {
        if (sidebarObj.groupId.length == 0) {
          this.selectedGroups = [];
        }
        else {
          this.selectedGroups = res.groups;
        }
      }
      // console.log('selectedGroups', res.groups);
      if (this.productfilterFlag != "Product") {
        var mainArray = [];
        var datalist = res.products;
        var companyid = [];
        for (let i = 0; i < datalist.length; i++) {
          var objcompany = {
            companyid: datalist[i].companyid,
            companyname: datalist[i].companyName,
          };
          companyid.push(objcompany);

        }
        var unique = [
          ...new Map(
            companyid.map((companyid) => [
              companyid['companyid'],
              companyid,
            ])
          ).values(),
        ];
        unique = unique.sort(function (objdata, objdatatwo) {
          if (objdata.companyname < objdatatwo.companyname) {
            return -1;
          } else if (objdata.companyname > objdatatwo.companyname) {
            return 1;
          } else {
            return 0;
          }
        });
        for (let i = 0; i < unique.length; i++) {
          var objdatalist = datalist.filter(
            (x: any) => x.companyid === unique[i].companyid
          );
          var childrens = [];
          for (let j = 0; j < objdatalist.length; j++) {
            var chaildobj1 = {
              key: objdatalist[j].productid,
              label: (objdatalist[j].name).trim() + ' ' + '(' + objdatalist[j].totalProducts + ')',
              data: (objdatalist[j].name).trim(),
              total: objdatalist[j].total,
              type: 'product',
              isShelfProduct: this.userShelfProductIds.indexOf(objdatalist[j].productid) > -1
            };
            childrens.push(chaildobj1);
          }

          var obj1 = {
            children: childrens,
            data: unique[i].companyname,
            key: unique[i].companyid,
            label: unique[i].companyname,
          };
          mainArray.push(obj1);
        }
        this.SelectFilter4 = [];
        let allProducts: any = sessionStorage.getItem('allProducts');
        if (allProducts) {
          this.productlist = JSON.parse(allProducts);
          this.SelectFilter4 = JSON.parse(JSON.stringify(this.filterdataobj_products_name));
        
        } else {
          this.productlist = JSON.parse(JSON.stringify(mainArray));
          sessionStorage.setItem('allProducts', JSON.stringify(mainArray));
          this.benefitcalculatorService.allProducts = JSON.parse(JSON.stringify(mainArray));
          //sessionStorage.setItem('productList', JSON.stringify(mainArray));
        }

        if(this.SelectFilter4.length > 0){
          this.SelectFilter4.map(x => {
            x.type = 'product',
            x.isShelfProduct = this.userShelfProductIds.indexOf(x.key) > -1
          })
          this.updateParentSelection();
        }
      }
      if (this.amRatingFlag != "amBests") {
        let amBestRating: any = [];
        this.allAMBestRatings.forEach((rating: any) => {
          const index = res.amBests.findIndex((x: AMBestRating) => x.bestid == rating.rating);
          amBestRating.push({ bestid: rating.rating, total: index > -1 ? res.amBests[index].total : 0, active: index > -1 ? false : true })
        });
        this.amBestRating = amBestRating;
        if (sidebarObj.amBestRating.length == 0) {
          this.selectedAMBestrating = [];
        }
        else {
          this.selectedAMBestrating = JSON.parse(JSON.stringify(this.amBestRating)) //res.amBests;
        }
      }
      if (this.riderFlag != 'riderFee') {
        this.riderFee = [];
        this.riderFeeData = [];
        this.riderFee.push(
          {
            minRiderFee: res.riderFees.minRiderFee,
            maxRiderFee: res.riderFees.maxRiderFee
          }
        )
        for (let i = 0; i < this.riderFee.length; i++) {

          for (this.riderFee[i].minRiderFee; this.riderFee[i].minRiderFee <= this.riderFee[i].maxRiderFee; this.riderFee[i].minRiderFee += 0.5) {
            this.riderFeeData.push({ riderFee: this.riderFee[i].minRiderFee });
          }
          this.riderFeeData.push({ riderFee: this.riderFee[i].minRiderFee });
        }
      }
      if (this.surrFlag != "surrYrs") {
        this.surrenderYear = res.surrYrs;
        if (sidebarObj.surrYear.length == 0) {
          this.selectedSuryear = [];
        }
        else {
          this.selectedSuryear = res.surrYrs;
        }
      }
      if (this.strategyFlag != 'strategyFees') {
        var strategyFeedata = res.strategyFees;
        this.strategyFee = [];
        for (let i = 0; i < strategyFeedata.length; i++) {
          this.strategyFee.push({ strategyFee: (strategyFeedata[i].strategyFee * 100) });
        }
      }
      if (this.filteredArray.length == 0) {
        this.notFount = true;
      }
      if (this.filteredArray.length > 8) {
        this.ispagination = true
      } else {
        this.ispagination = false
      }
      if (this.savedType != 'edit' && refreshPage) {
        this.onUpdateFilter.emit(res);
      }
    }, (error) => {
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Something went wrong, please try sometime' });
      this.onUpdateFilter.emit([]);
    });
  }

  updateParentSelection() {
    this.productlist.forEach((parent: any) => this.checkChildren(parent));
  }

  checkChildren(node: TreeNode) {
    if (node.children) {
      const allChildrenSelected = node.children.every((child) => this.isNodeSelected(child));
      if (allChildrenSelected) {
        if (!this.isNodeSelected(node)) {
          this.SelectFilter4.push(node);
        }
      } else {
        const index = this.SelectFilter4.indexOf(node);
        if (index !== -1) {
          this.SelectFilter4.splice(index, 1);
        }
      }
      node.children.forEach(child => this.checkChildren(child));
    }
  }

  isNodeSelected(node: any) {
    return this.SelectFilter4.some(selectedNode => selectedNode.key === node.key);
  }

  isParentNodeSelected(node: TreeNode) {
    return this.isNodeSelected(node) || (node.children && node.children.some(child => this.isNodeSelected(child)));
  }


  saveContinue() {
    this.savedSearch = true;
  }

  savedSearches() {
    if (this.savedType == 'edit') {
      let viewdata = [''];
      this.benefitcalculatorService.setsavedData(viewdata);
      this.onHideSideMenu();
    } else {
      this.router.navigate(['/savedsearch'])
    }
  }

  saveSearch() {
    this.saveFormSubmitted = true;
    if (this.searchDataForm.valid && this.savedType == 'edit') {
      this.updateContinue();
    } else if (this.searchDataForm.valid) {
      this.addNewSearch();
    }
  }

  addNewSearch() {
    let SelectFilter_AMbest = [];
    let surrYearIds = [];
    let groupIds = [];
    let productIds = [];
    let riderFeeId = [];
    let strategyFeeId = [];

    if (this.filterdataobj_group != undefined) {
      for (let i = 0; i < this.filterdataobj_group.length; i++) {
        if (this.filterdataobj_group[i]) {
          groupIds.push(this.filterdataobj_group[i]);
        }
      }
    }
    if (this.filterdataobj_products != undefined) {
      for (let i = 0; i < this.filterdataobj_products.length; i++) {
        if (this.filterdataobj_products[i]) {
          productIds.push(this.filterdataobj_products[i]);
        }

      }
    }
    if (this.SelectFilter_AMbest != undefined) {
      for (let i = 0; i < this.SelectFilter_AMbest.length; i++) {
        if (this.SelectFilter_AMbest[i]) {
          SelectFilter_AMbest.push(this.SelectFilter_AMbest[i]);
        }
      }
    }
    if (this.filterdataobj_surrender_year != undefined) {
      for (let i = 0; i < this.filterdataobj_surrender_year.length; i++) {
        if (this.filterdataobj_surrender_year[i]) {
          surrYearIds.push(this.filterdataobj_surrender_year[i]);
        }
      }
    }
    if (this.filterdataobj_riderfee != undefined) {
      if (this.filterdataobj_riderfee[0]) {
        riderFeeId.push(this.filterdataobj_riderfee[0]);
      }
    }
    if (this.filterdataobj_strategyfee != undefined) {
      if (this.filterdataobj_strategyfee[0]) {
        strategyFeeId.push(this.filterdataobj_strategyfee[0]);
      }
    }

    let basicFilterObj: any = sessionStorage.getItem("basicFilterObj");
    basicFilterObj = JSON.parse(basicFilterObj);

    let chipobj = {
      filled: true,
      filterdataobj_group_name: this.filterdataobj_group_name,
      filterdataobj_groupid: groupIds,

      SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
      SelectFilter_AMbestid: SelectFilter_AMbest,

      filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
      filterdataobj_surrender_yearid: surrYearIds,

      filterdataobj_products_name: this.filterdataobj_products_name,
      filterdataobj_productsid: productIds,

      filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name,
      filterdataobj_strategyfeeid: strategyFeeId,

      filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
      filterdataobj_riderfeeid: riderFeeId,

      gender: this.topbarForm.get('gender')?.value,
      age: basicFilterObj.age ? basicFilterObj.age : 55,
      pouseage: basicFilterObj.spage ? basicFilterObj.spage : 55,
      state: this.topbarForm.get('illinois')?.value,
      Contribution: this.topbarForm.get('Contribution')?.value,
      income: basicFilterObj.amountType ? basicFilterObj.amountType : 'income',
      amount: basicFilterObj.amount ? basicFilterObj.amount : 0,
      periode: basicFilterObj.periode ? basicFilterObj.amount : false,
      diffperiode: 10,
      searchtitle: this.searchDataForm.value.searchtitle,
      sortBy: this.selectedSortBy,
      joint: this.topbarForm.get('pouseage')?.value ? 'Yes' : 'No'
    }
    let savedsearchdata = JSON.stringify(chipobj);
    let uID = this.benefitcalculatorService.decryptUsingAES256(JSON.parse(sessionStorage.getItem('userId') || ''));
    let savedsearchobj = {
      // id: ,
      userId: JSON.parse(uID),
      searchString: savedsearchdata,
      lastUpdatedDate: this.illEffectDate
    }
    //save serach insert
    this.benefitcalculatorService.postSaveSearch(savedsearchobj).subscribe((res: any) => {
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'success', detail: 'Search history has been saved Successfully !!' });
      this.savedSearch = false;
      this.saveFormSubmitted = false;
      this.showMenu = false;
      this.hideAdvanceFilter.emit(true);
      // setTimeout(() => {

      // }, 2000);
    },
      err => {
      })

  }

  updateContinue() {
    let SelectFilter_AMbest = [];
    let surrYearIds = [];
    let groupIds = [];
    let productIds = [];
    let riderFeeId = [];
    let strategyFeeId = [];

    for (let i = 0; i < this.filterdataobj_group.length; i++) {
      if (this.filterdataobj_group[i]) {
        groupIds.push(this.filterdataobj_group[i]);
      }
    }

    for (let i = 0; i < this.filterdataobj_products.length; i++) {
      if (this.filterdataobj_products[i]) {
        productIds.push(this.filterdataobj_products[i]);
      }
    }

    for (let i = 0; i < this.SelectFilter_AMbest.length; i++) {
      if (this.SelectFilter_AMbest[i]) {
        SelectFilter_AMbest.push(this.SelectFilter_AMbest[i]);
      }
    }

    for (let i = 0; i < this.filterdataobj_surrender_year.length; i++) {
      if (this.filterdataobj_surrender_year[i]) {
        surrYearIds.push(this.filterdataobj_surrender_year[i]);
      }
    }

    if (this.filterdataobj_riderfee[0]) {
      riderFeeId.push(this.filterdataobj_riderfee[0]);
    }

    if (this.filterdataobj_strategyfee[0]) {
      strategyFeeId.push(this.filterdataobj_strategyfee[0]);
    }


    let chipobj = {
      filled: true,
      filterdataobj_group_name: this.filterdataobj_group_name,
      filterdataobj_groupid: groupIds,

      SelectFilter_AMbest_name: this.SelectFilter_AMbest_name,
      SelectFilter_AMbestid: SelectFilter_AMbest,

      filterdataobj_surrender_year_name: this.filterdataobj_surrender_year_name,
      filterdataobj_surrender_yearid: surrYearIds,

      filterdataobj_products_name: this.filterdataobj_products_name,
      filterdataobj_productsid: productIds,

      filterdataobj_strategyfee_name: this.filterdataobj_strategyfee_name,
      filterdataobj_strategyfeeid: strategyFeeId,

      filterdataobj_riderfee_name: this.filterdataobj_riderfee_name,
      filterdataobj_riderfeeid: riderFeeId,

      gender: this.topbarForm.get('gender')?.value,
      age: this.topbarForm.get('age')?.value,
      // acalendar: this.topbarForm.get('gender')?.value,
      pouseage: this.topbarForm.get('pouseage')?.value,
      // scalendar: this.topbarForm.get('gender')?.value,
      state: this.topbarForm.get('illinois')?.value,
      Contribution: this.topbarForm.get('Contribution')?.value,
      income: this.topbarForm.get('income')?.value,
      amount: this.topbarForm.get('amount')?.value,
      periode: this.topbarForm.get('periode')?.value,
      diffperiode: this.topbarForm.get('diffperiode')?.value,
      searchtitle: this.searchDataForm.value.searchtitle,
      sortBy: this.selectedSortBy,
      joint: this.topbarForm.get('pouseage')?.value ? 'Yes' : 'No'
    }
    let savedsearchdata = JSON.stringify(chipobj);
    let savedsearchobj = {
      id: this.filterEdit.source._value[0].id,
      userId: this.filterEdit.source._value[0].userId,
      searchString: savedsearchdata,
      lastUpdatedDate: this.illEffectDate
    }
    //save serach update
    this.benefitcalculatorService.putSaveSearch(savedsearchobj).subscribe((res: any) => {
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'success', detail: 'Update history Successfully !!' });
      this.savedSearch = false;
      this.showMenu = false;
      let viewdata = [''];
      this.benefitcalculatorService.setsavedData(viewdata);
      this.hideAdvanceFilter.emit(savedsearchobj);
    },
      err => {
      })
  }

  get searchDataFormControls(): any {
    return this.searchDataForm['controls'];
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  onHideSideMenu() {
    this.showMenu = false;
    this.hideAdvanceFilter.emit(false);
  }

  addCustomSelf() {
    const ref: any = this.dialogService.open(AddUpdateCustomShelfComponent, {
      header: 'Add Custom Self',
      width: '70%',
      contentStyle: { "overflow-y": "auto" },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        allProducts: this.customShelfMasterData
      }
    });
    ref.onClose.subscribe((res: any) => {

    });
    ref.onClose.subscribe((res: boolean) => {
      if (res === true) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Custom Shelf has been added successfully' });
        this.getSavedCustomShelfs();
      }
    });
  }

  onEditCustomShelf() {
    if (this.selectedCustomShelfName && this.selectedCustomShelfName != '') {
      const customeShelf = this.userCustomShelfs.find(x => x.shelfName == this.selectedCustomShelfName);
      this.updateCustomSelf(customeShelf);
    }
  }

  updateCustomSelf(customShelf: any) {
    let children: any = []
    for (let mainData of this.customShelfMasterData) {
      for (let child of mainData.children) {
        for (let data of customShelf.items) {
          if (data.productId == child.key) {
            if (data.companyid == mainData.key) {
              children.push({
                label: data.productName,
                data: data.productName,
                key: data.productId
              })
            }
          }
        }
      }
    }
    const ref: any = this.dialogService.open(AddUpdateCustomShelfComponent, {
      header: 'Update Custom Self',
      width: '70%',
      contentStyle: { "overflow-y": "auto" },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        allProducts: this.customShelfMasterData,
        seletedItems: children,
        shelfName: customShelf.shelfName,
        isEdit: true
      }
    });
    ref.onClose.subscribe((res: boolean) => {
      if (res === true) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Custom Shelf has updated successfully' });
        this.getSavedCustomShelfs();
      }
    });
  }

  getSavedCustomShelfs() {
    this.userCustomShelfs = [];
    let uID = this.benefitcalculatorService.decryptUsingAES256(JSON.parse(sessionStorage.getItem('userId') || ''));
    this.benefitcalculatorService.getUserCustomShelfs(+(uID)).subscribe((res: any) => {
      if (res.data.length > 0) {
        for (let item of res.data) {
          item.shelfName = item.shelfName ? item.shelfName : 'Custom Shelf';
          const index = this.userCustomShelfs.findIndex((x: any) => x.shelfName == item.shelfName);
          if (index > -1) {
            this.userCustomShelfs[index].items.push(item);
            if (!this.userCustomShelfs[index].allCompanies.includes(item.companyid)) {
              this.userCustomShelfs[index].allCompanies.push(item.companyid);
            }
          } else {
            this.userCustomShelfs.push({
              shelfName: item.shelfName ? item.shelfName : 'Custom Shelf',
              items: [item],
              allCompanies: [item.companyid]
            })
          }
        }
      }
    });
  }

}
