import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '../service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { BenefitcalculatorService } from '../../service/benefitcalculator.service';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  userItems: MenuItem[] = [];
  userlogo: string = '';
  username !: string;
  isLoggedIn$!: Observable<boolean>;

  constructor(public layoutService: LayoutService, private router: Router, public benefitcalculatorService: BenefitcalculatorService, private authService: AuthService) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn; // {2}
    const isLoggedIn = this.authService.isLoggedIn.toPromise();
    this.userlogo = '';
    //  //topbar menu button items
    this.userItems = [{
      label: 'Account Settings',
      icon: 'pi pi-user-edit',
      command: () => {
        this.router.navigate(['/profilesetting']);
      }
    },
    {
      label: 'Default Settings',
      icon: 'pi pi-cog',
      command: () => {
        this.router.navigate(['/adminsystem']);
      }
    },
    {
      label: 'My Saved Searches',
      icon: 'pi pi-save',
      command: () => {
        this.router.navigate(['/savedsearch']);
      }
    },
    {
      label: 'My Custom Shelfs',
      icon: 'pi pi-book',
      command: () => {
        this.router.navigate(['/customshelf']);
      }
    },
    {
      label: 'Fixed Products',
      icon: 'pi pi-eye',
      command: () => {
        this.router.navigate(['/products/fixed-products']);
      }
    },
    {
      label: 'Variable Products',
      icon: 'pi pi-eye',
      command: () => {
        this.router.navigate(['/products/variable-products']);
      }
    },
    {
      label: 'IVA Products',
      icon: 'pi pi-eye',
      command: () => {
        this.router.navigate(['/products/iva-products']);
      }
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        this.onLogout();
      }
    }
    ];
  }


  redirectUserAnnuityNexus() {
    let loginSessionData = JSON.parse(sessionStorage.getItem('logInSession') || '');
    let logindata = this.benefitcalculatorService.decryptUsingAES256(loginSessionData);
    const loginUserData = JSON.parse(logindata);
    let mainMenuBtnLink = 'https://staging.an.annuitynexus.com/auth?token=';
    if (environment.domain == 'https://benefitapi.annuitynexus.com') {
      mainMenuBtnLink = 'https://an.annuitynexus.com/auth?token=';
    }
    this.benefitcalculatorService.getUser(loginUserData.id).subscribe((res) => {
      if (res.success === true && res.data.userOtp) {
        window.open(`${mainMenuBtnLink}${+(res.data.userOtp)}`, "_blank");
      }
    });
  }


  ngAfterViewInit(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
  }

  getUserName() {
    return this.benefitcalculatorService.get('UserName') ? (this.benefitcalculatorService.get('UserName')[0]) : '';
  }

  onLogout() {
    // sessionStorage.removeItem('Token');
    // sessionStorage.removeItem('UserName');
    // sessionStorage.removeItem('userId');
    // sessionStorage.removeItem('sidebarObject');
    // sessionStorage.removeItem('chipDataObject');
    // sessionStorage.removeItem('basicFilterObj');
    sessionStorage.clear();
    this.authService.logOut();
    this.router.navigate(['/auth/login']);
  }
}


