import { OnInit, inject } from '@angular/core';
import { Component } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { AMBestRating, Groups, RiderFee, StrategyFee, SurrenderYear } from '../models/filter';
import {
    Datum,
    ProductDetails,
    ProductDetail,
    ProductChildDetail,
} from '../models/product';
import { BenefitcalculatorService } from '../service/benefitcalculator.service';
import { NodeService } from '../service/node.service';
import { ProductService } from '../service/product.service';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss'],
})
export class AppMenuComponent implements OnInit {
    selectedtab: string = 'Sidebar';
    files1: any[] = [];
    countries: any[] = [];
    model: any[] = [];

    cities: any[] = [];

    selectedCity1: any;

    selectedCountries: any[] = [];
    selectedShort: any = 'Withdrawal Amount';
    categories: any[] = [];
    selectedCategory: any = null;
    product: ProductDetails[] = [];

    datum: Datum[] = [];
    productlist: any;

    amBestRating: AMBestRating[] = [];
    riderFee: RiderFee[] = [];
    surrenderYear: SurrenderYear[] = [];
    strategyFee: StrategyFee[] = [];
    groups: Groups[] = [];

    //root: Root[] = [];

    ProductDetail: ProductDetail[] = [];
    ProductChildDetail: ProductChildDetail[] | undefined;
    constructor(
        public layoutService: LayoutService,
        private nodeService: NodeService,
        private productService: ProductService,
        private benefitcalculatorService: BenefitcalculatorService
    ) { }

    ngOnInit() {
        this.benefitcalculatorService.getAMRatingDetails().subscribe((data) => {
            this.amBestRating = data;
        });
        this.benefitcalculatorService.getRiderFeeDetails().subscribe((data) => {
            this.riderFee = data;
        });
        this.benefitcalculatorService.getSurrenderYrDetails().subscribe((data) => {
            this.surrenderYear = data;
        });
        this.benefitcalculatorService.getStrategyFeeDetails().subscribe((data) => {
            this.strategyFee = data;
        });


        this.categories = [
            { name: 'Withdrawal Amount', key: 'A' },
            { name: 'Company Name', key: 'M' },
            { name: 'Prodct Name', key: 'P' },
        ];

        this.nodeService.getFiles().then((files) => (this.files1 = files));

    }

    ontabSelect(status: string) {
        this.selectedtab = status;
    }
    onbackClick() {
        this.selectedtab = 'Sidebar';
    }
    shortby(val: any) {
        this.selectedShort = val.name;
    }
}
