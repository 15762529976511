<div style="position: fixed;
height: 5rem;
z-index: 997;
left: 0;
top: 0;
width: 100%;
padding: 0 2rem;
background-color: var(--surface-card);
transition: left .2s;
display: flex;
align-items: center;
box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014;">
    <div class="py-2 px-4 shadow bg-white heading fixed-top">
        <div class="d-flex align-items-center justify-content-between">
            <div class="flex text-start align-items-center">
                <div class="logo-wrp">
                    <a class="layout-topbar-logo d-block" routerLink="/home">
                        <img class="head-img" src="../../assets/layout/images/annuity_logo.png" alt="logo" />
                    </a>
                </div>
                <div class="pl-4">
                    <a pButton label="Main Menu" class="annuity-menu-btn" (click)="redirectUserAnnuityNexus()"></a>
                </div>
            </div>
            <div class="d-flex align-items-center admin text-end header-user-wrp">
                <div class="py-2 px-4 site-title">
                    <h4 class="mb-0">Fixed Indexed GLWB Calculator</h4>
                </div>
                <div #topbarmenu class="layout-topbar-menu justify-content-end" [ngClass]="{
                            'layout-topbar-menu-mobile-active':
                                layoutService.state.profileSidebarVisible
                        }">
                    <button class="p-link layout-topbar-button rounded-circle" (click)="menuuser.toggle($event)">
                        <!-- <div *ngIf="userlogo" class="login-user">
                                <img src="assets/demo/images/login/avatar.png" alt="Image" />
                            </div> -->
                        <div class="login-user">
                            <label>{{ getUserName() | uppercase }}</label>
                        </div>
                    </button>
                    <p-menu class="userpop-up" [showTransitionOptions]="'250ms'" #menuuser [popup]="true"
                        [model]="userItems"></p-menu>
                </div>
            </div>
        </div>
        <p-toast position="top-center" key="tc"></p-toast>
    </div>
</div>



