import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SavedSearchRoutingModule } from './saved-search-routing.module';
import { SavedSearchComponent } from './saved-search.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { AdvanceFilterMenuComponent } from '../advance-filter-menu/advance-filter-menu.component';
import { RouterModule } from '@angular/router';
import { EllipsisModule } from 'ngx-ellipsis';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AppConfigModule } from 'src/app/layout/config/config.module';
import { DashboardsRoutingModule } from '../dashboard/dashboard-routing.module';
import { IllustrationModule } from '../illustration/illustration.module';
import { LogoUploadModule } from '../logo-upload/logo-upload.module';
import { TreeDemoRoutingModule } from '../uikit/tree/treedemo-routing.module';
import { BeaconSharedModule } from 'src/app/shared/shared.module';
import { CustomShelfModule } from '../custom-shelf/custom-shelf.module';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';


@NgModule({
  declarations: [
    SavedSearchComponent,
    AdvanceFilterMenuComponent
  ],
  imports: [
    CommonModule,
    SavedSearchRoutingModule,
    InputNumberModule,
    DropdownModule,
    MenuModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule,
    AccordionModule,
    ChipModule,
    DataViewModule,
    TreeTableModule,
    DialogModule,
    SidebarModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    AppConfigModule,
    TreeDemoRoutingModule,
    TreeModule,
    InputNumberModule,
    CalendarModule,
    DropdownModule,
    SliderModule,
    ReactiveFormsModule,
    ListboxModule,
    DropdownModule,
    ButtonModule,
    CommonModule,
    ChartModule,
    MenuModule,
    TableModule,
    StyleClassModule,
    PanelMenuModule,
    DashboardsRoutingModule,
    ProgressSpinnerModule,
    ChipModule,
    IllustrationModule,
    DialogModule,
    CheckboxModule,
    ImageModule,
    FileUploadModule,
    LogoUploadModule,
    ToastModule,
    EllipsisModule,
    DataViewModule,
    MultiSelectModule,
    SelectButtonModule,
    ToggleButtonModule,
    VirtualScrollerModule,
    BeaconSharedModule,
    DynamicDialogModule,
    CustomShelfModule
  ],
  providers: [
    DialogService
  ],
  exports: [
    SavedSearchComponent,
    AdvanceFilterMenuComponent
  ]
})
export class SavedSearchModule { }
