export enum BonusType {
     FixedAccountValue = 'Fixed Account Value',
     ContractValue = 'Contract Value',
     Others = 'Others',
     PurchasePayments = 'Purchase Payments',
     BenefitBase = 'Benefit Base',
     InterestBonus = 'Interest Bonus',
     AccumulationValue = 'Accumulation Value'
}

export enum BonusTypeId {
     FixedAccountValue = 1,
     ContractValue = 2,
     Others = 3,
     PurchasePayments = 4,
     BenefitBase = 5,
     InterestBonus = 6,
     AccumulationValue = 7
}

export enum PremiumType {
     Flexible = 'Flexible',
     Single = 'Single',
     SingleWithAdditional = 'SingleWithAddition'
}

export enum PremiumTypeId {
     Flexible = 1,
     Single = 2,
     SingleWithAdditional = 3
}

export enum ParticipantBasedOnId {
     Owner = 1,
     Annuitant = 2,
     OwnerAndAnnuitant = 3,
     OwnerOrAnnuitant = 4
}

export enum ParticipantBasedOn {
     Owner = 'Owner',
     Annuitant = 'Annuitant',
     OwnerAndAnnuitant = 'Owner and Annuitant',
     OwnerOrAnnuitant = 'Owner or Annuitant'
}

export enum BasedOnLifeId {
     Owner = 1,
     Annuitant = 2,
     OwnerAndAnnuitant = 3,
     OwnerOrAnnuitant = 4 //  (if owner is a non natural entity)
}

export enum BasedOnLifeName {
     Owner = 'Owner',
     Annuitant = 'Annuitant',
     OwnerAndAnnuitant = 'Owner and Annuitant',
     OwnerOrAnnuitant = 'Owner (or Annuitant if owner is a natural entity)'
}


export enum ChargeBasisId {
     FixedAccountValue = 1,
     ContractValue = 2,
     Others = 3,
     PurchasePayments = 4,
     BenefitBase = 5,
     InterestBonus = 6,
     AccumulationValue = 7
}

export enum ChargeBasisName {
     FixedAccountValue = 'Fixed Account Value',
     ContractValue = 'Contract Value',
     Others = 'Others',
     PurchasePayments = 'Purchase Payments',
     BenefitBase = 'Benefit Base',
     InterestBonus = 'Interest Bonus',
     AccumulationValue = 'Accumulation Value'
}

export enum DeductedWhenId {
     Daily = 1,
     BiMonthly = 2,
     Monthly = 3,
     Qauterly = 4,
     SemiAnnually = 5,
     Annually = 6
}

export enum DeductedWhenName {
     Daily = 'Daily',
     BiMonthly = 'Bi-Monthly',
     Monthly = 'Monthly',
     Qauterly = 'Quaterly',
     SemiAnnually = 'Semi-Annually',
     Annually = 'Annually'
}

export enum ChargePercentageId {
     Simple = 1,
     Compound = 2
}

export enum ChargePercentageName {
     Simple = 'Simple',
     Compound = 'Compound'
}

export enum AvailabilityId {
     Individual = 1,
     Combine = 2,
     Both = 3
}

export const WaiverTypes = ['Activities of Daily Living', 'Annuitization', 'Bail Out', 'Death', 'Disability', 'Hospital', 'Nursing Home / Long Term Care', 'Surrender Window', 'Terminal Illness', 'Unemployment'];

export const stateCodes = [
     "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
     "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
     "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
     "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
     "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

export const stateVariationSections = [{
     title: 'Product By Minimum Guarantee',
     key: 'minimumGuarantees'
},
{
     title: 'Age',
     key: 'age'
},
{
     title: 'Contribution',
     key: 'contribution'
},
{
     title: 'Withdrawal',
     key: 'withdrawal'
},
{
     title: 'Surrender',
     key: 'surrender'
},
{
     title: 'Settlement Options',
     key: 'settlement'
},
{
     title: 'GLWB',
     key: 'glwb'
},
{
     title: 'Multiplier',
     key: 'multiplier'
},
{
     title: 'Death Benefit',
     key: 'death-benefit'
},
{
     title: 'Bonus',
     key: 'bonus'
}]

export const stateVariationColumms = ['minimumGuarantees', 'age', 'contribution', 'withdrawal', 'surrender', 'settlement', 'glwb', 'multiplier', 'death-benefit', 'bonus'];

export const usStates = [
     { "name": "Alabama", "code": "AL" },
     { "name": "Alaska", "code": "AK" },
     { "name": "Arizona", "code": "AZ" },
     { "name": "Arkansas", "code": "AR" },
     { "name": "California", "code": "CA" },
     { "name": "Colorado", "code": "CO" },
     { "name": "Connecticut", "code": "CT" },
     { "name": "Delaware", "code": "DE" },
     { "name": "Florida", "code": "FL" },
     { "name": "Georgia", "code": "GA" },
     { "name": "Hawaii", "code": "HI" },
     { "name": "Idaho", "code": "ID" },
     { "name": "Illinois", "code": "IL" },
     { "name": "Indiana", "code": "IN" },
     { "name": "Iowa", "code": "IA" },
     { "name": "Kansas", "code": "KS" },
     { "name": "Kentucky", "code": "KY" },
     { "name": "Louisiana", "code": "LA" },
     { "name": "Maine", "code": "ME" },
     { "name": "Maryland", "code": "MD" },
     { "name": "Massachusetts", "code": "MA" },
     { "name": "Michigan", "code": "MI" },
     { "name": "Minnesota", "code": "MN" },
     { "name": "Mississippi", "code": "MS" },
     { "name": "Missouri", "code": "MO" },
     { "name": "Montana", "code": "MT" },
     { "name": "Nebraska", "code": "NE" },
     { "name": "Nevada", "code": "NV" },
     { "name": "New Hampshire", "code": "NH" },
     { "name": "New Jersey", "code": "NJ" },
     { "name": "New Mexico", "code": "NM" },
     { "name": "New York", "code": "NY" },
     { "name": "North Carolina", "code": "NC" },
     { "name": "North Dakota", "code": "ND" },
     { "name": "Ohio", "code": "OH" },
     { "name": "Oklahoma", "code": "OK" },
     { "name": "Oregon", "code": "OR" },
     { "name": "Pennsylvania", "code": "PA" },
     { "name": "Rhode Island", "code": "RI" },
     { "name": "South Carolina", "code": "SC" },
     { "name": "South Dakota", "code": "SD" },
     { "name": "Tennessee", "code": "TN" },
     { "name": "Texas", "code": "TX" },
     { "name": "Utah", "code": "UT" },
     { "name": "Vermont", "code": "VT" },
     { "name": "Virginia", "code": "VA" },
     { "name": "Washington", "code": "WA" },
     { "name": "West Virginia", "code": "WV" },
     { "name": "Wisconsin", "code": "WI" },
     { "name": "Wyoming", "code": "WY" }
]

export const gmwbFactors = ['Earnings Enhancement Bonus', 'Multiplier Attributes', 'Bonus' ,'ROLL UP', 'Annual Charges based on: Accumulation Value', 'Annual Benefit Payments(ABP) / Cases based on: Age at first withdrawal'];

export const fixedProductGMWBFactors = ['Annual Charges based on: Contact Value', 'Annual Benefit Payments(ABP) / Cases based on: Age at issue'];
   
export enum PremiumTaxTypeId{
     Annuitization = 1,
     Surrender = 2,
     Withdrawal = 3,
     PaymentofDB = 4,
     StateLaw = 5,
     Others = 6
}

export enum FixedProductTabsId {
     BasicInfo = 0,
     Interests = 1,
     Waivers = 2,
     Withrawals = 3,
     Riders = 4
}

export enum VAProductTabsId{
     BasicInfo = 0,
     Charges = 1,
     Bonus = 2,
     DeathBenefit = 3,
     GMAB = 4,
     GMIB = 5,
     GMWB = 6,
     Feature = 7,
     CombineBenefits = 8,
     SubAccounts = 9,
     Trans = 10,
     SchTrans = 11,
     AnnuityOptions = 12

}
   