import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BenefitcalculatorService } from 'src/app/service/benefitcalculator.service';

@Component({
  selector: 'app-add-update-custom-shelf',
  templateUrl: './add-update-custom-shelf.component.html',
  styleUrls: ['./add-update-custom-shelf.component.scss']
})
export class AddUpdateCustomShelfComponent implements OnInit {
  selectedCustomCreateName: any[] = [];
  selectedProducts: any = [];
  allProducts: any[] = [];
  shelfName!: string;
  seletedItems: any = [];
  isEdit: boolean = false;
  constructor(private benefitcalculatorService: BenefitcalculatorService, public ref: DynamicDialogRef,
    private messageService: MessageService,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    //id: this.config.id
    this.allProducts = this.config.data.allProducts ?? [];
    this.seletedItems = this.config.data.seletedItems ?? [];
    this.shelfName = this.config.data.shelfName ?? '';
    this.isEdit = this.config.data.isEdit ?? false;
  }

  onProductSelect(event: any) {
    if (event.node.children === undefined) {
      let { node } = event;
      this.selectedProducts.push(node.key);
      this.selectedCustomCreateName = [];
      this.selectedCustomCreateName.push({
        key: node.key,
        label: node.label,
        data: node.data
      });
    }
    else {
      const { node: { children } } = event;
      if (Array.isArray(children) && children.length) {
        for (let val of children) {
          this.selectedProducts.filter((el: any, index: any) => {
            if (el === val.key) {
              this.selectedProducts.splice(index, 1);
            }
          })
          this.selectedProducts.push(val.key)

          this.selectedCustomCreateName.filter((el: any, index: any) => {
            if (el.key === val.key) {
              this.selectedCustomCreateName.splice(index, 1);
            }
          })
          this.selectedCustomCreateName.push({
            key: val.key,
            label: val.label,
            data: val.data
          });
        }
      }
    }
  }

  onProductRemove(event: any) {
    if (event.node.children === undefined) {
      const { node } = event;
      let element: any[] = [];
      if (node) element = this.selectedProducts.filter((val: any) => {
        return node.key === val
      })
      element.filter((res: any) => {
        this.selectedProducts.filter((data: any, index: any) => {
          if (res === data) {
            this.selectedProducts.splice(index, 1);

          }
        })
      })
      element.filter((res: any) => {
        this.selectedCustomCreateName.filter((data: any, index: any) => {
          if (res === data.key) {
            this.selectedCustomCreateName.splice(index, 1)

          }
        })
      })
    }
    else {
      const { node: { children } } = event;
      let element: any[] = [];
      if (children) element = this.selectedProducts.filter((val: any) => {
        return children.some((el: any) => el.key === val)
      })

      element.filter((res: any) => {
        this.selectedProducts.filter((data: any, index: any) => {
          if (res === data) {
            this.selectedProducts.splice(index, 1);
          }
        })
      })
      element.filter((res: any) => {
        this.selectedCustomCreateName.filter((data: any, index: any) => {
          if (res === data.key) {
            this.selectedCustomCreateName.splice(index, 1)
          }
        })
      })
    }
  }

  createCustomShelf() {
    if (this.selectedProducts.length > 0 && this.shelfName && this.shelfName.trim() != '') {
      const customSelfData: any = [];
      let test = JSON.parse(sessionStorage.getItem('logInSession') || '');
      let logindata = this.benefitcalculatorService.decryptUsingAES256(test);
      const loginUserData = JSON.parse(logindata);
      for (let item of this.selectedProducts) {
        customSelfData.push({
          productid: item,
          userId: loginUserData.id,
          shelfName: this.shelfName.trim()
        })
      }
      this.benefitcalculatorService.postCustomShelfnew(customSelfData).subscribe((res: any) => {
        this.ref.close(true);
      })
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter Shelf Name and Products' });
    }
  }

  onCancel() {
    this.ref.close(false);
  }



}
