import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { RouterModule } from '@angular/router';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppConfigModule } from './config/config.module';
import { AppSidebarComponent } from "./app.sidebar.component";
import { AppLayoutComponent } from "./app.layout.component";
import { SafePipe } from './safe.pipe';
import { DashboardsRoutingModule } from '../modules/dashboard/dashboard-routing.module';
import { IllustrationModule } from '../modules/illustration/illustration.module';
import { LogoUploadModule } from '../modules/logo-upload/logo-upload.module';
import { TreeDemoRoutingModule } from '../modules/uikit/tree/treedemo-routing.module';
import { SavedSearchModule } from '../modules/saved-search/saved-search.module';
import { CommonModule, DatePipe } from '@angular/common';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { SharedModule, MessageService } from 'primeng/api';
import { EllipsisModule } from 'ngx-ellipsis';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { BeaconSharedModule } from "../shared/shared.module";

@NgModule({
    declarations: [
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppSidebarComponent,
        AppLayoutComponent,
        SafePipe
    ],
    exports: [AppLayoutComponent],
    providers: [DatePipe, MessageService],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule,
        AppConfigModule,
        ReactiveFormsModule,
        DashboardsRoutingModule,
        IllustrationModule,
        FileUploadModule,
        LogoUploadModule,
        ToastModule,
        SavedSearchModule,
        SharedModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        RippleModule,
        TreeModule,
        InputNumberModule,
        CalendarModule,
        DropdownModule,
        SliderModule,
        ListboxModule,
        DropdownModule,
        ButtonModule,
        CommonModule,
        ChartModule,
        MenuModule,
        TableModule,
        StyleClassModule,
        PanelMenuModule,
        ProgressSpinnerModule,
        ChipModule,
        DialogModule,
        CheckboxModule,
        ImageModule,
        FileUploadModule,
        ToastModule,
        EllipsisModule,
        DataViewModule,
        MultiSelectModule,
        SelectButtonModule,
        ToggleButtonModule,
        VirtualScrollerModule,
        BeaconSharedModule
    ]
})
export class AppLayoutModule { }
