<div class="container">
    <div class="row">
        <div class="col-md-12 px-0">
            <div class="selfData mb-3 align-items-center d-flex justify-content-between p-4">
                <div class="w-100">
                    <h5>{{ shelfName }}'s Selected Products</h5>
                    <ul class="list-unstyled row">
                        <li class="list-item col-3 font-16 p-3" *ngFor="let child of customShelfData">{{
                            child.data }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>