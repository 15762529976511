<div class="container-fluid">
    <div class="row">
        <div class="col-8">
            <a class="layout-topbar-logo" routerLink="">
                <img class="head-img img-size" src="../../assets/layout/images/Logo.jpg" alt="logo"/>
            </a>
        </div>
        <div class="col-4 text-end align-self-center">
            <button pButton label="Home" class=" p-button p-component ml-2 mr-2"
            (click)="backToHome()">
            </button>
        </div>
    </div>
    <div class="card p-3">
        <form [formGroup]="companyLogoForm" (ngSubmit)="onSubmit()">
            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="isSubmit == true"></p-progressBar>
            <div class="text-center">
                <p-dropdown [options]="companies" formControlName="companyName" placeholder="Select a Company"
                    class="drop-down " optionLabel="name" [showClear]="true" [style]="{'width':'100%'}"
                    [(ngModel)]="selectedCompany" (onChange)="onCompanyChange($event)"
                    (onClear)="onCompanyClear($event)">
                </p-dropdown>
    
            </div>
    
            <div class="row">
                <div class="col shadow-2 border-round logoshow mt-4 mr-2">
                    <div *ngIf="isImg1">
                        <div class="row text-center p-img-height" >
                            <p-image [src]="(logoURL1) || (logoName1 | safeimgpipe)"  alt="Image" width="250" height="250" [preview]="true"
                                class="p-img">
                            </p-image>
                        </div>
                    </div>
                    <div *ngIf="!isImg1">
                        <div class="text-center p-img-height">
                            <i class="pi pi-upload"
                                style="font-size: 10rem;border: 5px solid;padding: 40px;margin: 80px;"></i>
                        </div>
                    </div>
    
                    <div class="text-center">
                        <p-fileUpload mode="basic" name="" [fileLimit]="1" (onSelect)="onUploadLogo($event)"
                            *ngIf="!isImg1" (onRemove)="onRemoveLogo($event)" [maxFileSize]="1000"
                            [previewWidth]="80000" chooseLabel="Choose" [disabled]="comp_btn" ></p-fileUpload>
                        <p-button label="Remove Logo" icon="pi pi-times" *ngIf="isImg1"
                            (onClick)="onRemoveLogo(uploadedFiles)" styleClass="p-button-lg"></p-button>
                    </div>
                </div>

                <div class="col shadow-2 border-round logoshow mt-4">
                    <div *ngIf="isImg2">
                        <div class="row text-center p-img-height" >
                            <p-image [src]="(logoURL2) ||  (logoName2 | safeimgpipe) " alt="Image" width="250" [preview]="true"
                                class="p-img">
                            </p-image>
                        </div>
                    </div>
                    <div *ngIf="!isImg2">
                        <div class="text-center p-img-height">
                            <i class="pi pi-upload"
                                style="font-size: 10rem;border: 5px solid;padding: 40px;margin: 80px;"></i>
                        </div>
                    </div>
                    <div class="text-center">
                        <p-fileUpload mode="basic" name="" [fileLimit]="1" (onSelect)="onUploadLogo1($event)"
                            *ngIf="!isImg2" (onRemove)="onRemoveLogo1($event)" [maxFileSize]="1000"
                            [previewWidth]="80000" chooseLabel="Choose" [disabled]="comp_btn" >
                        </p-fileUpload>
                        <p-button label="Remove Logo" icon="pi pi-times" *ngIf="isImg2"
                            (onClick)="onRemoveLogo1(uploadedFiles1)" class="remove-imgbtn"
                            styleClass="p-button-lg"></p-button>
                    </div>
                </div>

                <div class="text-center p-6 subbutton">
                    <p-button label="Submit" icon="pi pi-check" iconPos="right" styleClass="p-button-lg"
                        (onClick)="onSubmit()" [disabled]="compSub_btn" class="btn-submit"></p-button>
                </div>
            </div>
        </form>
    
    </div>
</div>
