import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppLayoutComponent } from 'src/app/layout/app.layout.component';
import { LogoUploadComponent } from './logo-upload.component';

@NgModule({
  imports: [RouterModule.forChild([
    { path: '', component: LogoUploadComponent },
    { path: 'home', component: AppLayoutComponent }
  ])],
  exports: [RouterModule]
})
export class LogoUploadRoutingModule { }
