import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from 'src/app/layout/app.layout.component';
import { SavedSearchComponent } from './saved-search.component';


@NgModule({
  imports: [RouterModule.forChild(
    [
      {path: '', component:SavedSearchComponent},
      {path: 'home', component: AppLayoutComponent},
      { path: 'logoupload', loadChildren: () => import('../logo-upload/logo-upload.module').then(m => m.LogoUploadModule) },
      { path: 'adminsystem', loadChildren: () => import('../admin-system/admin-system.module').then(m => m.AdminSystemModule) },
      { path: 'savedsearch', loadChildren: () => import('./saved-search.module').then(m => m.SavedSearchModule) },
      { path: 'profilesetting', loadChildren: () => import('../profile-setting/profile-setting.module').then(m => m.ProfileSettingModule) },
      { path: 'customshelf', loadChildren: () => import('../custom-shelf/custom-shelf.module').then(m => m.CustomShelfModule) },
      { path: 'myfavourites', loadChildren: () => import('../myfavourites/myfavourites.module').then(m => m.MyfavouritesModule) },

    ]
  )],
  exports: [RouterModule]
})
export class SavedSearchRoutingModule { }
