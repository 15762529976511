import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './modules/notfound/notfound.component';
import { ProductService } from './service/product.service';
import { CountryService } from './service/country.service';
import { CustomerService } from './service/customer.service';
import { EventService } from './service/event.service';
import { IconService } from './service/icon.service';
import { NodeService } from './service/node.service';
import { PhotoService } from './service/photo.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BenefitcalculatorInterceptor } from './interceptor/benefitcalculator.interceptor';
import { BeaconSharedModule } from './shared/shared.module';
import { HeaderComponent } from './layout/header/header.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { MessageService } from "primeng/api";

@NgModule({
    declarations: [
        AppComponent, NotfoundComponent,
        HeaderComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        BeaconSharedModule,
        CommonModule,
        BeaconSharedModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        RippleModule,
        TreeModule,
        InputNumberModule,
        CalendarModule,
        DropdownModule,
        SliderModule,
        ListboxModule,
        DropdownModule,
        ButtonModule,
        CommonModule,
        ChartModule,
        MenuModule,
        TableModule,
        StyleClassModule,
        PanelMenuModule,
        ProgressSpinnerModule,
        ChipModule,
        DialogModule,
        CheckboxModule,
        ImageModule,
        FileUploadModule,
        ToastModule,
        EllipsisModule,
        DataViewModule,
        MultiSelectModule,
        SelectButtonModule,
        ToggleButtonModule,
        VirtualScrollerModule,
        ProgressSpinnerModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: BenefitcalculatorInterceptor, multi: true },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
