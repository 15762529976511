<form #createCustomShelfForm="ngForm">
    <div class="row">
        <div class="col-12 d-grid">
            <label>Custom Self Name</label>
            <input type="text" pInputText id="shelfName" [(ngModel)]="shelfName" required [disabled]="isEdit"
                [ngModelOptions]="{standalone: true}" placeholder="Enter Shelf Name">
        </div>
    </div>
    <div class="row my-tree-data">
        <div class="col-12 d-grid">
            <label>Select Companies & Products</label>
            <p-tree class="tree" [value]="allProducts" filterPlaceHolder="Search by company name here..."
                placeholder="Select Companies & Products" optionLabel="name" [(ngModel)]="selectedCustomCreateName"
                name="selectedProducts" ngDefaultControl selectionMode="checkbox" [filter]="true" required
                [(selection)]="seletedItems" [ngModelOptions]="{standalone: true}"
                (onNodeSelect)="onProductSelect($event)" (onNodeUnselect)="onProductRemove($event)">
            </p-tree>
        </div>
    </div>

    <div class="row">
        <div class="col-6 px-3">
            <div class="flex">
                <div class="savebtn">
                    <p-button type="button" (click)="createCustomShelf()"
                        [disabled]="createCustomShelfForm.form.invalid" label="Save">
                    </p-button>
                </div>
                <div class="cancelbtn">
                    <p-button label="Cancel" (onClick)="onCancel()"></p-button>
                </div>
            </div>

        </div>
    </div>
</form>